import MuiSelect, { SelectVariants } from '@mui/material/Select';
import { SelectProps } from '../input.interface';
import { InputWrapper } from '../InputWrapper/InputWrapper';
import { styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const SelectInput = styled(MuiSelect, {
  name: 'Select',
  slot: 'input',
  overridesResolver: (props, styles) => styles.input,
})({});

export const Select: React.FC<SelectProps & { variant?: SelectVariants }> = ({
  label,
  fullWidth,
  onChange,
  value,
  children,
  className,
  variant = 'outlined',
  ...props
}) => {
  return (
    <InputWrapper label={label} fullWidth={fullWidth} className={className}>
      <SelectInput
        variant={variant}
        value={value}
        onChange={onChange}
        IconComponent={variant === 'filled' ? KeyboardArrowDownIcon : undefined}
        {...props}
      >
        {children}
      </SelectInput>
    </InputWrapper>
  );
};
