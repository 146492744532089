import { DataGridPro, DataGridProProps, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { simsInventoryTableDataTestId } from 'simInventory/SimInventoryDataTestIds';
import { greyColor, palette } from 'theme/palette';
import { useColumnConfigurationActions } from '../hooks/useColumnConfigurationActions';
import { useCallback } from 'react';
import {
  scrollbarHorizontalSX,
  scrollBarsSX,
  scrollbarVerticalSX,
} from 'theme/utility/utility.styles';
import { ColumnConfiguration } from '../../../SimInventoryApi/simInventoryTableConfigurationApi.interface';

export const gridStyles = {
  '& .MuiDataGrid-scrollbarFiller': {
    backgroundColor: 'white !important',
  },
  '& .MuiDataGrid-scrollbarFiller--header': {
    backgroundColor: `${greyColor[100]} !important`,
  },
  '& .MuiDataGrid-row': {
    '& .MuiDataGrid-cell--pinnedRight': {
      backgroundColor: 'transparent ',
    },
    '&.Mui-selected': {
      backgroundColor: greyColor[200],
      '& .MuiDataGrid-cell--pinnedRight': {
        backgroundColor: 'transparent !important',
      },
      '&.Mui-hovered': {
        backgroundColor: greyColor[100],
      },
    },
  },
  '& .MuiDataGrid-row.Mui-selected': {
    '&:hover': {
      backgroundColor: `${greyColor[200]} !important`,
      '& .MuiDataGrid-cell--pinnedRight': {
        backgroundColor: `${greyColor[200]} !important`,
        '#additionalMenuActionsButton': {
          visibility: 'visible',
        },
      },
    },
  },

  '& .header-sticky': {
    borderLeft: 'none !important',
    maxWidth: '50px !important',
    minWidth: '50px !important',
    padding: '0 5px',
  },
  '& .MuiDataGrid-cell--withLeftBorder': {
    borderLeft: 'none',
  },
  '& .MuiDataGrid-pinnedColumnHeaders--right': {
    borderLeft: 'none',
  },

  '& .MuiDataGrid-scrollbar--vertical::-webkit-scrollbar': {
    width: '14px',
  },

  '& .MuiDataGrid-scrollbar--horizontal': {
    ...scrollbarHorizontalSX,
    ...scrollBarsSX,
  },

  '& .MuiDataGrid-scrollbar--vertical': {
    ...scrollbarVerticalSX,
    ...scrollBarsSX,
  },

  '& .MuiDataGrid-columnHeader': {
    paddingLeft: '0 !important',
  },

  '& .MuiDataGrid-columnHeaderDraggableContainer': {
    cursor: 'default !important',
  },
  '& .MuiDataGrid-filler': {
    '&--pinnedRight': {
      backgroundColor: palette.white,
      borderLeft: 'none',
    },
  },
};

export const SimInventoryDataGrid = (
  props: Omit<DataGridProProps, 'onColumnWidthChange' | 'onColumnOrderChange'> & {
    columnConfiguration: ColumnConfiguration[];
  },
) => {
  const { updateWidthOfColumn, updateVisibiltyOfColumns, reorder } =
    useColumnConfigurationActions();

  const onColumnWidthChange = useCallback(
    (params: any) => {
      const matchedColumn = props.columnConfiguration.find(
        (row) => row.name === params.colDef.field,
      );
      if (matchedColumn) {
        updateWidthOfColumn(params.colDef.field, params.width, matchedColumn.isCustomField);
      }
    },
    [updateWidthOfColumn],
  );

  const onColumnOrderChange = useCallback(
    (params: any) => {
      const draggedColumnIndex: number = params.oldIndex;
      const targetIndex: number = params.targetIndex;

      reorder(draggedColumnIndex, targetIndex);
    },
    [reorder],
  );

  const onColumnVisibilityModelChange = useCallback(
    (model: GridColumnVisibilityModel) => {
      updateVisibiltyOfColumns(model);
    },
    [updateVisibiltyOfColumns],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <DataGridPro
        data-testid={simsInventoryTableDataTestId}
        getRowClassName={(params) => `row-${params.row.iccid}`}
        getRowId={(row) => row.iccid || Math.random()}
        sx={{
          backgroundColor: 'white',
          border: 'none',
          width: '100%',
          height: '100%',
          ...gridStyles,
        }}
        columnBufferPx={20000}
        hideFooter={true}
        rowBufferPx={520}
        onColumnWidthChange={onColumnWidthChange}
        onColumnOrderChange={onColumnOrderChange}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        {...props}
        pinnedColumns={{
          right: ['actions'],
        }}
      />
    </DndProvider>
  );
};
