import { useQuery } from 'react-query';
import { fetchRSimSimsProfiles } from '../RSimApi/rSimApi';
import { RSimSimInventoryTableProps } from './RSimSimInventoryTable.interface';
import { SimInventoryTableBase } from 'simInventory/Components/SimInventoryTableBase';
import { RsimProfileStatus } from 'rSimInventory/RSimApi/rSimApi.interface';
import { SimProfile } from 'simInventory/SimInventoryApi/simInventoryApi.interface';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import { KickSimSwitch } from './KickSimSwitch';
import { TableHeaderCell } from 'common/components/Table/TableHeaderCell';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { PollingSimStatusIcon } from './PollingSimStatusIcon';
import { PollingStatus, PollingSimStatus, RSimProfile } from './PollingSimStatusIcon.interface';
import { useKickSimContext } from './KickRsimProvider';
import { palette } from '../../theme/palette';
import { Actions, Subjects } from 'permissions/ability';
import { SimInventoryCan } from 'permissions/PermissionProvider';
import { CARD_TYPE, TagProvider } from 'tags/TagProvider';

export const RSimSimInventoryTable: React.FC<RSimSimInventoryTableProps> = ({
  euicc,
  primaryIccid,
  status,
  onDataLoaded,
}) => {
  const { isPrimaryProfileDisabled, isSecondaryProfileDisabled } = useKickSimContext();
  const primaryProfileDisabled = isPrimaryProfileDisabled(euicc);
  const secondaryProfileDisabled = isSecondaryProfileDisabled(euicc);

  const { data: simInventory, status: fetchingStatus } = useQuery(['RSimSimProfiles', euicc], () =>
    fetchRSimSimsProfiles(euicc),
  );
  const { t } = useTranslation();

  const headers = simInventory?.headers || [];
  const items = simInventory?.items || [];

  const primaryProfile = items.find((profile) => profile.iccid === primaryIccid);
  const secondaryProfile = items.find((profile) => profile.iccid !== primaryIccid);

  let primaryProfileStatus: PollingStatus = PollingSimStatus.Unknown;
  let secondaryProfileStatus: PollingStatus = PollingSimStatus.Unknown;
  if (status === RsimProfileStatus.PrimaryProfileActive && primaryProfile) {
    primaryProfileStatus = PollingSimStatus.Active;
    secondaryProfileStatus = PollingSimStatus.Inactive;
  } else if (status === RsimProfileStatus.SecondaryProfileActive && secondaryProfile) {
    primaryProfileStatus = PollingSimStatus.Inactive;
    secondaryProfileStatus = PollingSimStatus.Active;
  } else if (status === RsimProfileStatus.PrimaryProfileDisconnected && primaryProfile) {
    primaryProfileStatus = PollingSimStatus.Disconnected;
    secondaryProfileStatus = PollingSimStatus.Inactive;
  } else if (status === RsimProfileStatus.SecondaryProfileDisconnected && secondaryProfile) {
    primaryProfileStatus = PollingSimStatus.Inactive;
    secondaryProfileStatus = PollingSimStatus.Disconnected;
  }

  const profiles = [primaryProfile, secondaryProfile].filter(
    (profile) => !!profile,
  ) as SimProfile[];

  useEffect(() => {
    if (fetchingStatus === 'success' && onDataLoaded) {
      onDataLoaded();
    }
  }, [fetchingStatus, onDataLoaded]);

  return (
    <TagProvider type={CARD_TYPE.SIM}>
      <SimInventoryTableBase
        headers={headers}
        items={profiles}
        additionalHeadersStart={
          <TableHeaderCell verticalAlign="top" sx={{ background: palette.white }} />
        }
        renderAdditionalCellsStart={(item) => {
          const isPrimaryProfile = item.iccid === primaryProfile?.iccid;

          const status = isPrimaryProfile ? primaryProfileStatus : secondaryProfileStatus;

          const isDisabled =
            (item.iccid === primaryProfile?.iccid && primaryProfileDisabled) ||
            (item.iccid === secondaryProfile?.iccid && secondaryProfileDisabled);

          return (
            <TableCell
              sx={{ border: 'none', px: 0 }}
              padding="checkbox"
              className={'polling-status-cell'}
            >
              <PollingSimStatusIcon
                status={status}
                paused={isDisabled}
                profile={isPrimaryProfile ? RSimProfile.Primary : RSimProfile.Backup}
              />
            </TableCell>
          );
        }}
        additionalHeadersEnd={
          <SimInventoryCan I={Actions.simulate} a={Subjects.outage}>
            <TableHeaderCell
              primaryText={t('common.simulateOutage')}
              verticalAlign="top"
              sx={{ textAlign: 'center', background: palette.white }}
              boxJustify={'center'}
            />
          </SimInventoryCan>
        }
        renderAdditionalCellsEnd={(item) => {
          const isPrimaryProfile = item.iccid === primaryProfile?.iccid;
          const isSecondaryProfile = item.iccid === secondaryProfile?.iccid;

          const isDisabled =
            (isPrimaryProfile && primaryProfileDisabled) ||
            (isSecondaryProfile && secondaryProfileDisabled);
          return (
            <SimInventoryCan I={Actions.simulate} a={Subjects.outage}>
              <TableCell sx={{ pb: 0, pt: 0, textAlign: 'center' }}>
                <Box sx={{ minWidth: '100px' }}>
                  <KickSimSwitch
                    checked={isDisabled}
                    profileId={isPrimaryProfile ? 0 : 1}
                    euicc={euicc}
                  />
                </Box>
              </TableCell>
            </SimInventoryCan>
          );
        }}
        headerSx={{ backgroundColor: palette.white }}
      />
    </TagProvider>
  );
};
