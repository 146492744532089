import Grid from '@mui/material/Grid';
import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

type PrimarySimGridProps = {
  children: React.ReactElement;
  sx?: SxProps<Theme>;
  hideTopBorder?: boolean;
};

export const PrimarySimGrid: React.FC<PrimarySimGridProps> = ({ children, sx, hideTopBorder }) => {
  const theme = useTheme();
  return (
    <Grid
      item xs={ 12 } md={ 6 }
      sx={ {
        [theme.breakpoints.up('md')]: {
          mr: 9,
        },
        pl: 3,
        pt: 3,
        pb: 3,
        borderTop: ({ palette }) => !hideTopBorder ? `1px solid ${ palette.tableBorder.main }` : '',
        ...sx,
      } }>
      { children }
    </Grid>
  );
};
