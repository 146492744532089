import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { Routes } from 'Routes.constants';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export type GoToDetailsMenuItemProps = {
  iccid: string;
  onClose?: () => void;
};

export const GoToDetailsMenuItem = ({ iccid, onClose }: GoToDetailsMenuItemProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  return (
    <MenuItem
      onClick={() => {
        navigate(`${Routes.simInventory}/${iccid}`, {
          state: { previousPath: `${pathname}${search}` },
        });
        if (onClose) {
          onClose();
        }
      }}
    >
      <ListItemText>{t('bulkProvisioning.viewDetails')}</ListItemText>
    </MenuItem>
  );
};
