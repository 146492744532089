import { AssignedTagToRSimDTO, AssignedTagToSimDTO, Tag } from './services/tagsApi.types';

const TAG_SEPARATOR = ',';

export const convertTagsIdsToString = (tags: string[]) => {
  return tags.join(TAG_SEPARATOR);
};

export const convertStringToTags = (tagString: string) => {
  return tagString.split(TAG_SEPARATOR);
};

export type NormilizedAssignedTags = Record<
  string,
  {
    items: Tag[];
    hasMore: boolean;
  }
>;

export const normilizeAssignedTagsToSim = (tags: AssignedTagToSimDTO[]) => {
  const normilzeTags: NormilizedAssignedTags = tags.reduce((tags, tag) => {
    return {
      ...tags,
      [tag.iccid]: {
        items: tag.tags,
        hasMore: tag.hasMore
      }
    };
  }, {});

  return normilzeTags;
};

export const normilizeAssignedTagsToRSim = (tags: AssignedTagToRSimDTO[]) => {
  const normilzeTags: NormilizedAssignedTags = tags.reduce((tags, tag) => {
    return {
      ...tags,
      [tag.euicc]: {
        items: tag.tags,
        hasMore: tag.hasMore
      }
    };
  }, {});

  return normilzeTags;
};
