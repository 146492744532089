import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';
import CSS from 'csstype';
import { SortingIndicator } from './SortingIndicator';

type TableHeaderCellProps = {
  primaryText?: string;
  secondaryText?: string;
  verticalAlign?: CSS.Property.VerticalAlign;
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
  sx?: SxProps<Theme>;
  enableSortingPrimary?: boolean;
  enableSortingSecondary?: boolean;
  boxJustify?: 'inherit' | 'center';
  handleSort?: (columnName: string, sort: Sorting) => void;
  primaryColumnName?: string;
  secondaryColumnName?: string;
  sortedColumn?: { columnName: string; sort: Sorting } | null;
};

type Sorting = 'asc' | 'desc' | null;

const boxConfig = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& .MuiIconButton-root': {
    padding: '0',
  },
  '& .sort': {
    opacity: 0,
  },
  '&:hover .sort': {
    opacity: 1,
  },
};

const onSortChange = (sort: Sorting): Sorting => {
  if (!sort || sort === 'desc') {
    return 'asc';
  } else {
    return 'desc';
  }
}

const getSortState = (columnName?: string, sortedColumn?: { columnName: string; sort: Sorting } | null) => {
  return sortedColumn && columnName && sortedColumn.columnName === columnName ? sortedColumn.sort : null;
}

export const TableHeaderCell: React.FC<TableHeaderCellProps> = ({
  primaryText,
  secondaryText,
  verticalAlign,
  align = 'inherit',
  boxJustify = 'inherit',
  sx,
  enableSortingPrimary,
  enableSortingSecondary,
  handleSort,
  primaryColumnName,
  secondaryColumnName,
  sortedColumn,
}) => {
  const [primarySort, setPrimarySort] = useState<Sorting>(getSortState(primaryColumnName, sortedColumn));
  const [secondarySort, setSecondarySort] = useState<Sorting>(getSortState(secondaryColumnName, sortedColumn));

  useEffect(() => {
    setPrimarySort(getSortState(primaryColumnName, sortedColumn));
    setSecondarySort(getSortState(primaryColumnName, sortedColumn));
  }, [sortedColumn]);

  const onPrimarySort = () => {
    const sort = onSortChange(primarySort);
    setPrimarySort(sort);
    setSecondarySort(null);
    if (handleSort && primaryColumnName) {
      handleSort(primaryColumnName, sort);
    }
  };
  const onSecondarySort = () => {
    const sort = onSortChange(secondarySort);
    setPrimarySort(null);
    setSecondarySort(sort);
    if (handleSort && secondaryColumnName) {
      handleSort(secondaryColumnName, sort);
    }
  };

  return (
    <TableCell
      align={ align }
      sx={ { verticalAlign, height: '42px', textTransform: 'capitalize', paddingLeft: '8px', ...sx } }
      size="small"
    >
      <Box sx={ { ...boxConfig, justifyContent: boxJustify } } data-testid="tableHeaderPrimary">
        { enableSortingPrimary &&
          <SortingIndicator
            className={ !!primarySort ? '' : 'sort' }
            sorting={ onPrimarySort }
            direction={ primarySort }
            testId={'primarySortingIndicator'}></SortingIndicator> }
        <Typography
          style={ !enableSortingPrimary ? { paddingLeft: '16px' } : {} }
          variant="tableHeaderPrimary">
          <Box data-testid="primaryText">{ primaryText }</Box>
        </Typography>
      </Box>
      <Box sx={ boxConfig } data-testid="tableHeaderSecondary">
        { enableSortingSecondary &&
          <SortingIndicator
            className={ !!secondarySort ? '' : 'sort' }
            sorting={ onSecondarySort }
            direction={ secondarySort }
            testId={'secondarySortingIndicator'}></SortingIndicator> }
        <Typography
          sx={ !enableSortingSecondary ? { paddingLeft: '16px' } : {} }
          variant="tableHeaderSecondary">
          <Box data-testid="secondaryText">{ secondaryText }</Box>
        </Typography>
      </Box>
    </TableCell>
  );
};
