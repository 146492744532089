import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ClearIcon from '@mui/icons-material/Clear';
import { borderColor, typoColor } from '../../../../theme/palette';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { DateRangeValidationError } from '@mui/x-date-pickers-pro';

export type DateRangeValuePiece = Date | null;
export type DateRangeValue = [DateRangeValuePiece, DateRangeValuePiece];

type DateRangePickerProps = {
  value: [DateRangeValuePiece, DateRangeValuePiece];
  onChange: (value: DateRangeValue) => void;
  minDate?: Date;
  maxDate?: Date;
  allowClearing?: boolean;
  size?: 'small' | 'large';
  numOfCalendars?: 1 | 2 | 3;
  onError?: (error: DateRangeValidationError) => void;
  disableFuture?: boolean;
};

const BoxStyled = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  '& .MuiMultiInputDateRangeField-separator': {
    display: 'none',
  },
  '& .MuiTextField-root': {
    marginLeft: 0,
    '&.small .MuiInputBase-formControl': {
      maxWidth: '150px',
    },
    '&.large .MuiInputBase-formControl': {
      maxWidth: '300px',
    },
    '&:first-of-type': {
      '& .MuiInputBase-formControl fieldset': {
        borderRadius: '12px 0 0 12px',
      },
    },
    '&:last-of-type': {
      '& .MuiInputBase-formControl fieldset': {
        borderRadius: '0 12px 12px 0',
      },
    },
    '& .iconBox': {
      cursor: 'pointer',
      borderRadius: '50px',
      height: '28px',
      width: '40px',
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
      '& .calendarIcon': {
        height: '22px',
        width: '22px',
        marginTop: '2px',
        marginLeft: '3px',
      },
    },
  },
  '& .clearIcon': {
    position: 'absolute',
    right: '42px',
    height: '22px',
    width: '22px',
    borderRight: `1px solid ${borderColor}`,
    cursor: 'pointer',
  },
  '& .MuiInputLabel-root': {
    opacity: '60%',
    top: '-6px',
  },
  '& .MuiInputLabel-shrink': {
    opacity: '100%',
    top: '0',
    span: {
      fontSize: '15px',
      color: typoColor.secondary,
    },
  },
  '& label': {
    textTransform: 'capitalize',
  },
  '& .MuiInputBase-root.Mui-error, .MuiInputBase-root.Mui-error.Mui-focused fieldset': {
    borderColor: typoColor.error,
    '&:hover': {
      fieldset: {
        borderColor: typoColor.error,
      },
    },
  },
});

export const DateRangePickerMui: React.FC<DateRangePickerProps> = ({
  value,
  onChange,
  minDate,
  maxDate,
  allowClearing = false,
  size = 'small',
  numOfCalendars = 1,
  onError,
  disableFuture = false,
}) => {
  const { t } = useTranslation();
  return (
    <BoxStyled data-testid="dateRangePickerContainer">
      <DateRangePicker
        value={value}
        onChange={onChange}
        minDate={minDate ?? undefined}
        maxDate={maxDate ?? undefined}
        calendars={numOfCalendars}
        disableFuture={disableFuture}
        slotProps={{
          textField: ({ position }) => ({
            label:
              position === 'start' ? (
                <Typography variant={'text1Primary'} data-testid="fromLabel">
                  {t('common.from')}
                </Typography>
              ) : (
                <Typography variant={'text1Primary'} data-testid="toLabel">
                  {t('common.to')}
                </Typography>
              ),
            'data-testid': position === 'start' ? 'fromContainer' : 'toContainer',
            className: size,
            InputProps: {
              'data-testid': position === 'start' ? 'fromInput' : 'toInput',
              name: position === 'start' ? 'from' : 'to',
              endAdornment: (
                <Box className="iconBox">
                  <DateRangeIcon
                    data-testid={position === 'start' ? 'fromIcon' : 'toIcon'}
                    className="calendarIcon"
                    width="22px"
                    height="22px"
                    sx={{ color: ({ palette }) => palette.text.secondary }}
                  />
                </Box>
              ),
            },
          }),
        }}
        onError={onError}
      />
      {allowClearing && value.length === 2 && (value[0] || value[1]) && (
        <ClearIcon
          data-testid="clearIcon"
          onClick={() => onChange([null, null])}
          className="clearIcon"
          sx={{ color: ({ palette }) => palette.text.secondary }}
        />
      )}
    </BoxStyled>
  );
};
