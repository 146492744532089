import { useSearchParams } from 'react-router-dom';
import { parseNumberOrDefault } from './utils/numberUtils';
import { getUpdatedUrlSearchParams } from './utils/urlSearchParamsUtils';
import { useCallback } from 'react';

export const usePaginationWithUrlParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setPage = useCallback(
    (page: number) => {
      setSearchParams(getUpdatedUrlSearchParams('page', page.toString(), searchParams), {
        replace: true
      });
    },
    [setSearchParams, searchParams]
  );

  const setRowsPerPage = (rowsPerPage: number) => {
    setSearchParams(
      getUpdatedUrlSearchParams('rowsPerPage', rowsPerPage.toString(), searchParams),
      { replace: true }
    );
  };

  return {
    page: parseNumberOrDefault(searchParams.get('page'), 0),
    rowsPerPage: parseNumberOrDefault(searchParams.get('rowsPerPage'), 25),
    setPage,
    setRowsPerPage
  };
};
