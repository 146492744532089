import { buttonStyles, ButtonProps } from './Button';
import { SxProps, Theme } from '@mui/material';
import MUIButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export type IconTextButtonProps = ButtonProps & {
  iconStart?: React.ReactElement;
  iconEnd?: React.ReactElement;
  sx?: SxProps<Theme>;
  disabled?: boolean;
};

const buttonBaseSX = {
  py: 2,
  '&:has(.text + .icon)': {
    pr: 2,
  },
  '&:has(.icon + .text)': {
    pl: 2,
  },
};

const buttonIconStyles: Record<ButtonProps['variant'], SxProps<Theme>> = {
  primary: {
    ...buttonStyles.primary,
    ...buttonBaseSX,
  },
  secondary: {
    ...buttonStyles.secondary,
    ...buttonBaseSX,
  },
  secondaryActive: {
    ...buttonStyles.secondaryActive,
    ...buttonBaseSX,
  },
  tetriary: {
    ...buttonStyles.tetriary,
    ...buttonBaseSX,
  },
};

const iconBox: SxProps<Theme> = {
  width: '24px',
  height: '24px',
};

export const IconTextButton: React.FC<IconTextButtonProps> = ({
  variant,
  children,
  iconStart,
  iconEnd,
  sx,
  ...props
}) => {
  const style = { ...buttonIconStyles[variant], ...sx };

  return (
    <MUIButtonBase sx={style} disableTouchRipple disableRipple {...props}>
      <Stack spacing={1.5} direction={'row'} alignItems="center">
        {iconStart && (
          <Box sx={iconBox} className="icon">
            {iconStart}
          </Box>
        )}
        <Box sx={{ alignSelf: 'flex-end' }} className="text">
          {children}
        </Box>
        {iconEnd && (
          <Box sx={iconBox} className="icon">
            {iconEnd}
          </Box>
        )}
      </Stack>
    </MUIButtonBase>
  );
};
