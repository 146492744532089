import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import AddIcon from '@mui/icons-material/Add';
import { useBulkProvisioningUpload } from './useBulkProvisioningUpload';
import { useTranslation } from 'react-i18next';
import {
  uploadBulkProvisioningButtonDataTestId,
  uploadBulkProvisioningInputDataTestId,
} from 'bulkProvisioning/BulkProvisioningDataTestIds';
import { UploadDialog } from './UploadDialog';
import { BulkProvisioningUploadSnackbar } from './BulkProvisioningUploadSnackbar';
import Box from '@mui/material/Box';

export const BulkProvisioningUploadButton = () => {
  const { t } = useTranslation();

  const { selectedFile, selectFile, uploadFile, importError, setImportError } =
    useBulkProvisioningUpload();
  return (
    <>
      <Box>
        <label htmlFor="provisioning-jobs">
          <Input
            id="provisioning-jobs"
            name={uploadBulkProvisioningInputDataTestId}
            data-testid="upload bulk provisioning input"
            type="file"
            sx={{ display: 'none' }}
            onChange={(event) => {
              const fileInput = event.target as HTMLInputElement;
              if (fileInput?.files?.[0]) {
                selectFile(fileInput.files[0]);
                // file input cannot be controlled. This is hack to reset the input field that allow upload the same
                // file in row
                event.target.value = '';
              }
            }}
          />
          <Button
            data-testid={uploadBulkProvisioningButtonDataTestId}
            variant="contained"
            component="span"
            startIcon={<AddIcon />}
            sx={{ padding: '8px 26px 8px 16px' }}
          >
            {t('common.uploadFile')}
          </Button>
        </label>
        <UploadDialog
          open={!!selectedFile && !importError}
          fileName={selectedFile?.name || ''}
          onConfirm={() => {
            uploadFile();
          }}
          onCancel={() => {
            selectFile(null);
          }}
        />

        <BulkProvisioningUploadSnackbar
          importError={importError}
          setImportError={setImportError}
          selectFile={selectFile}
        />
      </Box>
    </>
  );
};
