import MuiTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { InputWrapper } from '../InputWrapper/InputWrapper';
import SearchIcon from '@mui/icons-material/Search';

import { TextFieldProps } from '../input.interface';

export type SearchTextFieldProps = TextFieldProps;

export const SearchTextField: React.FC<TextFieldProps> = ({
  label,
  fullWidth,
  onChange,
  value,
  sx,
  inputWrapperSx,
  ...props
}) => (
  <InputWrapper label={label} fullWidth={fullWidth} sx={inputWrapperSx}>
    <MuiTextField
      value={value}
      data-testid="Search text field"
      onChange={onChange}
      {...props}
      type="search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ ml: 3 }}>
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
    />
  </InputWrapper>
);
