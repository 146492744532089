import TableCell from "@mui/material/TableCell";
import TableRow from '@mui/material/TableRow';
import { collapseRowSeparator } from './CollapseRow.style';

export const SeparatorRow = () => {
  return (
    <TableRow>
      <TableCell colSpan={100} sx={collapseRowSeparator}></TableCell>
    </TableRow>
  );
};
