import { FilterChipProps, FiltersChips } from 'common/components/Filters/FiltersChips';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { TagFilterChip } from 'common/components/FilterChip/TagFilterChip';
import { convertStringToTags } from 'tags/utils';
import { MnoFilterChip } from '../../common/components/FilterChip/MnoFilterChip';
import { SESSION_STATUS_NUMERIC } from '../utils/sessionStatus';
import { AccountFilterChip } from '../../common/components/FilterChip/AccountFilterChip';
import { SimInventoryFilters } from 'simInventory/models/SimProfile.model';
import { CustomFieldFilterChip } from '../../common/components/FilterChip/CustomFieldFilterChip';

type SimInventoryFilterChipsProps = Pick<FilterChipProps, 'onFilterClear' | 'onFiltersClear'> & {
  filters: SimInventoryFilters;
  onTagRemoved: (id: string) => void;
  onMnoRemoved: (id: string) => void;
  onAccountRemoved: (id: string) => void;
  onCustomFieldRemoved: (fieldIdd: string, optionId: string) => void;
};

export const SimInventoryFilterChips: React.FC<SimInventoryFilterChipsProps> = ({
  filters,
  onFilterClear,
  onFiltersClear,
  onTagRemoved,
  onMnoRemoved,
  onAccountRemoved,
  onCustomFieldRemoved,
}) => {
  const { t } = useTranslation();
  const createValuesForChips = (
    filters: Omit<
      SimInventoryFilters,
      'tags' | 'mobileNetworkOperator' | 'accounts' | 'customFields'
    >,
  ) => {
    const filtersNames = {
      sessionStatus: t('simInventory.sessionStatus'),
      accounts: t('common.account'),
      mobileNetworkOperator: t('simInventory.mobileNetworkOperator'),
      label: t('common.label'),
      orderNumber: t('common.orderNumber'),
      connectionId: t('common.connectionId'),
      tags: 'tags',
      iccidRange: '',
    };

    const { sessionStatus, iccidFrom, iccidTo, ...othersFilters } = filters;
    const chipsValues: Partial<Record<keyof typeof filtersNames, string>> = {};

    if (sessionStatus !== '') {
      //@ts-ignore
      chipsValues.sessionStatus = `${filtersNames.sessionStatus}: ${
        sessionStatus === SESSION_STATUS_NUMERIC.IN_SESSION
          ? t('common.inSession')
          : t('common.outSession')
      }`;
    }

    if (iccidFrom !== '' || iccidTo !== '') {
      chipsValues.iccidRange = `ICCID`;
      if (iccidFrom) {
        chipsValues.iccidRange += ` ${t('common.from')}: ${iccidFrom}`;
      }

      if (iccidTo) {
        chipsValues.iccidRange += ` ${t('common.to')}: ${iccidTo}`;
      }
    }

    Object.entries(othersFilters).forEach(([key, value]) => {
      if (value !== '') {
        chipsValues[key as keyof typeof filtersNames] = `${
          filtersNames[key as keyof typeof filtersNames]
        }: ${value}`;
      }
    });

    return chipsValues;
  };

  const { tags, mobileNetworkOperator, accounts, customFields, ...otherFilters } = filters;
  const filtersChips = createValuesForChips(otherFilters);

  const parsedTags = useMemo(() => {
    if (!tags) {
      return [];
    }
    return convertStringToTags(tags) || [];
  }, [tags]);

  const parsedMnos = useMemo(() => {
    if (!mobileNetworkOperator) {
      return [];
    }
    return convertStringToTags(mobileNetworkOperator) || [];
  }, [mobileNetworkOperator]);

  const parsedAccounts = useMemo(() => {
    if (!accounts) {
      return [];
    }
    return convertStringToTags(accounts) || [];
  }, [accounts]);

  const parsedCustomFields = useMemo(() => {
    if (!customFields) {
      return [];
    }
    const currentFilterArray = customFields.split(';');
    return currentFilterArray.map((filter) => {
      const splitFilter = filter.split('_');
      return { id: splitFilter[0], value: splitFilter[2] };
    }) || [];
  }, [customFields]);

  const parsedChips: { item: string; type: string; value?: string }[] = [
    ...parsedTags.map((t) => ({ item: t, type: 'tag' })),
    ...parsedMnos.map((m) => ({ item: m, type: 'mno' })),
    ...parsedAccounts.map((a) => ({ item: a, type: 'account' })),
    ...parsedCustomFields.map((c) => ({ item: c.id.toString(), value: c.value, type: 'custom' })),
  ];

  return (
    <FiltersChips
      externalChips={
        parsedChips.length
          ? parsedChips.map((i) => (
              <React.Fragment key={i.item}>
                {i.type === 'mno' && <MnoFilterChip ccii={i.item} onCancel={onMnoRemoved} />}
                {i.type === 'account' && (
                  <AccountFilterChip id={i.item} onCancel={onAccountRemoved} />
                )}
                {i.type === 'tag' && <TagFilterChip id={i.item} onCancel={onTagRemoved} />}
                {i.type === 'custom' && (
                  <CustomFieldFilterChip
                    id={i.item}
                    value={i.value || ''}
                    onCancel={onCustomFieldRemoved}
                  />
                )}
              </React.Fragment>
            ))
          : undefined
      }
      filters={filtersChips}
      onFilterClear={onFilterClear}
      onFiltersClear={onFiltersClear}
    />
  );
};
