import { Chip } from '../Chips/Chip';
import { useQuery } from 'react-query';
import { fetchAccount } from '../../../rSimInventory/Filters/services/accountsApi';

type AccountFilterChipProps = {
  id: string;
  onCancel: (id: string) => void;
};
export const AccountFilterChip = ({ id, onCancel }: AccountFilterChipProps) => {
  const { data } = useQuery(
    ['account', id],
    async () => {
      return await fetchAccount(id);
    },
    {
      suspense: false,
      enabled: !!id,
    },
  );

  return (
    <Chip
      label={`Account: ${data?.accountName}`}
      size="medium"
      variant="outlined"
      onDelete={() => {
        onCancel(id);
      }}
    />
  );
};
