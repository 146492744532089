import {
  AccountReferenceFilter,
  SessionStatusFilter,
  accountReferenceFilterSchema,
  sessionStatusFilterSchema,
} from 'simInventory/models/SimProfile.model';

export const parseSessionStatus = (sessionStatus: string | null): SessionStatusFilter => {
  if (sessionStatus === null) {
    return '';
  }

  const parsedSessionStatus = parseInt(sessionStatus);
  if (isNaN(parsedSessionStatus)) {
    return '';
  }

  try {
    return sessionStatusFilterSchema.parse(parsedSessionStatus);
  } catch (err) {
    return '';
  }
};

export const parsedAccountReference = (accountReference: string | null): AccountReferenceFilter => {
  if (accountReference === null) {
    return '';
  }

  const parsedAccountReference = parseInt(accountReference);
  if (isNaN(parsedAccountReference)) {
    return '';
  }

  try {
    return accountReferenceFilterSchema.parse(parsedAccountReference);
  } catch (err) {
    return '';
  }
};
