import { defineAbility } from '@casl/ability';
import {
  AUDIT_TRAILS_PERMISSIONS,
  AuditTrailsPermissions,
  SIM_INVENOTRY_PERMISSIONS,
  SimInventoryPermissions,
} from './Permissions.model';
import { userRoles } from 'auth/AuthApi/authApi.constants';
import { UserRole } from 'auth/AuthApi/authApi.interface';
import {
  BULK_PROVISIONING_PERMISSIONS,
  BulkProvisioningPermissions,
} from 'permissions/Permissions.model';

export const Actions = {
  read: 'read',
  edit: 'edit',
  export: 'export',
  upload: 'upload',
  simulate: 'simulate',
  restore: 'restore',
  filter: 'filter',
} as const;

export const Subjects = {
  bulkProvisioning: 'bulkProvisioning',
  simsProfiles: 'simsProfiles',
  simDetails: 'simDetails',
  simProfilesTableConfiguration: 'simsProfilesTableConfiguration',
  tags: 'tags',
  poolingLogs: 'poolingLogs',
  outage: 'outage',
  otaConfiguration: 'otaConfiguration',
  customFields: 'customFields',
  internalData: 'internalData',
  auditTrails: 'auditTrails',
  importRsims: 'importRsims',
} as const;

export const createSimInventoryPermissionsFromRules = (
  permissions: SimInventoryPermissions[],
  can: any,
) => {
  permissions.forEach((permission) => {
    if (permission === SIM_INVENOTRY_PERMISSIONS.ViewSimListAndDetails) {
      can(Actions.read, Subjects.simsProfiles);
      can(Actions.read, Subjects.simDetails);
      can(Actions.edit, Subjects.simProfilesTableConfiguration);
    }

    if (permission === SIM_INVENOTRY_PERMISSIONS.Export) {
      can(Actions.export, Subjects.simsProfiles);
    }
    if (permission === SIM_INVENOTRY_PERMISSIONS.EditTags) {
      can(Actions.edit, Subjects.tags);
    }

    if (permission === SIM_INVENOTRY_PERMISSIONS.ExportPoolingLogs) {
      can(Actions.export, Subjects.poolingLogs);
    }

    if (permission === SIM_INVENOTRY_PERMISSIONS.RestoreOTAConfiguration) {
      can(Actions.restore, Subjects.otaConfiguration);
    }

    if (permission === SIM_INVENOTRY_PERMISSIONS.ViewOtaConfigurationsDetails) {
      can(Actions.read, Subjects.otaConfiguration);
    }

    if (permission === SIM_INVENOTRY_PERMISSIONS.SimulateOutage) {
      can(Actions.simulate, Subjects.outage);
    }

    if (permission === SIM_INVENOTRY_PERMISSIONS.ViewCustomFields) {
      can(Actions.read, Subjects.customFields);
    }

    if (permission === SIM_INVENOTRY_PERMISSIONS.EditCustomFields) {
      can(Actions.edit, Subjects.customFields);
    }

    if (permission === SIM_INVENOTRY_PERMISSIONS.HasInternalDataAccess) {
      can(Actions.filter, Subjects.internalData);
    }

    if (permission === SIM_INVENOTRY_PERMISSIONS.ImportRsims) {
      can(Actions.edit, Subjects.importRsims);
    }
  });
};

export const createBulkProvisioningPermissionsFromRules = (
  permissions: BulkProvisioningPermissions[],
  can: any,
) => {
  permissions.forEach((permission) => {
    if (permission === BULK_PROVISIONING_PERMISSIONS.UploadFile) {
      can(Actions.upload, Subjects.bulkProvisioning);
    }

    if (permission === BULK_PROVISIONING_PERMISSIONS.ViewTableAndDetails) {
      can(Actions.read, Subjects.bulkProvisioning);
    }

    if (permission === BULK_PROVISIONING_PERMISSIONS.DownloadReport) {
      can(Actions.export, Subjects.bulkProvisioning);
    }
  });
};

export const createAuditTrailsPermissionsFromRules = (
  permissions: AuditTrailsPermissions[],
  can: any,
) => {
  permissions.forEach((permission) => {
    if (permission === AUDIT_TRAILS_PERMISSIONS.export) {
      can(Actions.export, Subjects.auditTrails);
    }

    if (permission === AUDIT_TRAILS_PERMISSIONS.viewAuditTrailList) {
      can(Actions.read, Subjects.auditTrails);
    }
  });
};

export const ability = defineAbility(() => {});

export const defineBulkProvisioningUserAbility = (can: any, cannot: any) => {};

export const defineSimInventoryUserAbility = (can: any, cannot: any) => {
  can(Actions.read, Subjects.simsProfiles);
  can(Actions.read, Subjects.simDetails);
  can(Actions.edit, Subjects.simProfilesTableConfiguration);
  can(Actions.export, Subjects.simsProfiles);
  can(Actions.edit, Subjects.tags);
  can(Actions.export, Subjects.poolingLogs);
  can(Actions.simulate, Subjects.outage);

  cannot(Actions.restore, Subjects.otaConfiguration);
  cannot(Actions.read, Subjects.bulkProvisioning);
  cannot(Actions.read, Subjects.customFields);
  cannot(Actions.filter, Subjects.internalData);
};

export const defineSimInventoryAdminAbility = (can: any) => {
  can(Actions.read, Subjects.simsProfiles);
  can(Actions.read, Subjects.simDetails);
  can(Actions.read, Subjects.otaConfiguration);
  can(Actions.edit, Subjects.simProfilesTableConfiguration);
  can(Actions.export, Subjects.simsProfiles);
  can(Actions.export, Subjects.poolingLogs);
  can(Actions.edit, Subjects.tags);

  can(Actions.restore, Subjects.otaConfiguration);
  can(Actions.simulate, Subjects.outage);
  can(Actions.read, Subjects.customFields);
  can(Actions.edit, Subjects.customFields);
  can(Actions.filter, Subjects.internalData);

  can(Actions.upload, Subjects.bulkProvisioning);
  can(Actions.read, Subjects.bulkProvisioning);
  can(Actions.export, Subjects.bulkProvisioning);

  can(Actions.export, Subjects.auditTrails);
  can(Actions.read, Subjects.auditTrails);
};

export const defineAdminAbility = () => defineAbility(defineSimInventoryAdminAbility);
export const defineUserAbility = () => defineAbility(defineSimInventoryUserAbility);

export const defineSimInventoryByRole = (role: UserRole) => (can: any, cannot: any) => {
  if (role === userRoles.admin) {
    defineSimInventoryAdminAbility(can);
  } else if (role === userRoles.user) {
    defineSimInventoryUserAbility(can, cannot);
  }
};
