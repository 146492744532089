import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';

type ButtonProps = Pick<
  MuiButtonProps,
  'onClick' | 'children' | 'variant' | 'color' | 'disabled' | 'sx' | 'startIcon'
>;

export const Button: React.FC<ButtonProps> = (props) => {
  return <MuiButton {...props} />;
};
