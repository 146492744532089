import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { SimInventorySearch } from 'simInventory/Components/SimInventoryTable.interface';
import { MaxSimProfilesCountError } from 'simInventory/SimInventoryApi/simInventoryApi';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTranslation } from 'react-i18next';
import { AuthError } from 'auth/utils';
import { AutoHideSnackbar } from 'common/Snackbar/AutoHideSnackbar';
import { AlertTitle } from 'common/Snackbar/AlertTitle';
import { useQuery } from 'react-query';
import { downloadSimProfilesReport } from './api/simProfileReportsApi';

type SimProfileDownloadReportsButtonProps = {
  searchParams: SimInventorySearch;
};

type ExportError = {
  title: string;
  description: string;
};

export const SimProfileDownloadReportsExtendedFiltersButton: React.FC<
  SimProfileDownloadReportsButtonProps
> = ({ searchParams }) => {
  const { refetch, isFetching } = useQuery(
    ['sim-inventory', 'reports', 'download', searchParams],
    async () => {
      await downloadSimProfilesReport(searchParams);
    },
    {
      retry: 0,
      enabled: false,
      suspense: false,
      onError: (error) => {
        if (error instanceof AuthError) {
          throw error;
        }

        if (error instanceof MaxSimProfilesCountError) {
          setExportError({
            title: t('common.exportFailed'),
            description: t('simInventory.maxSimProfilesCountError', {
              maxSimProfiles: error.maxSimProfilesCount,
            }),
          });
        } else {
          setExportError({
            title: t('common.requestFailed'),
            description: t('common.somethingWrong'),
          });
        }
      },
    },
  );

  const [downloadingSnackbarOpened, setDownloadingSnackbarOpened] = useState(false);
  useEffect(() => {
    if (isFetching) {
      setDownloadingSnackbarOpened(true);
    }
  }, [isFetching]);
  const { t } = useTranslation();

  const [exportError, setExportError] = useState<ExportError | null>(null);

  return (
    <>
      <Stack>
        <Button
          startIcon={<FileDownloadIcon />}
          disabled={isFetching}
          color="secondary"
          onClick={() => refetch()}
        >
          {t('common.export')}
        </Button>
        <AutoHideSnackbar
          severity="success"
          open={downloadingSnackbarOpened}
          onClose={() => {
            setDownloadingSnackbarOpened(false);
          }}
        >
          {''} {/* Without this component throw a error and crash app */}
          <AlertTitle> {t('common.downloading')}!</AlertTitle>
        </AutoHideSnackbar>
        <AutoHideSnackbar
          open={!!exportError}
          severity="error"
          onClose={() => setExportError(null)}
        >
          {''} {/* Without this component throw a error and crash app */}
          <AlertTitle>{exportError?.title || ''}!</AlertTitle>
          <Typography variant="text1White">{exportError?.description}</Typography>
        </AutoHideSnackbar>
      </Stack>
    </>
  );
};
