import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { ValuesType } from 'utility-types';
import { SIM_CARD_IDENTIFIERS } from 'data/sim.constants';
import { MultipleIdInput } from 'common/components/Inputs/IdAdvanceSearch/MultipleIdInput';

export const RSIM_INVENTORY_ID_SELECTION = {
  EID: SIM_CARD_IDENTIFIERS.EID,
  ICCID: SIM_CARD_IDENTIFIERS.ICCID,
} as const;

export type RSimId = ValuesType<typeof RSIM_INVENTORY_ID_SELECTION>;

export type SimIdSearchInputProps = {
  chosenId: RSimId;
  onChosenIdChange: (chosenId: RSimId) => void;
  search: string;
  onSearchChange: (value: string) => void;
};

export const RSimIdSearchInput = ({
  chosenId,
  onChosenIdChange,
  search,
  onSearchChange,
}: SimIdSearchInputProps) => {
  const { t } = useTranslation();
  return (
    <MultipleIdInput
      optionValue={chosenId}
      onOptionChange={onChosenIdChange as (value: string) => void} // typescript cannot deduct becuase it base on menu items in children
      inputValue={search}
      onInputValueChange={onSearchChange}
      inputLabel={t('common.search')}
      selectLabel="ID"
    >
      <MenuItem value={RSIM_INVENTORY_ID_SELECTION.ICCID}>
        {RSIM_INVENTORY_ID_SELECTION.ICCID}
      </MenuItem>
      <MenuItem value={RSIM_INVENTORY_ID_SELECTION.EID}>{RSIM_INVENTORY_ID_SELECTION.EID}</MenuItem>
    </MultipleIdInput>
  );
};
