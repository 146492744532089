import { authFetch } from 'auth/utils';
import { AssignedTagToRSimDTO, Tag } from './tagsApi.types';

export const fetchRSimTags = async (signal?: AbortSignal) => {
  const tagsResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_RSIMS_PROFILE_API}/tags`,
    { signal },
  );

  const tags: Tag[] = await tagsResponse.json();

  return tags;
};

export const fetchRSimTag = async (id: string, signal?: AbortSignal) => {
  const tagsResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_RSIMS_PROFILE_API}/tags/${id}`,
    { signal },
  );

  const tag: Tag = await tagsResponse.json();

  return tag;
};

export const createRSimTag = async (name: string, signal?: AbortSignal) => {
  const urlQuery = new URLSearchParams();
  urlQuery.append('name', name);

  const tagsResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_RSIMS_PROFILE_API}/tags?${urlQuery.toString()}`,
    { signal, method: 'POST' },
  );

  const tag: Tag = await tagsResponse.json();

  return tag;
};

export const assignTagsToRSim = async (euicc: string, tagIds: string[], signal?: AbortSignal) => {
  await authFetch(`${process.env.REACT_APP_SIM_INVENTORY_RSIMS_PROFILE_API}/tags/assign`, {
    signal,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      euicc,
      tagIds,
    }),
  });
};

export const fetchAssignedTagToRSim = async (euicc: string, signal?: AbortSignal) => {
  const assignedTags: AssignedTagToRSimDTO[] = await fetchAssignedTagToRSims({
    euiccs: [euicc],
  });

  return assignedTags.length ? assignedTags[0].tags : [];
};

type FetchAssignedTagToRSimsFilter = {
  euiccs: string[];
  limit?: number;
};

export const fetchAssignedTagToRSims = async (
  filters: FetchAssignedTagToRSimsFilter,
  signal?: AbortSignal,
) => {
  const tagsResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_RSIMS_PROFILE_API}/tags/assigned`,
    {
      signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filters),
    },
  );

  const assignedTags: AssignedTagToRSimDTO[] = await tagsResponse.json();

  return assignedTags;
};
