import React, { createContext, PropsWithChildren } from 'react';
import { useQuery } from 'react-query';
import { getFeatureFlags } from './FeatureFlagService';
import { FEATURE_FLAGS_MODULES, FeatureFlagsModules } from './FeatureFlags.models';

export type FeatureFlagContextProps = Record<FeatureFlagsModules, Record<string, boolean>>;

export const FeatureFlagContext = createContext<FeatureFlagContextProps>(
  {} as FeatureFlagContextProps,
);

export const FeatureFlagProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { data } = useQuery(
    ['feature', 'flags'],
    () => {
      return getFeatureFlags();
    },
    {
      retry: 0,
      cacheTime: 0,
      suspense: false,
    },
  );

  return data ? (
    <FeatureFlagContext.Provider
      value={
        data || {
          [FEATURE_FLAGS_MODULES.SIM_INVENTORY]: {},
          [FEATURE_FLAGS_MODULES.BULK_PROVISIONING]: {},
          [FEATURE_FLAGS_MODULES.AUDIT_TRAIL]: {},
        }
      }
    >
      {children}
    </FeatureFlagContext.Provider>
  ) : null;
};
