import { useQuery } from 'react-query';
import { Chip } from '../Chips/Chip';
import { useContext } from 'react';
import { TagContext } from 'tags/TagProvider';

type TagFilterChipProps = {
  id: string;
  onCancel: (id: string) => void;
};
export const TagFilterChip = ({ id, onCancel }: TagFilterChipProps) => {
  const { fetchTag, type } = useContext(TagContext);
  const { data, status } = useQuery(
    ['tag', type, id],
    () => {
      return fetchTag(id);
    },
    {
      enabled: !!id,
      suspense: false
    }
  );

  return status === 'success' ? (
    <Chip
      label={`Tag: ${data.name}`}
      size="medium"
      variant="outlined"
      onDelete={() => {
        onCancel(id);
      }}
    />
  ) : null;
};
