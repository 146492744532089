import { useEffect } from 'react';
import { useSearchStorage } from 'simInventory/Components/Filters/hooks/useSearchStorage';

export const useSimInventorySearchStorageSaver = (ids: string) => {
  const searchStorage = useSearchStorage();

  useEffect(() => {
    if (searchStorage) {
      searchStorage.updateSearchQuery(ids);
    }
  }, [ids, searchStorage]);
};
