import { useEffect, useState } from 'react';

export const useModalFilters = <T extends {}>(
  initialFilters: T,
  filters: T,
  onFiltersChange?: (filters: T) => void,
) => {
  const [modalFilters, setModalFilters] = useState<T>(initialFilters);

  const [filtersApplied, setFiltersApplied] = useState(false);
  const [open, setOpen] = useState(false);

  const filtersActive = filtersApplied && Object.values(modalFilters).some((value) => value);

  useEffect(() => {
    setModalFilters(filters);
  }, [filters]);

  const cancel = () => {
    setModalFilters(filters);
    setOpen(false);
  };

  const clear = () => {
    setModalFilters(initialFilters);
    if (onFiltersChange) {
      onFiltersChange(initialFilters);
    }

    setFiltersApplied(false);
  };

  const apply = () => {
    !filtersApplied && setFiltersApplied(true);
    if (onFiltersChange) {
      onFiltersChange(modalFilters);
    }

    setOpen(false);
  };

  return {
    filtersActive,
    open,
    setOpen,
    cancel,
    clear,
    apply,
    modalFilters,
    setModalFilters,
  };
};
