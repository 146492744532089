import TableCell from "@mui/material/TableCell";
import React from 'react';
import { stickyBorder } from './CollapseRow.style';

type StickyVerticalBorderProps = {
  side: 'left' | 'right';
};

export const StickyVerticalBorder: React.FC<StickyVerticalBorderProps> = ({ side }) => {
  return (
    <TableCell
      sx={{
        ...stickyBorder,
        [side]: 0
      }}
    />
  );
};
