import React from 'react';
import { GridEditSingleSelectCellProps, useGridApiContext } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { FIELD_TYPE } from '../../entities/customFields.entity';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Select } from 'common/components/Inputs/Select/Select';
import Typography from '@mui/material/Typography';

export const TypeDropdownCell = (props: GridEditSingleSelectCellProps) => {
  const apiRef = useGridApiContext();
  const { t } = useTranslation();

  return (
    <FormControl>
      {!props.row.isNew && (
        <InputLabel sx={{ top: '-8px' }}>
          <Typography data-testid="enabled-header" variant="tableCellPrimary">
            {props.row.dataType === FIELD_TYPE.Text
              ? t('customFields.text')
              : t('customFields.dropdown')}
          </Typography>
        </InputLabel>
      )}

      <Select
        //@ts-ignore
        displayEmpty
        //@ts-ignore
        renderValue={
          props.row.isNew && !props.row.dataType ? () => t('customFields.selectFieldType') : ''
        }
        className={props.row.dataType ? '' : 'missing-data'}
        sx={{
          width: '180px',
          ...(!props.row.isNew
            ? { '& svg': { display: 'none' }, '& fieldset': { border: 0, outline: 0 } }
            : {}),
        }}
        fullWidth={false}
        onChange={(e) => {
          if (e.target.value) {
            apiRef.current.setEditCellValue({
              id: props.row.id,
              field: 'dataType',
              value: e.target.value,
            });
          }
        }}
        disabled={!props.row.isNew}
      >
        <MenuItem key={FIELD_TYPE.Text} value={FIELD_TYPE.Text}>
          {t('customFields.text')}
        </MenuItem>
        <MenuItem key={FIELD_TYPE.Dropdown} value={FIELD_TYPE.Dropdown}>
          {t('customFields.dropdown')}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
