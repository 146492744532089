import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import React, { ReactNode } from 'react';
import { TableHeaderCell } from 'common/components/Table/TableHeaderCell';
import { useTranslation } from 'react-i18next';
import { rsimsInventoryTableDataTestId } from 'rSimInventory/RSimInventoryDataTestIds';
import { rightBorder } from 'theme/utility/utility.styles';
import { StickyTable } from 'common/components/Table/StickyTable';
import { StickyCell } from 'simInventory/Components/StickyCell';
import { StickyVerticalBorder } from 'common/components/Table/CollapseRow/StickyVerticalBorder';
import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';
import { expandButtonCell } from 'common/components/Table/CollapseRow/CollapseRow.style';
import { Sorting } from 'common/hooks/useSorting';

type RSimTableBaseProps = {
  children?: ReactNode;
  collapseAllButtonVisible?: boolean;
  onCloseAll: () => void;
  sortedColumn: { columnName: string; sort: Sorting } | null;
  onSortChange: (columnName: string, sort: Sorting) => void;
};
export const RSimTableBase: React.FC<RSimTableBaseProps> = ({
  children,
  onCloseAll,
  collapseAllButtonVisible = true,
  sortedColumn,
  onSortChange,
}) => {
  const { t } = useTranslation();
  return (
    <StickyTable data-testid={rsimsInventoryTableDataTestId}>
      <TableHead>
        <TableRow>
          <StickyVerticalBorder side="left" />
          <TableCell sx={expandButtonCell}>
            {collapseAllButtonVisible ? (
              <IconButton size="small" aria-label="collapse all" onClick={onCloseAll}>
                <UnfoldLessRoundedIcon />
              </IconButton>
            ) : null}
          </TableCell>

          <TableHeaderCell
            primaryText={t('common.eid')}
            sx={{
              textTransform: 'none',
              borderBottom: 'none',
              width: '280px',
              minWidth: '280px',
            }}
            primaryColumnName="EID"
            enableSortingPrimary={true}
            handleSort={onSortChange}
            sortedColumn={sortedColumn}
          />
          <TableHeaderCell
            primaryText={t('rSimInventory.rsimStatus')}
            align="center"
            sx={{ textTransform: 'none', borderBottom: 'none', minWidth: '110px', width: '110px' }}
          />

          <TableHeaderCell
            primaryText={t('rSimInventory.lastPollingTimestamp')}
            sx={{ borderBottom: 'none' }}
          />
          <TableHeaderCell
            primaryText={t('common.accountName')}
            secondaryText={t('common.accountNumber')}
            sx={{ borderBottom: 'none' }}
          />
          <TableHeaderCell primaryText={t('common.usage')} sx={{ borderBottom: 'none' }} />
          <TableHeaderCell
            sx={{ textTransform: 'none', borderBottom: 'none' }}
            primaryText={t('common.inMinuteUsage')}
            secondaryText={t('common.outMinuteUsage')}
          />
          <TableHeaderCell
            sx={{ textTransform: 'none', borderBottom: 'none' }}
            primaryText={t('common.inTextUsage')}
            secondaryText={t('common.outTextUsage')}
          />
          <TableHeaderCell
            primaryText={t('rSimInventory.configuration')}
            secondaryText={t('rSimInventory.version')}
            sx={{ borderBottom: 'none' }}
          />
          <TableHeaderCell primaryText={t('common.tag')} sx={{ borderBottom: 'none' }} />
          <StickyCell sx={{ border: 'none', right: 0, ...rightBorder }} />
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </StickyTable>
  );
};
