import React from 'react';
import { ReactComponent as UnknownProfileIcon } from 'assets/images/StatusIndicator/UnknownProfile.svg';
import { ReactComponent as ConnectedProfileIcon } from 'assets/images/StatusIndicator/ConnectedProfile.svg';
import { ReactComponent as DisconnectedProfileIcon } from 'assets/images/StatusIndicator/DisconnectedProfile.svg';
import { ReactComponent as InactiveProfileIcon } from 'assets/images/StatusIndicator/InactiveProfile.svg';
import { ReactComponent as AlertrSIM } from 'assets/images/StatusIndicator/AlertrSIM.svg';

import { PollingSimStatus, RSimProfile, SimStatusIconProps } from './PollingSimStatusIcon.interface';

import { useTranslation } from 'react-i18next';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type FCWithChildren = React.FC<React.PropsWithChildren>;
const IconAlertBox: FCWithChildren = ({ children }) => (
  <Box
    sx={{
      position: 'absolute',
      top: '-3px',
      right: '-3px'
    }}
  >
    {children}
  </Box>
);

const AlertIcon = () => (
  <IconAlertBox>
    <AlertrSIM height="13px" data-testid="Pause icon" />
  </IconAlertBox>
);

const IconRootBox: FCWithChildren = React.forwardRef(({ children, ...props }, ref) => (
  <Box ref={ref} sx={{ position: 'relative', width: '24px', height: '24px' }} {...props}>
    {children}
  </Box>
));

const getTooltipTitle = (profileLabel: string, statusLabel: string) => {
  return `${profileLabel} ${statusLabel.toLowerCase()}`;
};

export const PollingSimStatusIcon: React.FC<SimStatusIconProps> = ({
  status,
  paused = false,
  profile,
  hideTooltip,
}) => {
  const { t } = useTranslation();
  const alertDecriptionRows = paused ? `${t('common.simulateOutageRunning')}!` : [];
  const profileName = profile === RSimProfile.Primary ? t('common.primary') : t('common.backup');
  let statusLabel = t('common.unknown');
  if (!hideTooltip) {
    if (status === PollingSimStatus.Active) {
      statusLabel = t('common.connected');
      return (
        <Tooltip title={ getTooltipTitle(profileName, statusLabel) } description={ alertDecriptionRows }>
          <IconRootBox>
            <ConnectedProfileIcon width="24px" height="24px" aria-label={ statusLabel } role="img"/>
            { paused && <AlertIcon/> }
          </IconRootBox>
        </Tooltip>
      );
    }

    if (status === PollingSimStatus.Inactive) {
      statusLabel = t('common.inactive');
      return (
        <Tooltip title={ getTooltipTitle(profileName, statusLabel) } description={ alertDecriptionRows }>
          <IconRootBox>
            <InactiveProfileIcon width="24px" height="24px" aria-label={ statusLabel } role="img"/>
            { paused && <AlertIcon/> }
          </IconRootBox>
        </Tooltip>
      );
    }

    if (status === PollingSimStatus.Disconnected) {
      statusLabel = t('common.disconnected');
      return (
        <Tooltip title={ getTooltipTitle(profileName, statusLabel) } description={ alertDecriptionRows }>
          <IconRootBox>
            <DisconnectedProfileIcon width="24px" height="24px" aria-label={ statusLabel } role="img"/>
            { paused && <AlertIcon/> }
          </IconRootBox>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={ statusLabel } description={ alertDecriptionRows }>
        <IconRootBox>
          <UnknownProfileIcon width="24px" height="24px" aria-label={ statusLabel } role="img"/>
          { paused && <AlertIcon/> }
        </IconRootBox>
      </Tooltip>
    );
  }

  if (status === PollingSimStatus.Active) {
    statusLabel = t('common.connected');
    return (
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <IconRootBox>
          <ConnectedProfileIcon width="24px" height="24px" aria-label={ statusLabel } role="img"/>
          { paused && <AlertIcon/> }
        </IconRootBox>
        <Typography variant="text1Primary">{ statusLabel }</Typography>
      </Box>
    );
  }

  if (status === PollingSimStatus.Inactive) {
    statusLabel = t('common.inactive');
    return (
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <IconRootBox>
          <InactiveProfileIcon width="24px" height="24px" aria-label={ statusLabel } role="img"/>
          { paused && <AlertIcon/> }
        </IconRootBox>
        <Typography variant="text1Primary">{ statusLabel }</Typography>
      </Box>
    );
  }

  if (status === PollingSimStatus.Disconnected) {
    statusLabel = t('common.disconnected');
    return (
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <IconRootBox>
          <DisconnectedProfileIcon width="24px" height="24px" aria-label={ statusLabel } role="img"/>
          { paused && <AlertIcon/> }
        </IconRootBox>
        <Typography variant="text1Primary">{ statusLabel }</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <IconRootBox>
        <UnknownProfileIcon width="24px" height="24px" aria-label={ statusLabel } role="img"/>
        { paused && <AlertIcon/> }
      </IconRootBox>
      <Typography variant="text1Primary">{ statusLabel }</Typography>
    </Box>
  );
};
