import TableCell from '@mui/material/TableCell';
import { StickyCellProps } from './StickyCell.interface';

export const StickyCell: React.FC<StickyCellProps> = ({ children, sx, className }) => (
  <TableCell
    className={className}
    sx={{
      width: '45px',
      minWidth: '45px',
      position: 'sticky',
      top: 0,
      right: 0,
      borderLeft: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
      p: 0,
      ...sx
    }}
  >
    {children}
  </TableCell>
);
