import { useMemo, useState } from 'react';

export type Sorting = 'asc' | 'desc' | null;

export type SortingParam = {
  columnName: string;
  sort: Sorting;
} | null;

export const useSorting = (key: string) => {
  const sortingParamFromLocalStorage = localStorage.getItem(key);
  const [sortParams, setSortParams] = useState<string>(sortingParamFromLocalStorage ?? '');

  const sorting = useMemo(() => {
    let sort = sortParams;
    if (sortingParamFromLocalStorage) {
      sort = sortingParamFromLocalStorage;
    }
    if (sort) {
      const sortSplitted = sort?.split(':');
      if (sortSplitted) {
        return {
          columnName: sortSplitted[0],
          sort: sortSplitted[1] as Sorting,
        };
      }
    }
    return null;
  }, [sortParams, sortingParamFromLocalStorage]);

  return { sorting, setSortParams };
};
