import { CollapseRow } from 'common/components/Table/CollapseRow/CollapseRow';
import { RSimTableBase } from './RSimTableBase';
import { TableLayoutWithPagination } from 'common/components/Table/TableLayoutWithPagination';
import {
  RSimConfiguration,
  RSimInventoryData,
  RSimInventoryItem,
  RsimProfileStatus,
} from '../RSimApi/rSimApi.interface';
import { TableSkeleton } from 'common/components/Table/TableSkeleton';
import React, { Suspense, useMemo, useState } from 'react';
import { RSimSimInventoryTable } from './RSimSimInventoryTable';

import { RSimStatusIndicator } from './RSimStatusIndicator';
import { PrioritizedTwoValuesCell } from 'common/components/Table/PrioritizedTwoValuesCell';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import { ThemeProvider } from '@mui/material/styles';
import { usePrevSearchValue } from 'common/usePrevSearchValue';
import { NoDataMessage } from 'common/components/Table/NoDataMessage';

import { topBorder, topDarkBorder, verticalBorders } from 'theme/utility/utility.styles';
import { defaultTheme } from 'theme/DefaultThemeProvider';
import { greyColor } from 'theme/palette';
import { KickRsimProvider } from './KickRsimProvider';
import {
  LastActiveProfileFilter,
  LastUpdateTimeFilters,
  SimulateOutageFilter,
} from 'rSimInventory/Filters/data/filters.constants';
import { TwoValueCellLayout, ValueBox } from 'common/components/Table/TwoValueCell';
import { Routes } from 'Routes.constants';
import { CopyableLink } from 'common/components/Table/Link';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RSimInventoryEditDialog } from 'rSimInventory/RSimTags/RSimInventoryEditDialog';
import { TagsTableCell } from 'tags/TagsTableCell';
import { useQueryClient } from 'react-query';
import { ReactComponent as ConfigurationIcon } from 'assets/images/configuration.svg';
import { ReactComponent as PendingIcon } from 'assets/images/pending.svg';
import { Tooltip } from '../../common/components/Tooltip/Tooltip';
import { ContentCopyExtension } from '../../common/ContentCopyExtension/ContentCopyExtension';
import Typography from '@mui/material/Typography';
import { RSimInventoryConfigurationDialog } from '../RSimConfiguration/RSimInventoryConfigurationDialog';
import Stack from '@mui/material/Stack';
import { AutoHideSnackbar } from '../../common/Snackbar/AutoHideSnackbar';
import { AlertTitle } from '../../common/Snackbar/AlertTitle';
import { useSorting } from '../../common/hooks/useSorting';
import { useRSimInventory } from 'rSimInventory/hooks/useRSimInventory';
import { RSimProfileId } from 'rSimInventory/models/RSimProfile.model';
import { getHuminizeDurationInSecFunction } from 'i18n/dateLocale';
import { Actions, Subjects } from 'permissions/ability';
import { SimInventoryCan } from 'permissions/PermissionProvider';

interface RSimTableProps {
  searchText: string;
  chosenIdType: RSimProfileId;
  lastActiveProfile: LastActiveProfileFilter;
  lastUpdateTime: LastUpdateTimeFilters;
  tags: string;
  connectionId: string;
  orderNumber: string;
  simulateOutage: SimulateOutageFilter;
  accounts: string;
  eidFrom: string;
  eidTo: string;
}

const rsimTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    tertiary: {
      ...defaultTheme.palette.tertiary,
      main: greyColor['100'],
    },
    background: {
      background: greyColor['200'],
    },
  },
  components: {
    ...defaultTheme.components,
    MuiTable: {
      background: greyColor['100'],
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          borderRadius: 0,
          background: greyColor['100'],
          borderColor: greyColor['300'],
          border: 'none',
          zIndex: 0,
        },
        root: {
          paddingLeft: '24px',
          paddingRight: '24px',
          whiteSpace: 'nowrap',
          borderColor: greyColor['200'],
          borderBottom: 'none',
          borderTop: `1px solid ${greyColor['300']}`,
        },
      },
    },
    MuiTableRow: {
      defaultProps: {
        hover: true,
      },
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover': {
            '&:hover': {
              backgroundColor: greyColor[200],
            },
          },
        },
        head: {
          whiteSpace: 'nowrap',
          backgroundColor: greyColor['100'],
        },
      },
    },
  },
};

const humanize = getHuminizeDurationInSecFunction();

export const RSimTable: React.FC<RSimTableProps> = ({
  searchText,
  chosenIdType,
  lastActiveProfile,
  lastUpdateTime,
  tags,
  connectionId,
  orderNumber,
  simulateOutage,
  accounts,
  eidFrom,
  eidTo,
}) => {
  const { sorting, setSortParams } = useSorting('rsim-order');

  const searchIds = useMemo(() => {
    return searchText.match(/[0-9]+/g) || [''];
  }, [searchText]);

  const {
    queryResult,
    pagination: { page, setPage, rowsPerPage, setRowsPerPage, totalNumberOfItems },
    queryKey,
  } = useRSimInventory({
    searchIds: searchIds,
    searchText,
    chosenIdType,
    lastActiveProfile,
    lastUpdateTime,
    tags,
    connectionId,
    orderNumber,
    simulateOutage,
    accounts,
    eidFrom,
    eidTo,
    sorting,
  });

  const queryClient = useQueryClient();
  const updateTagsForRSim = async (euicc: string, tags: any) => {
    const simProfiles: RSimInventoryData | undefined = queryClient.getQueryData(queryKey);

    if (!simProfiles?.items.length) {
      return;
    }

    //@ts-ignore
    queryClient.setQueryData(queryKey, (oldData: RSimInventoryData) => {
      if (!oldData) {
        return oldData;
      }

      const newData = {
        ...oldData,
        items: oldData?.items.map((simProfile: RSimInventoryItem) => {
          if (simProfile.euicc === euicc) {
            return { ...simProfile, tags: { items: tags, hasMore: false } };
          }

          return simProfile;
        }),
      };

      return newData;
    });
  };

  const [restoreStatus, setRestoreStatus] = useState<{ success: boolean; error: boolean }>({
    success: false,
    error: false,
  });

  const restoreConfiguration = async (isSuccessful: boolean) => {
    setRestoreStatus({ success: isSuccessful, error: !isSuccessful });
  };

  const prevSearchText = usePrevSearchValue(searchText, queryResult.isRefetching);

  const getOpenedRowsMap = () => {
    return (
      queryResult.data?.items.reduce((current, item) => {
        return { ...current, [item.id]: false };
      }, {}) || {}
    );
  };
  const [openedRows, setOpenedRows] = useState<Record<string, boolean>>(getOpenedRowsMap());

  const euiccs =
    useMemo(() => queryResult.data?.items.map((item) => item.euicc), [queryResult.data]) || [];

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const [editedRSim, setEditedRsim] = useState<string | null>(null);
  const [editedConfiguration, setEditedConfiguration] = useState<RSimConfiguration | null>(null);

  const isPreviousOpen = (index: number) => {
    const item = queryResult.data?.items[index - 1];
    if (!!item) {
      return !!openedRows[item.id];
    }
    return false;
  };

  return (
    <KickRsimProvider euiccs={euiccs}>
      <TableLayoutWithPagination
        noItemsMessage={<NoDataMessage searchText={searchText} prevSearchText={prevSearchText} />}
        page={page}
        onPageChange={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(page) => setRowsPerPage(page)}
        totalNumberOfItems={totalNumberOfItems}
        paginationSx={{
          ...verticalBorders,
          ...topDarkBorder,
        }}
        table={(scrollbarsRef) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ flex: 0 }}>
              <RSimTableBase
                collapseAllButtonVisible={Object.values(openedRows).some((opened) => opened)}
                onCloseAll={() => {
                  setOpenedRows(getOpenedRowsMap());
                }}
                sortedColumn={sorting}
                onSortChange={(columnName, sort) => {
                  if (columnName && sort) {
                    localStorage.setItem('rsim-order', `${columnName}:${sort}`);
                    setSortParams(`${columnName}:${sort}`);
                  } else {
                    localStorage.removeItem('rsim-order');
                  }
                }}
              >
                {queryResult.data?.items.map(
                  (
                    {
                      id,
                      euicc,
                      status,
                      accountName,
                      accountNumber,
                      primaryIccid,
                      dataUsage,
                      inMinuteUsage,
                      inTextUsage,
                      outMinuteUsage,
                      outTextUsage,
                      lastPollingTimestamp,
                      tags,
                      appliedOtaConfigurationProfile,
                      appliedOtaConfigurationVersion,
                      isPendingConfiguration,
                      isCustomConfigurationDetected,
                    },
                    index,
                  ) => {
                    return (
                      <React.Fragment key={id}>
                        <CollapseRow
                          onCollapseEnd={() => {
                            setTimeout(() => {
                              scrollbarsRef.current && scrollbarsRef.current.forceUpdate();
                            }, 100);
                          }}
                          open={!!openedRows[id]}
                          onCollapseButtonClick={(open) => {
                            setOpenedRows((prevOpendRows) => ({
                              ...prevOpendRows,
                              [id]: open,
                            }));
                          }}
                          collapseChildren={
                            <div data-testid={`sim-inventory-${euicc}`}>
                              <Suspense
                                fallback={
                                  <TableSkeleton
                                    numberOfRows={2}
                                    showEndSkeleton
                                    pagination={false}
                                  />
                                }
                              >
                                <ThemeProvider theme={rsimTheme}>
                                  <div data-testid={`sim-inventory-table-${euicc}`}>
                                    <RSimSimInventoryTable
                                      euicc={euicc}
                                      primaryIccid={primaryIccid}
                                      status={status}
                                      onDataLoaded={() =>
                                        scrollbarsRef.current && scrollbarsRef.current.forceUpdate()
                                      }
                                    />
                                  </div>
                                </ThemeProvider>
                              </Suspense>
                            </div>
                          }
                          renderMenu={({ onClose }) => (
                            <MenuList>
                              <MenuItem
                                onClick={() => {
                                  navigate(`${Routes.rSimInventory}/${euicc}`, {
                                    state: {
                                      previousPath: `${pathname}${search}`,
                                    },
                                  });
                                  onClose();
                                }}
                              >
                                <ListItemText>{t('bulkProvisioning.viewDetails')}</ListItemText>
                              </MenuItem>
                              <SimInventoryCan I={Actions.edit} a={Subjects.tags}>
                                <MenuItem
                                  onClick={() => {
                                    setEditedRsim(euicc);
                                    onClose();
                                  }}
                                >
                                  <ListItemText>Edit</ListItemText>
                                </MenuItem>
                              </SimInventoryCan>
                              <SimInventoryCan I={Actions.read} a={Subjects.otaConfiguration}>
                                {(isCustomConfigurationDetected ||
                                  appliedOtaConfigurationProfile ||
                                  status !== RsimProfileStatus.Unknown) && (
                                  <MenuItem
                                    onClick={() => {
                                      setEditedConfiguration({
                                        euicc,
                                        appliedOtaConfigurationProfile,
                                        appliedOtaConfigurationVersion,
                                        isCustomConfigurationDetected,
                                        isPendingConfiguration,
                                      });
                                    }}
                                  >
                                    <ListItemText>
                                      {t('rSimInventory.configurationDetails')}
                                    </ListItemText>
                                  </MenuItem>
                                )}
                              </SimInventoryCan>
                            </MenuList>
                          )}
                          isPreviousOpen={isPreviousOpen(index)}
                          isFirst={index === 0}
                        >
                          <TwoValueCellLayout
                            sx={{
                              pl: '12px',
                              borderTop: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                              borderBottom: 'none',
                            }}
                            primaryContent={
                              <CopyableLink value={euicc} to={`${Routes.rSimInventory}/${euicc}`} />
                            }
                          />
                          <TableCell
                            align="center"
                            sx={{ py: 1.5, ...topBorder, borderBottom: 'none' }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <RSimStatusIndicator euicc={euicc} status={status} />
                            </Box>
                          </TableCell>
                          <PrioritizedTwoValuesCell
                            primaryText={lastPollingTimestamp}
                            sx={{
                              borderTop: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                              borderBottom: 'none',
                            }}
                          />
                          <PrioritizedTwoValuesCell
                            primaryText={accountName}
                            secondaryText={accountNumber}
                            sx={{
                              borderTop: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                              borderBottom: 'none',
                            }}
                          />
                          <PrioritizedTwoValuesCell
                            primaryText={dataUsage.toString()}
                            sx={{
                              borderTop: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                              borderBottom: 'none',
                            }}
                          />
                          <PrioritizedTwoValuesCell
                            primaryText={humanize(inMinuteUsage)}
                            secondaryText={humanize(outMinuteUsage)}
                            sx={{
                              borderTop: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                              borderBottom: 'none',
                            }}
                          />
                          <PrioritizedTwoValuesCell
                            primaryText={inTextUsage.toString()}
                            secondaryText={outTextUsage.toString()}
                            sx={{
                              borderTop: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                              borderBottom: 'none',
                            }}
                          />
                          <TableCell
                            sx={{
                              verticalAlign: 'center',
                              py: 2,
                              height: '52px',
                              borderTop: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                              borderBottom: 'none',
                            }}
                          >
                            <Box
                              sx={{ whiteSpace: 'nowrap', display: 'flex' }}
                              data-testid="primaryText"
                            >
                              <ContentCopyExtension value={appliedOtaConfigurationProfile || ''}>
                                <Typography
                                  marginRight={
                                    isCustomConfigurationDetected || isPendingConfiguration
                                      ? '5px'
                                      : ''
                                  }
                                  variant="tableCellPrimary"
                                >
                                  {appliedOtaConfigurationProfile || ''}
                                </Typography>
                                {isCustomConfigurationDetected && (
                                  <Tooltip title={t('rSimInventory.customConfigurationDetected')}>
                                    <ConfigurationIcon
                                      width="16px"
                                      height="16px"
                                      role="img"
                                      data-testid="custom-configuration-icon"
                                    />
                                  </Tooltip>
                                )}
                                {isPendingConfiguration && (
                                  <Tooltip title={t('rSimInventory.configurationChangePending')}>
                                    <PendingIcon
                                      width="18px"
                                      height="18px"
                                      role="img"
                                      data-testid="change-pending-icon"
                                    />
                                  </Tooltip>
                                )}
                              </ContentCopyExtension>
                            </Box>

                            <Box sx={{ whiteSpace: 'nowrap' }} data-testid="secondaryText">
                              <ValueBox
                                variant="tableCellSecondary"
                                text={appliedOtaConfigurationVersion || ''}
                              />
                            </Box>
                          </TableCell>

                          <TagsTableCell
                            initialTags={tags?.items.map((tag) => tag.name) || []}
                            id={euicc}
                            hasMore={tags?.hasMore || false}
                          />
                        </CollapseRow>
                      </React.Fragment>
                    );
                  },
                )}
              </RSimTableBase>
            </Box>
            <Box
              sx={{
                flex: 1,
                border: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                borderBottom: 'none',
              }}
            />
          </Box>
        )}
      />

      {editedRSim ? (
        <Suspense>
          <RSimInventoryEditDialog
            open
            euicc={editedRSim}
            onClose={() => setEditedRsim(null)}
            onTagsUpdate={updateTagsForRSim}
          />
        </Suspense>
      ) : null}

      <SimInventoryCan I={Actions.read} a={Subjects.otaConfiguration}>
        {editedConfiguration ? (
          <Suspense>
            <RSimInventoryConfigurationDialog
              open
              configuration={editedConfiguration}
              onClose={() => setEditedConfiguration(null)}
              onConfigurationRestore={restoreConfiguration}
            />
          </Suspense>
        ) : null}

        <Stack>
          <AutoHideSnackbar
            severity="success"
            open={restoreStatus.success}
            onClose={() => setRestoreStatus({ ...restoreStatus, success: false })}
          >
            {''} {/* Without this component throw a error and crash app */}
            <AlertTitle> {t('rSimInventory.restoreConfigurationSend')}</AlertTitle>
          </AutoHideSnackbar>
          <AutoHideSnackbar
            severity="error"
            open={restoreStatus.error}
            onClose={() => setRestoreStatus({ ...restoreStatus, error: false })}
          >
            {''} {/* Without this component throw a error and crash app */}
            <AlertTitle>{t('rSimInventory.restoreConfigurationFailed')}</AlertTitle>
          </AutoHideSnackbar>
        </Stack>
      </SimInventoryCan>
    </KickRsimProvider>
  );
};
