import { Dialog, DialogProps } from 'common/components/Dialog/Dialog';
import { Button } from 'common/components/Button';
import { DialogLoader } from './DialogLoader';

type EditDialogProps = {
  children: React.ReactNode;
  unsavedChanges: boolean;
  onApplyChangesClick: () => void;
  onCancelClick: () => void;
  onClearChangesClick: () => void;
  isLoading?: boolean;
} & Pick<DialogProps, 'subtitle' | 'open'>;

export const EditDialog: React.FC<EditDialogProps> = ({
  children,
  open,
  isLoading,
  subtitle,
  unsavedChanges,
  onApplyChangesClick,
  onCancelClick,
  onClearChangesClick,
}) => {
  return (
    <Dialog
      open={open}
      title="Edit"
      subtitle={subtitle}
      onClose={onCancelClick}
      size="medium"
      actions={
        <>
          <Button
            variant="text"
            color="tertiary"
            disabled={!unsavedChanges}
            onClick={onClearChangesClick}
          >
            Clear changes
          </Button>
          <Button color="secondary" onClick={onCancelClick}>
            Cancel
          </Button>
          <Button onClick={onApplyChangesClick}>Apply changes</Button>
        </>
      }
    >
      {!isLoading ? children : <DialogLoader />}
    </Dialog>
  );
};
