import { GridCell, GridRenderCellParams } from '@mui/x-data-grid-pro';

export type CellConfiguration = Record<string, any>;

type CellLoaderProps = GridRenderCellParams & {
  cellConfiguration: CellConfiguration;
};

export const CellLoader = ({ cellConfiguration, ...props }: CellLoaderProps) => {
  const Component = cellConfiguration?.[props.field] || cellConfiguration?.['default'];
  if (!Component) {
    return <GridCell {...props} />;
  }

  return (
    <Component
      //@ts-ignore
      value={props.value}
    />
  );
};
