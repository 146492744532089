import React, { createContext, PropsWithChildren, useContext } from 'react';
import { useQuery } from 'react-query';
import { fetchUser, loginRedirect, logoutRedirect } from './AuthApi/authApi';
import { User } from './AuthApi/authApi.interface';
import { authFetch } from 'auth/utils';

interface AppContextInterface {
  user: User;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

export const AuthContext = createContext<AppContextInterface>({} as AppContextInterface);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { data } = useQuery(['user'], () => fetchUser());

  useQuery(['refresh-user-data'], async () => {
    if (!window.location.href.includes('provisioning-requests')) {
      await authFetch(`${process.env.REACT_APP_SIM_INVENTORY_USERS_MODULE_API}/me`);
    }
  });

  return data ? (
    <AuthContext.Provider
      value={{
        user: data,
        login: loginRedirect,
        logout: logoutRedirect,
      }}
    >
      {children}
    </AuthContext.Provider>
  ) : null;
};
