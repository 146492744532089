import Stack from '@mui/material/Stack';
import { Chip } from './Chip';
import Box from '@mui/material/Box';
import useMeasure from 'react-use-measure';
import IconButton from '@mui/material/IconButton';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { useTheme } from '@mui/material/styles';

export type ChipsClipperProps = {
  labels: string[];
  hasMore?: boolean;
};

export const ChipsClipper = ({ labels, hasMore = false }: ChipsClipperProps) => {
  const [root, rootBounds] = useMeasure();
  const [chipsBox, chipsBoxBounds] = useMeasure();

  const diffWidth = rootBounds.width - chipsBoxBounds.width;

  const contentNotFit = diffWidth <= 0;
  const displayMoreButton = contentNotFit || hasMore;

  const { palette } = useTheme();
  return (
    <Stack width={'100%'} direction="row" ref={root} gap={1}>
      <Box sx={{ overflow: 'hidden', '-webkit-mask-image': 'linear-gradient(90deg, #fff 80%, transparent)' }}>
        <Stack direction="row" ref={chipsBox} width={'max-content'} gap={1}>
          {labels.map((label, index) => (
            <Chip label={label} key={index} variant="outlined" />
          ))}
        </Stack>
      </Box>
      {displayMoreButton ? (
        <Box>
          <IconButton
            size="small"
            sx={{ width: '18px', height: '24px', p: 0, pointerEvents: 'none' }}
          >
            <MoreHorizOutlinedIcon
              style={{ marginBottom: -20, width: '18px', color: palette.text.tertiary }}
            />
          </IconButton>
        </Box>
      ) : null}
    </Stack>
  );
};
