import { useContext } from 'react';
import { useQuery } from 'react-query';
import { TagContext } from './TagProvider';

export const useAssignedTags = (id: string) => {
  const { fetchAssignedTags } = useContext(TagContext);
  return useQuery(
    ['tags', id],
    async () => {
      const tags = await fetchAssignedTags(id);
      return tags.map(({ name }) => name);
    },
    {
      enabled: false,
      suspense: false
    }
  );
};
