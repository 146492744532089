import Box from '@mui/material/Box';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurableTableProps, TableBaseProps } from './TableBase.interface';
import { simInventoryColumnsDefaultWidth, simInventoryColumnsMaxWidth } from './TableBase.style';
import {
  ColumnConfiguration,
  TABLE_VARIANT,
} from '../../SimInventoryApi/simInventoryTableConfigurationApi.interface';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { BaseCell } from './tableCells/BaseCell';
import { HeaderCell } from './tableCells/HeaderCell';
import { DefaultThemeProvider } from 'theme/DefaultThemeProvider';
import { SimInventoryEditDialog } from '../../SimInventoryDetails/SimInventoryEditDialog';
import { TableSettings } from './Settings/TableSettings';
import { RowSettings } from './Settings/RowSettings';
import { useColumnConfiguration } from './hooks/useColumnConfiguration';
import { useColumnConfigurationActions } from './hooks/useColumnConfigurationActions';
import { SimInventoryDataGrid } from './Components/SimInventoryDataGrid';
import { SimInventoryCan } from 'permissions/PermissionProvider';
import { Actions, Subjects } from 'permissions/ability';

export const TableBase: React.FC<TableBaseProps> = React.memo(
  ({
    items,
    onTagsUpdate,
    flatColumnConfigurations,
    combinedColumnConfigurations,
    enabledVariant,
    sort,
    onSortChange,
    updateVariant,
    revertToDefault,
  }) => {
    const mappedItems = items.map((i) => {
      const mappedCustomFields: { [key: string]: any } = {};
      i.customFields?.forEach((field) => {
        if (mappedCustomFields[field.label.toLowerCase()]) {
          const values = mappedCustomFields[field.label.toLowerCase()].selectionOption.split(', ');
          if (!values.includes(field.selectionOption)) {
            values.push(field.selectionOption);
          }
          mappedCustomFields[field.label.toLowerCase()].selectionOption = values.join(', ');
        } else {
          mappedCustomFields[field.label.toLowerCase()] = field;
        }
      });
      return { ...i, ...mappedCustomFields };
    });

    const { t } = useTranslation();
    const { updateWidthOfColumn, reorder } = useColumnConfigurationActions();

    const idLabelMapForFlatTable: Record<string, string> = useMemo(
      () => ({
        iccid: t('common.iccid'),
        imsi: t('common.imsi'),
        msisdn: t('common.msisdn'),
        currentIpAddress: t('common.currentIp'),
        managementIpAddress: t('common.managementIp'),
        mnoRatePlan: t('common.plan'),
        organisation: t('common.organisation'),
        label: t('common.label'),
        subscriptionStatus: t('common.subscriptionStatus'),
        subscriptionStatusEntryTime: t('simInventory.subscriptionStatusEntryTime_flat'),
        customerCommsPlan: t('simInventory.customerCommsPlan'),
        commsPlan: t('simInventory.commsPlan'),
        mobileNetworkOperator: t('simInventory.mobileNetworkOperator'),
        accountName: t('common.accountName'),
        accountRef: t('common.accountNumber'),
        liveDataUsage: t('common.usage'),
        inMinuteUsage: t('common.inMinuteUsage'),
        outMinuteUsage: t('common.outMinuteUsage'),
        inTextUsage: t('common.inTextUsage'),
        outTextUsage: t('common.outTextUsage'),
        sessionStartTime: t('simInventory.sessionStartTime'),
        sessionEndTime: t('simInventory.sessionEndTime'),
        sessionStatus: t('simInventory.sessionStatus'),
        sessionStatusLastUpdated: t('simInventory.sessionStatusLastUpdated_flat'),
        connectionId: t('simInventory.connectionId'),
        orderNumber: t('simInventory.orderNumber'),
        tags: t('common.tag'),
        simType: t('simInventory.simType'),
        activeConfiguration: t('simInventory.activeConfiguration'),
        productCode: t('simInventory.productCode'),
        billingStatus: t('simInventory.billingStatus'),
      }),
      [],
    );
    const idLabelMapForCombinedTable: Record<string, string> = useMemo(
      () => ({
        ...idLabelMapForFlatTable,
        subscriptionStatusEntryTime: t('simInventory.subscriptionStatusEntryTime_combined'),
        sessionStatusLastUpdated: t('simInventory.sessionStatusLastUpdated_combined'),
      }),
      [],
    );

    const columnConfiguration: ColumnConfiguration[] =
      enabledVariant === TABLE_VARIANT.Combined
        ? combinedColumnConfigurations
        : flatColumnConfigurations;

    const [editedSim, setEditedSim] = useState<string | null>(null);

    const handleEditDialogClose = useCallback(() => {
      setEditedSim(null);
    }, []);

    const columns = useMemo(() => {
      const columns = columnConfiguration?.reduce(
        (enabledColumns: GridColDef[], column: ColumnConfiguration) => {
          const columnDefinition: GridColDef = {
            field: column.name,
            //@ts-ignore
            width:
              column.width && column.width !== 'auto'
                ? column.width
                : simInventoryColumnsDefaultWidth[column.name] &&
                  enabledVariant === TABLE_VARIANT.Combined
                ? simInventoryColumnsDefaultWidth[column.name]?.combined
                : simInventoryColumnsDefaultWidth[column.name] &&
                  enabledVariant === TABLE_VARIANT.Flat
                ? simInventoryColumnsDefaultWidth[column.name]?.flat
                : 130,
            minWidth: 70,
            maxWidth:
              enabledVariant === TABLE_VARIANT.Combined
                ? simInventoryColumnsMaxWidth[column.name]?.combined || 400
                : simInventoryColumnsMaxWidth[column.name]?.flat || 400,
            renderHeader: () => (
              <HeaderCell
                column={column}
                primaryText={
                  !column.isCustomField
                    ? enabledVariant === TABLE_VARIANT.Combined
                      ? idLabelMapForCombinedTable[column.name]
                      : idLabelMapForFlatTable[column.name]
                    : column.name
                }
                secondaryText={
                  !column.isCustomField
                    ? enabledVariant === TABLE_VARIANT.Combined
                      ? idLabelMapForCombinedTable[column.children[0]?.name]
                      : idLabelMapForFlatTable[column.children[0]?.name]
                    : column.children[0]?.name
                }
                enableSortingPrimary={column.sortable}
                enableSortingSecondary={column.children[0]?.sortable || false}
                handleSort={onSortChange}
                sortedColumn={sort}
                verticalAlign="top"
              />
            ),
            renderCell: (params: GridRenderCellParams) => (
              <BaseCell params={params} column={column} variant={enabledVariant} />
            ),
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
          };
          enabledColumns.push(columnDefinition);

          return enabledColumns;
        },
        [],
      );

      columns.push({
        field: 'actions',
        resizable: false,
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        cellClassName: 'sticky',
        headerClassName: 'header-sticky',
        renderHeader: () => (
          <SimInventoryCan I={Actions.edit} a={Subjects.simProfilesTableConfiguration}>
            <TableSettings
              columnConfig={columnConfiguration}
              handleWidthChange={updateWidthOfColumn}
              handleVariantChange={handleVariantChange}
              handleRevert={revertToDefault}
              handleDrop={reorder}
              variant={enabledVariant}
              idLabelMap={
                enabledVariant === TABLE_VARIANT.Combined
                  ? idLabelMapForCombinedTable
                  : idLabelMapForFlatTable
              }
            />
          </SimInventoryCan>
        ),
        renderCell: (params) => <RowSettings row={params.row} setEditedSim={setEditedSim} />,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
      });

      return columns;
    }, [columnConfiguration, enabledVariant, sort]);

    const visibilityModel = useMemo(
      () =>
        columnConfiguration.reduce(
          (visibilityModel: Record<string, boolean>, columnConfiguration: ColumnConfiguration) => {
            return {
              ...visibilityModel,
              [columnConfiguration.name]: columnConfiguration.enabled,
            };
          },
          {} as Record<string, boolean>,
        ),
      [],
    );

    const handleVariantChange = useCallback(
      async (isChecked: boolean) => {
        updateVariant(isChecked ? TABLE_VARIANT.Combined : TABLE_VARIANT.Flat);
      },
      [updateVariant],
    );

    const initialState = useMemo(() => {
      return {
        columns: {
          columnVisibilityModel: { ...visibilityModel, simStatus: false },
        },
      };
    }, [visibilityModel]);

    return (
      <Box sx={{ width: '100%', height: '100%' }}>
        <SimInventoryDataGrid
          rowHeight={enabledVariant === TABLE_VARIANT.Combined ? 52 : 42}
          columns={columns}
          rows={mappedItems}
          initialState={initialState}
          columnConfiguration={columnConfiguration}
        />

        {editedSim ? (
          <Suspense>
            <DefaultThemeProvider>
              <SimInventoryEditDialog
                open
                iccid={editedSim}
                onClose={handleEditDialogClose}
                onTagsUpdate={onTagsUpdate}
                queryKey={'SimInventoryConfigurable'}
              />
            </DefaultThemeProvider>
          </Suspense>
        ) : null}
      </Box>
    );
  },
);

export const ConfigurableTable = (props: ConfigurableTableProps) => {
  const { configuration, updateVariant, revertToDefault } = useColumnConfiguration();

  return (
    <TableBase
      {...props}
      flatColumnConfigurations={configuration!.flatColumnConfigurations}
      combinedColumnConfigurations={configuration!.combinedColumnConfigurations}
      enabledVariant={configuration!.enabledVariant}
      updateVariant={updateVariant}
      revertToDefault={revertToDefault}
      key={configuration?.enabledVariant}
    />
  );
};
