import { BoxTwoValueCell } from 'common/components/Table/BoxTwoValueCell';
import { TwoValueCellProps } from 'common/components/Table/TwoValueCell.interface';

export type SessionHistoryCellProps = Pick<
  TwoValueCellProps,
  'primaryText' | 'secondaryText' | 'sx'
>;

export const SessionHistoryCell: React.FC<SessionHistoryCellProps> = ({
  primaryText,
  secondaryText,
  sx
}) => {
  return (
    <BoxTwoValueCell
      primaryText={primaryText}
      secondaryText={secondaryText}
      primaryVariant="tableCellPrimary"
      secondaryVariant="tableCellSecondary"
      sx={sx}
    />
  );
};
