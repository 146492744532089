import { authFetch } from 'auth/utils';
import {
  ErrorResponse,
  SimInventoryData,
  SimInventoryResponse,
  SimProfile,
} from 'simInventory/SimInventoryApi/simInventoryApi.interface';
import { RSimInventoryData, RsimProfileStatus, SimProfilesByEuicc } from './rSimApi.interface';
import { mapSimProfileDTO } from 'simInventory/SimInventoryApi/simInventoryApi.utils';
import {
  LAST_UPDATE_TIME_FILTERS,
  LastActiveProfileFilter,
  LastUpdateTimeFilters,
  RSimFilters,
  SIMULATE_OUTAGE_FILTER,
  SimulateOutageFilter,
} from 'rSimInventory/Filters/data/filters.constants';
import { mapLastUpdateTimeToDateTime } from 'rSimInventory/Filters/utils/lastUpdateTimeMappers';
import { mapLastActiveProfileFilterToValue } from 'rSimInventory/Filters/utils/lastActiveProfileFilterMappers';
import { convertStringToTags, normilizeAssignedTagsToRSim } from 'tags/utils';
import { fetchAssignedTagToRSims } from 'tags/services/tagsRSimApi';
import { mapRSimProfileDTO } from './rSimApi.utils';
import { ErrorCodes } from 'common/errorCodes';
import { Sorting } from 'common/hooks/useSorting';
import { uppercaseFirstLetter } from 'common/utils/stringUtil';
import {
  ACTIVE_PROFILE,
  ACTIVE_PROFILE_FILTER,
  NUMERIC_RSIM_PROFILE_IDS,
  RSimInventoryExtededFilterBody,
  RSimInventoryFilters,
  SIMULATED_OUTAGE_STATE,
  SIMULATED_OUTAGE_STATUS,
} from 'rSimInventory/models/RSimProfile.model';

export enum LastActiveProfile {
  Unknown,
  Primary,
  Secondary,
}

type CycleToData = {
  dataUsage: number;
  inMinuteUsage: number;
  inTextUsage: number;
  outMinuteUsage: number;
  outTextUsage: number;
};

type RSimProfileResponse = {
  accountName: string;
  accountRef: number;
  euicc: string;
  lastAckTimestamp: string;
  lastActiveProfile: LastActiveProfile;
  primaryIccid: string;
  rsimProfileStatus: RsimProfileStatus;
  secondaryIccid: string;
  cycleTo: CycleToData;
  appliedOtaConfigurationProfile: string;
  appliedOtaConfigurationVersion: string;
  isPendingConfiguration: boolean;
  isCustomConfigurationDetected: boolean;
  connectionId: string;
  orderNumber: string;
  customerReference: string;
  iccidOta: string;
};

export type RsimProfilesResponse = RSimProfileResponse[];

export type RSimInventoryResponsePagination = {
  totalCount: number;
  totalPages: number;
};

const prepareRSimInventorySearchQuery = (filters: Partial<RSimFilters>) => {
  const urlQuery = new URLSearchParams();

  if (filters.lastUpdateTime && filters.lastUpdateTime !== LAST_UPDATE_TIME_FILTERS.NONE) {
    const noPollingFrom = mapLastUpdateTimeToDateTime(filters.lastUpdateTime)?.toISOString();
    noPollingFrom && urlQuery.append('NoPollingFrom', noPollingFrom);
  }

  if (filters.lastActiveProfile && filters.lastActiveProfile !== ACTIVE_PROFILE_FILTER.ANY) {
    const lastActiveProfileValue = mapLastActiveProfileFilterToValue(filters.lastActiveProfile);
    lastActiveProfileValue &&
      urlQuery.append('LastActiveProfile', lastActiveProfileValue.toString());
  }

  if (filters.tags) {
    const tagsList = convertStringToTags(filters.tags);
    tagsList.forEach((tag) => {
      urlQuery.append('Tags', tag);
    });
  }

  if (filters.accounts) {
    const accountsList = convertStringToTags(filters.accounts);
    accountsList.forEach((account) => {
      urlQuery.append('AccountRef', account);
    });
  }

  if (filters.connectionId) {
    urlQuery.append('ConnectionId', filters.connectionId);
  }

  if (filters.orderNumber) {
    urlQuery.append('OrderNumber', filters.orderNumber);
  }

  if (filters.simulateOutage) {
    if (filters.simulateOutage === SIMULATE_OUTAGE_FILTER.OFF) {
      urlQuery.append('SimulatedOutageStatuses', '0');
    } else if (filters.simulateOutage === SIMULATE_OUTAGE_FILTER.ON) {
      urlQuery.append('SimulatedOutageStatuses', '1');
      urlQuery.append('SimulatedOutageStatuses', '2');
      urlQuery.append('SimulatedOutageStatuses', '3');
    }
  }

  return urlQuery;
};

export const fetchRSimInventory = async (
  page: number,
  jobsPerPage: number,
  iccid?: string,
  euicc?: string,
  lastActiveProfile?: LastActiveProfileFilter,
  lastUpdateTime?: LastUpdateTimeFilters,
  tags?: string,
  connectionId?: string,
  orderNumber?: string,
  simulationOutage?: SimulateOutageFilter,
  accounts?: string,
  sort: { columnName: string; sort: Sorting } | null = null,
  signal?: AbortSignal,
): Promise<RSimInventoryData> => {
  const urlQuery = prepareRSimInventorySearchQuery({
    lastUpdateTime,
    lastActiveProfile,
    tags,
    accounts,
    connectionId,
    orderNumber,
    simulateOutage: simulationOutage,
  });

  urlQuery.append('PageNumber', (page + 1).toString());
  urlQuery.append('PageSize', jobsPerPage.toString());

  if (euicc) {
    urlQuery.append('Euicc', euicc);
  }
  if (iccid) {
    urlQuery.append('Iccid', iccid);
  }

  if (sort) {
    urlQuery.append(
      'Order',
      `${uppercaseFirstLetter(sort.columnName)}:${sort.sort?.toUpperCase()}`,
    );
  }

  const rsimProfilesResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_RSIMS_PROFILE_API}?${urlQuery.toString()}`,
    { signal },
  );

  const rsimProfiles: RsimProfilesResponse = await rsimProfilesResponse.json();

  const assignedTags = await fetchAssignedTagToRSims({
    euiccs: rsimProfiles.map((rsim) => rsim.euicc),
  });

  const normalizedTagsData = normilizeAssignedTagsToRSim(assignedTags);
  const items = mapRSimProfileDTO(rsimProfiles, { normalizedTagsData });

  return {
    items,
  };
};

const prepareRSimInventorySearchBody = (
  searchParams: RSimInventoryFilters,
  sort: { columnName: string; sort: Sorting } | null = null,
) => {
  const body: RSimInventoryExtededFilterBody = {};

  if (searchParams.searchIds.length === 1) {
    const id = searchParams.chosenIdType === 'EID' ? 'euicc' : 'iccid';
    body[id] = searchParams.searchIds[0];
  } else if (searchParams.searchIds.length > 0 && searchParams.searchIds[0]) {
    body['rsimProfileIdsFilter'] = {
      type: NUMERIC_RSIM_PROFILE_IDS[searchParams.chosenIdType],
      ids: searchParams.searchIds,
    };
  }

  if (searchParams.simulateOutage) {
    if (searchParams.simulateOutage === SIMULATED_OUTAGE_STATE.OFF) {
      body.simulatedOutageStatuses = [SIMULATED_OUTAGE_STATUS.NONE];
    } else if (searchParams.simulateOutage === SIMULATED_OUTAGE_STATE.ON) {
      body.simulatedOutageStatuses = [
        SIMULATED_OUTAGE_STATUS.PRIMARY,
        SIMULATED_OUTAGE_STATUS.SECONDARY,
        SIMULATED_OUTAGE_STATUS.BOTH,
      ];
    }
  }

  if (searchParams.connectionId) {
    body.connectionId = searchParams.connectionId;
  }

  if (searchParams.orderNumber) {
    body.orderNumber = searchParams.orderNumber;
  }

  if (
    searchParams.lastActiveProfile &&
    searchParams.lastActiveProfile !== ACTIVE_PROFILE_FILTER.ANY
  ) {
    if (searchParams.lastActiveProfile === ACTIVE_PROFILE_FILTER.PRIMARY) {
      body.lastActiveProfile = ACTIVE_PROFILE.PRIMARY;
    } else if (searchParams.lastActiveProfile === ACTIVE_PROFILE_FILTER.SECONDARY) {
      body.lastActiveProfile = ACTIVE_PROFILE.SECONDARY;
    }
  }

  if (searchParams.tags) {
    body.tags = convertStringToTags(searchParams.tags);
  }

  if (searchParams.accounts) {
    const accountsList = convertStringToTags(searchParams.accounts);
    body.accountRef = accountsList.map((accountRef) => Number(accountRef));
  }

  if (
    searchParams.lastUpdateTime &&
    searchParams.lastUpdateTime !== LAST_UPDATE_TIME_FILTERS.NONE
  ) {
    body.noPollingFrom = mapLastUpdateTimeToDateTime(searchParams.lastUpdateTime)?.toISOString();
  }

  if (searchParams.eidFrom && searchParams.eidTo) {
    body.rsimProfileIdsRange = {
      type: 1,
      idsFrom: searchParams.eidFrom,
      idsTo: searchParams.eidTo,
    };
  }

  if (sort) {
    body.order = `${uppercaseFirstLetter(sort.columnName)}:${sort.sort?.toUpperCase()}`;
  }

  return body;
};

export const preparePaginatedRSimInventorySearchBody = (
  page: number,
  entryPerPage: number,
  searchParams: RSimInventoryFilters,
  sort: { columnName: string; sort: Sorting } | null = null,
) => {
  const body = prepareRSimInventorySearchBody(searchParams, sort);
  body.pageNumber = page + 1;
  body.pageSize = entryPerPage;

  return body;
};

export const fetchRSimInventoryExtendedFilters = async (
  page: number,
  entryPerPage: number,
  filters: RSimInventoryFilters,
  sort: { columnName: string; sort: Sorting } | null = null,
  signal?: AbortSignal,
) => {
  const body = preparePaginatedRSimInventorySearchBody(page, entryPerPage, filters, sort);

  const rsimInventoryResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_RSIMS_PROFILE_API}`,
    {
      signal,
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  const rsimProfiles: RsimProfilesResponse = await rsimInventoryResponse.json();

  const assignedTags = await fetchAssignedTagToRSims({
    euiccs: rsimProfiles.map((rsim) => rsim.euicc),
  });

  const normalizedTagsData = normilizeAssignedTagsToRSim(assignedTags);
  const items = mapRSimProfileDTO(rsimProfiles, { normalizedTagsData });

  return {
    items,
  };
};

export const fetchRSimInventoryPagination = async (
  page: number,
  jobsPerPage: number,
  iccid?: string,
  euicc?: string,
  lastActiveProfile?: LastActiveProfileFilter,
  lastUpdateTime?: LastUpdateTimeFilters,
  tags?: string,
  connectionId?: string,
  orderNumber?: string,
  simulationOutage?: SimulateOutageFilter,
  accounts?: string,
  sort: { columnName: string; sort: Sorting } | null = null,
  signal?: AbortSignal,
) => {
  const urlQuery = prepareRSimInventorySearchQuery({
    lastUpdateTime,
    lastActiveProfile,
    tags,
    accounts,
    connectionId,
    orderNumber,
    simulateOutage: simulationOutage,
  });

  urlQuery.append('PageNumber', (page + 1).toString());
  urlQuery.append('PageSize', jobsPerPage.toString());

  if (euicc) {
    urlQuery.append('Euicc', euicc);
  }
  if (iccid) {
    urlQuery.append('Iccid', iccid);
  }

  const rsimInventoryPaginationResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_RSIMS_PROFILE_API}/pagination?${urlQuery.toString()}`,
    { signal },
  );

  const rsimInventoryPaginationResponseData: RSimInventoryResponsePagination =
    await rsimInventoryPaginationResponse.json();

  return {
    totalNumberOfItems:
      rsimInventoryPaginationResponseData.totalCount !== undefined
        ? rsimInventoryPaginationResponseData.totalCount
        : -1,
  };
};

export const fetchRSimInventoryExtendedFiltersPagination = async (
  filters: RSimInventoryFilters,
  sort: { columnName: string; sort: Sorting } | null = null,
  signal?: AbortSignal,
) => {
  const body = prepareRSimInventorySearchBody(filters, sort);

  const rsimInventoryPaginationResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_RSIMS_PROFILE_API}/pagination`,
    {
      signal,
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  const rsimInventoryPaginationResponseData: RSimInventoryResponsePagination =
    await rsimInventoryPaginationResponse.json();

  return {
    totalNumberOfItems:
      rsimInventoryPaginationResponseData.totalCount !== undefined
        ? rsimInventoryPaginationResponseData.totalCount
        : -1,
  };
};

export const fetchRSimSimsProfiles = async (
  euicc: string,
  signal?: AbortSignal,
): Promise<
  Omit<SimInventoryData, 'numberOfPages' | 'totalNumberOfItems' | 'items'> & {
    items: SimProfile[];
  }
> => {
  const simInventoryEntryResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/euicc/${euicc}`,
    { signal },
  );

  const simInventoryResponse: SimInventoryResponse = await simInventoryEntryResponse.json();

  return {
    headers: simInventoryResponse.headers,
    items: simInventoryResponse.items.map((item) => {
      const simInventory = mapSimProfileDTO(item);
      return { ...simInventory, pollingSimStatus: '' };
    }),
  };
};

export type RSimEnability = {
  euicc: string;
  isPrimaryProfileDisabled: boolean;
  isSecondaryProfileDisabled: boolean;
};

export type RSimEnabilities = Record<
  string,
  Pick<RSimEnability, 'isPrimaryProfileDisabled' | 'isSecondaryProfileDisabled'>
>;

export const fetchRSimEnability = async (euiccs: string[], signal?: AbortSignal) => {
  const rSimEnabilityResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_RSIMS_PROFILE_API}/rsim-enability`,
    {
      signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        rSimsEuiccs: euiccs,
      }),
    },
  );

  const rSimEnability: RSimEnability[] = await rSimEnabilityResponse.json();

  return rSimEnability.reduce((acc, value) => {
    const { euicc, ...disabledValues } = value;
    return {
      ...acc,
      [euicc]: disabledValues,
    };
  }, {} as RSimEnabilities);
};

export const setRSimEnability = async (
  euicc: string,
  profileId: 0 | 1,
  enable: boolean,
  signal?: AbortSignal,
) => {
  const response = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_RSIMS_PROFILE_API}/set-rsim-enability`,
    {
      signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        euicc,
        profileId,
        enable,
      }),
    },
  );

  if (!response.ok) {
    throw new Error(response.statusText);
  }
};

export const fetchSimProfilesByEuicc = async (euicc: string, signal?: AbortSignal) => {
  const simProfilesResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/euicc/${euicc}`,
    { signal },
  );

  if (!simProfilesResponse.ok) {
    const status = simProfilesResponse.status.toString();

    let errorMessage: ErrorResponse;
    try {
      errorMessage = await simProfilesResponse.json();
    } catch (_err) {
      throw new Error(status);
    }

    const euiccNotFoundErrors = errorMessage.validationErrors.Euicc?.find(
      (error) => error.code === 'EuiccValidator',
    );

    if (status === ErrorCodes.BadRequest && euiccNotFoundErrors) {
      throw new Error(ErrorCodes.NotFound);
    }

    throw new Error(status);
  }

  const simProfilesByEuiccJson = await simProfilesResponse.json();
  const simProfilesByEuicc: SimProfilesByEuicc[] = simProfilesByEuiccJson.items;

  return simProfilesByEuicc;
};
