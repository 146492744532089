import { DateRangeValidationError } from '@mui/x-date-pickers-pro';
import { DateRangeValuePiece } from './DateRangePickerMui';
import { DateTimeRangeValidationError } from '@mui/x-date-pickers-pro/models/validation';

const MIN_POSSIBLE_DATE = new Date('01/01/2000');

export const basicErrorHandling = (
  currentRange: [DateRangeValuePiece, DateRangeValuePiece],
  error: DateRangeValidationError | DateTimeRangeValidationError,
  minDate?: DateRangeValuePiece,
  maxDate?: DateRangeValuePiece,
): [DateRangeValuePiece, DateRangeValuePiece] => {
  if (
    (error[0] === 'minDate' && (error[1] === 'maxDate' || error[1] === 'disableFuture')) ||
    ((error[0] === 'maxDate' || error[0] === 'disableFuture') && error[1] === 'minDate')
  ) {
    return [minDate || MIN_POSSIBLE_DATE, maxDate || new Date()];
  }
  if (
    (error[0] === 'maxDate' || error[0] === 'disableFuture') &&
    (error[1] === 'maxDate' || error[1] === 'disableFuture')
  ) {
    return [maxDate || new Date(), maxDate || new Date()];
  }
  if (error[0] === 'minDate' && error[1] === 'minDate') {
    return [minDate || MIN_POSSIBLE_DATE, minDate || MIN_POSSIBLE_DATE];
  }
  if (error[0] === 'minDate') {
    return [minDate || MIN_POSSIBLE_DATE, currentRange[1]];
  }
  if (error[1] === 'minDate') {
    return [currentRange[0], maxDate || new Date()];
  }
  if (error[0] === 'maxDate' || error[0] === 'disableFuture') {
    return [maxDate || new Date(), maxDate || new Date()];
  }
  if (error[1] === 'maxDate' || error[1] === 'disableFuture') {
    return [currentRange[0], maxDate || new Date()];
  }
  return currentRange;
};
