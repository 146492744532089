import Box from '@mui/material/Box';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type DetailsSkeletonProps = {
  numberOfRepetitions?: number;
  hideBorder?: boolean;
  sx?: SxProps<Theme>;
};

export const DetailsSkeleton: React.FC<DetailsSkeletonProps> = ({
  numberOfRepetitions = 5,
  hideBorder = false,
  sx,
}) => {
  return (
    <Box sx={{ height: '100%', position: 'relative', ...sx }} data-testid={'detailsSkeleton'}>
      <>
        {[...Array(numberOfRepetitions)].map((e, i) => (
          <Box key={i}>
            <Box
              sx={{
                borderBottom: ({ palette }) =>
                  !hideBorder ? `1px solid ${palette.tableBorder.main}` : '',
                pt: !hideBorder && i !== 0 ? 3 : 0,
                pb: !hideBorder ? 3 : 0,
                pl: !hideBorder ? 3 : 0,
              }}
            >
              <Skeleton data-testid="skeleton-details" width={'10%'} height={24} />
              <Skeleton data-testid="skeleton-details" width={'25%'} height={24} />
              <Skeleton data-testid="skeleton-details" width={'100%'} height={24} />
            </Box>
          </Box>
        ))}
      </>
    </Box>
  );
};
