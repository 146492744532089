import { ObjectValues } from 'common/tsHelpers';

import React from 'react';
import {
  assignTagsToSim,
  createSimTag,
  fetchAssignedTagToSim,
  fetchSimTag,
  fetchSimTags,
} from './services/tagsSimApi';
import { Tag } from './services/tagsApi.types';
import {
  assignTagsToRSim,
  createRSimTag,
  fetchAssignedTagToRSim,
  fetchRSimTag,
  fetchRSimTags,
} from './services/tagsRSimApi';

export const CARD_TYPE = {
  SIM: 'sim',
  RSIM: 'rsim',
} as const;

export type CardType = ObjectValues<typeof CARD_TYPE>;

type TagProviderProps = {
  children: React.ReactNode;
  type: CardType;
};

export type TagContextProps = {
  type: CardType;
  fetchTags: () => Promise<Tag[]>;
  fetchTag: (id: string) => Promise<Tag>;
  fetchAssignedTags: (id: string) => Promise<Tag[]>;
  assignTags: (id: string, selectedValues: string[]) => Promise<void>;
  createTag: (name: string) => Promise<Tag>;
};

export const TagContext = React.createContext<TagContextProps>({
  type: CARD_TYPE.SIM,
  fetchTag: () => {
    throw new Error('not implemented');
  },
  fetchTags: () => {
    throw new Error('not implemented');
  },
  fetchAssignedTags: () => {
    throw new Error('not implemented');
  },
  assignTags: (id: string, selectedValues: string[]) => {
    throw new Error('not implemented');
  },
  createTag: () => {
    throw new Error('not implemented');
  },
});

export const TagProvider = ({ children, type }: TagProviderProps) => {
  const fetchTag = async (id: string) => {
    if (type === CARD_TYPE.SIM) {
      return fetchSimTag(id);
    }
    return fetchRSimTag(id);
  };

  const fetchTags = async () => {
    if (type === CARD_TYPE.SIM) {
      return fetchSimTags();
    }
    return fetchRSimTags();
  };

  const fetchAssignedTags = async (id: string) => {
    if (type === CARD_TYPE.SIM) {
      return fetchAssignedTagToSim(id);
    }
    return fetchAssignedTagToRSim(id);
  };

  const assignTags = async (id: string, selectedValues: string[]) => {
    if (type === CARD_TYPE.SIM) {
      return assignTagsToSim(id, selectedValues);
    }

    return assignTagsToRSim(id, selectedValues);
  };

  const createTag = async (name: string) => {
    if (type === CARD_TYPE.SIM) {
      return createSimTag(name);
    }

    return createRSimTag(name);
  };

  return (
    <TagContext.Provider
      value={{ assignTags, fetchTag, fetchAssignedTags, createTag, fetchTags, type }}
    >
      {children}
    </TagContext.Provider>
  );
};
