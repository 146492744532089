import { ErrorCodes } from 'common/errorCodes';
import { useQuery } from 'react-query';
import { fetchSimInventoryEntry } from 'simInventory/SimInventoryApi/simInventoryApi';

export const useSessionsEntry = (iccid: string) => {
  const queryData = useQuery(['SimInventoryEntry', iccid], () => fetchSimInventoryEntry(iccid!), {
    enabled: !!iccid,
    retry: (failureCount, error) => {
      if (error instanceof Error && error.message === ErrorCodes.NotFound) {
        return false;
      }
      return failureCount < 5;
    }
  });

  return queryData;
};
