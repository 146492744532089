import { Option } from '../components/Inputs/AutocompleteSelect/AutocompleteSelect';
import { useState } from 'react';

const MAX_HINTS_PER_FILTER = 10;

export const filterArray = (options: Option[]) => {
  const op = options.filter((o: Option) => o.id !== '');
  return op.reduce((p: Option[], c: Option) => {
    if (!p.some((el: Option) => el.id === c.id)) {
      p.push(c);
    }
    return p;
  }, []);
};

export const removeExcessHints = (hintGroup: Option[]) => {
  const hints = filterArray(hintGroup);
  if (hints.length > MAX_HINTS_PER_FILTER) {
    const numOfItemsToSlice = hints.length - MAX_HINTS_PER_FILTER;
    return hints.slice(numOfItemsToSlice);
  }
  return hints;
};

export const useHints = <T extends { id: string | number }>(hintKey: string) => {
  const getHintsFromStorage = () => {
    const prevHints = localStorage.getItem(hintKey);
    return prevHints ? JSON.parse(prevHints) : {};
  };

  const [internalHints, setInternalHints] = useState<{ [key: string]: any }>(getHintsFromStorage());

  const saveHintsToStorage = <T extends { id: string | number }>(newHints: {
    [key: string]: T[];
  }) => {
    const prevHints = getHintsFromStorage();
    const cleanedHints = {};

    for (const [key] of Object.entries(newHints)) {
      const prevHintsWithKey = prevHints[key] ?? [];
      const group = [...prevHintsWithKey, ...newHints[key]];
      // @ts-ignore
      cleanedHints[key] = removeExcessHints(group);
    }
    setInternalHints({ ...prevHints, ...cleanedHints });
    localStorage.setItem(hintKey, JSON.stringify(cleanedHints));
  };

  const removeHint = (hint: T, hintName: string) => {
    const prevHints = getHintsFromStorage();
    let hintGroup = prevHints[hintName];
    if (hintGroup) {
      hintGroup = hintGroup.filter((h: T) => h.id !== hint.id);
    }
    setInternalHints({ ...prevHints, [hintName]: hintGroup });
    localStorage.setItem(hintKey, JSON.stringify({ ...prevHints, [hintName]: hintGroup }));
  };

  return { internalHints, setInternalHints, saveHintsToStorage, removeHint, getHintsFromStorage };
};
