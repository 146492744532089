import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  createCustomField,
  deleteCustomField,
  editCustomField,
  editSimCustomFieldsData,
  fetchCustomFields,
  saveSelectionOptions,
} from '../api/customFieldsApi';
import {
  CreateCustomField,
  EditCustomField,
  EditCustomFieldSelectionOptions,
} from '../entities/customFields.entity';
import { useHasFeatureFlag } from '../../../featureFlags/useHasFeatureFlag';
import { useAbility } from 'permissions/hooks/useAbility';
import { Actions, Subjects } from 'permissions/ability';
import { setMinimalPromiseTime } from 'common/utils/setMinimalPromiseTime';

const CustomFieldsKey = ['CustomFields'];

export const useCustomFields = () => {
  const ability = useAbility();
  const customFieldsEnabled =
    useHasFeatureFlag('CustomFields') && ability.can(Actions.read, Subjects.customFields);
  const {
    data: customFields,
    refetch,
    status,
    isRefetching,
  } = useQuery(
    [...CustomFieldsKey, customFieldsEnabled],
    async () => setMinimalPromiseTime(fetchCustomFields()),
    {
      enabled: customFieldsEnabled,
    },
  );

  const queryClient = useQueryClient();

  const { mutate: createField } = useMutation(
    async (data: CreateCustomField) => {
      return createCustomField(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: CustomFieldsKey });
      },
    },
  );

  const { mutate: editField } = useMutation(
    async (data: EditCustomField) => {
      return editCustomField(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: CustomFieldsKey });
      },
    },
  );

  const { mutate: deleteField } = useMutation(
    async (id: number) => {
      return deleteCustomField(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: CustomFieldsKey });
      },
    },
  );

  const { mutate: editSelectionOptions } = useMutation(
    async (data: EditCustomFieldSelectionOptions) => {
      return saveSelectionOptions(data.id, data.selectionOptions);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: CustomFieldsKey });
      },
    },
  );

  const { mutateAsync: editSimCustomFields } = useMutation(
    async (data: { iccid: string; customFields: { id: number; value: string }[] }) => {
      return editSimCustomFieldsData(data.iccid, data.customFields);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: CustomFieldsKey });
      },
    },
  );

  return {
    customFields,
    createField,
    editField,
    deleteField,
    editSimCustomFields,
    refetch,
    status,
    isRefetching,
    editSelectionOptions,
  };
};
