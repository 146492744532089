import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { fetchCustomFields } from '../../admin/customFields/api/customFieldsApi';
import { CustomField } from '../../admin/customFields/entities/customFields.entity';
import { useHasFeatureFlag } from '../../featureFlags/useHasFeatureFlag';
import {
  AssignedCustomField,
  CustomFieldValue,
} from '../SimInventoryApi/simInventoryApi.interface';
import { getAssignedFields } from 'common/components/Filters/CustomFieldsFilter/customFields.utils';

import { Actions, Subjects } from 'permissions/ability';
import { useAbility } from 'permissions/hooks/useAbility';

export const useSimCustomFields = (customFields: CustomFieldValue[]) => {
  const ability = useAbility();
  const customFieldsEnabled =
    useHasFeatureFlag('CustomFields') && ability.can(Actions.read, Subjects.customFields);

  const { data: customFieldsData } = useQuery(
    ['customFields'],
    async () => {
      const fields = await fetchCustomFields();
      return fields.filter((f: CustomField) => f.enabled) || [];
    },
    {
      suspense: false,
      enabled: customFieldsEnabled,
      initialData: customFieldsEnabled ? undefined : [],
    },
  );

  const clearCustomFields = () => {
    setAssignedCustomFields(getAssignedFields(customFields, customFieldsData || []));
  };

  const [assignedCustomFields, setAssignedCustomFields] = useState<AssignedCustomField[]>([]);

  const originalCustomFields = getAssignedFields(customFields, customFieldsData || []);

  useEffect(() => {
    if (customFieldsData) {
      setAssignedCustomFields(getAssignedFields(customFields, customFieldsData || []));
    }
  }, [customFieldsData]);

  return { customFieldsData, assignedCustomFields, originalCustomFields, setAssignedCustomFields, clearCustomFields };
};
