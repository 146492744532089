import { SortingParam } from 'common/hooks/useSorting';
import { useLazyPaginationQuery } from 'common/useLazyPaginationQuery';
import { useRefetchQuery } from 'common/useRefetchQuery';
import { setMinimalPromiseTime } from 'common/utils/setMinimalPromiseTime';
import {
  LastActiveProfileFilter,
  LastUpdateTimeFilters,
  SimulateOutageFilter,
} from 'rSimInventory/Filters/data/filters.constants';
import {
  fetchRSimInventoryExtendedFilters,
  fetchRSimInventoryExtendedFiltersPagination,
} from 'rSimInventory/RSimApi/rSimApi';
import { RSimInventoryData } from 'rSimInventory/RSimApi/rSimApi.interface';
import { RSimProfileId } from 'rSimInventory/models/RSimProfile.model';
import { useDebounce } from 'usehooks-ts';

type UseRSimInventoryProps = {
  searchIds: string[];
  searchText: string;
  chosenIdType: RSimProfileId;
  lastActiveProfile: LastActiveProfileFilter;
  lastUpdateTime: LastUpdateTimeFilters;
  tags: string;
  connectionId: string;
  orderNumber: string;
  simulateOutage: SimulateOutageFilter;
  accounts: string;
  eidFrom: string;
  eidTo: string;
  sorting: SortingParam;
};

export const useRSimInventory = ({
  searchIds,
  searchText,
  chosenIdType,
  lastActiveProfile,
  lastUpdateTime,
  tags,
  connectionId,
  orderNumber,
  simulateOutage,
  accounts,
  eidFrom,
  eidTo,
  sorting,
}: UseRSimInventoryProps) => {
  const debouncedSearchText = useDebounce(searchText || '', 500);
  const minLengthText = debouncedSearchText.length < 3 ? '' : debouncedSearchText;

  const iccidToSearch = chosenIdType === 'ICCID' ? debouncedSearchText : '';
  const euiccToSearch = chosenIdType === 'EID' ? debouncedSearchText : '';

  const refetchSimInventory = useRefetchQuery('RSimSimProfiles');

  return useLazyPaginationQuery<RSimInventoryData>(
    [
      'RSimInventory',
      minLengthText,
      chosenIdType,
      iccidToSearch,
      euiccToSearch,
      lastActiveProfile,
      lastUpdateTime,
      tags,
      connectionId,
      orderNumber,
      simulateOutage,
      accounts,
      sorting,
      eidFrom,
      eidTo,
    ],
    async ({ signal, page, rowsPerPage }) => {
      const result = await setMinimalPromiseTime(
        fetchRSimInventoryExtendedFilters(
          page,
          rowsPerPage,
          {
            searchIds,
            chosenIdType,
            connectionId,
            orderNumber,
            simulateOutage,
            lastActiveProfile,
            tags,
            accounts,
            lastUpdateTime,
            eidFrom,
            eidTo,
          },
          sorting,
        ),
      );
      refetchSimInventory();
      return result;
    },
    ({ signal }) =>
      fetchRSimInventoryExtendedFiltersPagination(
        {
          searchIds,
          chosenIdType,
          connectionId,
          orderNumber,
          simulateOutage,
          lastActiveProfile,
          tags,
          accounts,
          lastUpdateTime,
          eidFrom,
          eidTo,
        },
        sorting,
      ),
    {
      refetchOnWindowFocus: false,
    },
  );
};
