import React from 'react';
import Typography from '@mui/material/Typography';
import { StickyCopyExtension } from 'common/StickyCopyExtension/StickyCopyExtension';
import { SxProps, Theme } from '@mui/material';

type TextCellProps = {
  text: string;
  isChild: boolean;
  sx?: SxProps<Theme>;
};

export const TextCell: React.FC<TextCellProps> = React.memo(({ text, isChild, sx }) => {
  // @ts-ignore

  return (
    <StickyCopyExtension value={text}>
      <Typography sx={sx} variant={isChild ? 'tableCellSecondary' : 'tableCellPrimary'}>
        {text}
      </Typography>
    </StickyCopyExtension>
  );
});
