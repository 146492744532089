import { useQuery } from 'react-query';
import { useState } from 'react';
import {
  fetchAuditTrailByIccid,
  fetchFieldsAuditTrail,
  fetchUsersAuditTrail,
} from '../auditTrailApi/auditTrailApi';
import { AuditTrail, AuditTrailPagination } from '../AuditTrail.models';
import { setMinimalPromiseTime } from 'common/utils/setMinimalPromiseTime';

export const useAuditTrail = (
  iccid: string,
  userName?: string,
  dateFrom?: string | null,
  dateTo?: string | null,
  fields?: string[],
): { data: AuditTrail[]; pagination: AuditTrailPagination } => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);

  const { data } = useQuery(
    ['AuditTrail', page, perPage, userName, dateFrom, dateTo, fields],
    async () => {
      return fetchAuditTrailByIccid(iccid, page, perPage, userName, dateFrom, dateTo, fields);
    },
  );

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onRowsPerPageChange = (perPage: number) => {
    setPerPage(perPage);
  };

  return {
    data: data.items,
    pagination: {
      page,
      onPageChange,
      perPage,
      onRowsPerPageChange,
      totalNumberOfItems: data.totalCount,
    },
  };
};

export const useUsersAuditTrail = (userName?: string, iccid?: string) => {
  const { data: users } = useQuery(
    ['UsersAuditTrail', userName, iccid],
    () => setMinimalPromiseTime(fetchUsersAuditTrail(userName, iccid)),
    {
      suspense: false,
    },
  );

  return { users };
};

export const useFieldsAuditTrail = () => {
  const { data: fields } = useQuery(['FieldsAuditTrail'], () => fetchFieldsAuditTrail(), {
    suspense: false,
  });

  return { fields };
};
