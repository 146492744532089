import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { fetchBulkProvisioningJob } from '../BulkProvisioningApi/bulkProvisioningApi';
import { Counter } from 'common/components/Counter/Counter';
import { BulkProvisioningStatusChip } from './BulkProvisioningStatusChip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Routes } from '../../Routes.constants';
import { useTranslation } from 'react-i18next';
import {
  noOfCardsTestId,
  fileNameTestId,
  successTestId,
  failTestId,
  uploadDateTestId,
  processedDateTestId,
  statusTestId
} from '../../../src/bulkProvisioning/BulkProvisioningDataTestIds';
import { ScrollArea } from 'common/components/ScrollArea';
import { dialogPaper } from 'theme/utility/components.styles';
import { usePreviousPath } from 'common/components/AppLayout/AppNavigation/usePreviousPath';

type BulkProvisioningJobRoutingParams = {
  id: string;
};

export const BulkProvisioningJobDetailsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<BulkProvisioningJobRoutingParams>();
  const { data: bulkProvisioningJob } = useQuery(
    ['BulkProvisioningJob', id],
    ({ signal }) => fetchBulkProvisioningJob(id!, signal),
    { enabled: !!id }
  );

  const {
    fileName,
    status,
    numberOfCards,
    numberOfSuccessCards,
    numberOfFailedCards,
    uploadAt,
    uploadFinishedAt
  } = bulkProvisioningJob || {};

  const prevPath = usePreviousPath() || Routes.provisioningRequest;
  return (
    <Dialog open={!!id} maxWidth="sm" fullWidth PaperProps={{ sx: dialogPaper }}>
      <DialogTitle>
        {bulkProvisioningJob?.fileName}
        <IconButton
          aria-label={t('common.close')}
          component={Link}
          to={Routes.provisioningRequest}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ScrollArea>
          <Grid container rowSpacing={4}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant="light">{t('common.fileName')}:</Typography>
                </Grid>
                <Grid data-testid={fileNameTestId}>{fileName}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant="light">{t('common.status')}:</Typography>
                </Grid>
                <Grid data-testid={statusTestId}>
                  <BulkProvisioningStatusChip status={status} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant="light">{t('common.numberOfCards')}:</Typography>
                </Grid>
                <Grid data-testid={noOfCardsTestId}>{numberOfCards}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant="light">{t('common.success')}:</Typography>
                </Grid>
                <Grid data-testid={successTestId}>
                  <Counter count={numberOfSuccessCards ?? 0} variant="counterSuccess" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant="light">{t('common.fail')}:</Typography>
                </Grid>
                <Grid data-testid={failTestId}>
                  <Counter count={numberOfFailedCards ?? 0} variant="counterError" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant="light">{t('common.uploadDate')}</Typography>
                </Grid>
                <Grid data-testid={uploadDateTestId}>{uploadAt?.toLocaleDateString()}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant="light">{t('bulkProvisioning.processedDate')}:</Typography>
                </Grid>
                <Grid data-testid={processedDateTestId}>
                  {uploadFinishedAt?.toLocaleDateString()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ScrollArea>
      </DialogContent>
      <DialogActions>
        <Button component={Link} to={prevPath}>
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
