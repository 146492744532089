import { Components } from '@mui/material';
import { greyColor, typoColor } from 'theme/palette';

import inputCloseImage from 'assets/images/inputClose.svg';

export const MuiTextField: Components['MuiTextField'] = {
  styleOverrides: {
    root: () => {
      return {
        '& fieldset': {
          borderRadius: '12px',
        },
        '& .MuiInputBase-adornedStart': {
          padding: 0,
        },
        '& label.Mui-focused': {
          color: greyColor[600],
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: typoColor.tertiary,
          },

          '&:hover fieldset': {
            borderColor: typoColor.secondary,
          },
          '&.Mui-focused fieldset': {
            borderColor: greyColor[600],
          },
        },
        '& .MuiInputBase-input::-webkit-search-cancel-button': {
          '-webkit-appearance': 'none',
          height: '12px',
          width: '12px',
          backgroundImage: `url(${inputCloseImage})`,
          m: 0,
        },
      };
    },
  },
};
