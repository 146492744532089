import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { InputWrapperProps } from '../input.interface';
import { InputWrapper } from '../InputWrapper/InputWrapper';
import { TextFieldFieldsetSx } from '../TextField/TextField.style';

type TextFieldProps = MuiTextFieldProps & Pick<InputWrapperProps, 'label' | 'fullWidth'>;

export const TextField: React.FC<TextFieldProps> = ({
  label,
  fullWidth,
  onChange,
  value,
  sx,
  type = 'search',
  ...props
}) => (
  <InputWrapper label={label} fullWidth={fullWidth}>
    <MuiTextField
      value={value}
      onChange={onChange}
      {...props}
      sx={{ ...TextFieldFieldsetSx, ...sx }}
      type={type}
    />
  </InputWrapper>
);
