import Tab from '@mui/material/Tab';
import { tabStyle } from 'common/components/AppLayout/AppLayout.styles';
import { usePreviousPath } from 'common/components/AppLayout/AppNavigation/usePreviousPath';
import { TabAppLayout } from 'common/components/AppLayout/TabAppLayout';

import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';
import { Link, useParams } from 'react-router-dom';
import { Routes } from 'Routes.constants';
import { FEATURE_FLAGS_MODULES } from '../../featureFlags/FeatureFlags.models';

import { Actions, Subjects } from 'permissions/ability';
import { useAbility } from 'permissions/hooks/useAbility';

export const SimInventoryTabsLayout = () => {
  const { iccid } = useParams();
  const previousPath = usePreviousPath();
  const sessionHistoryEnabled = useHasFeatureFlag('SimCardSessionsHistory');

  const ability = useAbility();

  const auditTrailEnabled =
    useHasFeatureFlag('AuditTrailsApiEndpoints', FEATURE_FLAGS_MODULES.AUDIT_TRAIL) &&
    ability.can(Actions.read, Subjects.auditTrails);
  return (
    <TabAppLayout>
      <Tab
        value={`${Routes.simInventory}/${iccid}`}
        label="General"
        component={Link}
        to={`${Routes.simInventory}/${iccid}`}
        state={{ previousPath }}
        sx={tabStyle}
        replace
      />

      {sessionHistoryEnabled ? (
        <Tab
          value={`${Routes.simInventory}/${iccid}/history`}
          label="History"
          component={Link}
          to={`${Routes.simInventory}/${iccid}/history`}
          state={{ previousPath }}
          sx={tabStyle}
          replace
        />
      ) : null}
      {sessionHistoryEnabled ? (
        <Tab
          value={`${Routes.simInventory}/${iccid}/history-analysis`}
          label="History Analysis"
          component={Link}
          to={`${Routes.simInventory}/${iccid}/history-analysis`}
          state={{ previousPath }}
          sx={tabStyle}
          replace
        />
      ) : null}
      {auditTrailEnabled ? (
        <Tab
          value={`${Routes.simInventory}/${iccid}/audit-trail`}
          label="Audit Trail"
          component={Link}
          to={`${Routes.simInventory}/${iccid}/audit-trail`}
          state={{ previousPath }}
          sx={tabStyle}
          replace
        />
      ) : null}
    </TabAppLayout>
  );
};
