import { useGraphSessionRange } from '../hooks/useGraphSessionRange';
import { subHours } from 'date-fns';
import { IconTextButton } from 'common/components/Buttons/IconTextButton';
import { ReactComponent as FastForwardIcon } from 'assets/images/fastforward.svg';
export const LastHourButton = () => {
  const { selectRange } = useGraphSessionRange();

  return (
    <IconTextButton
      variant="secondary"
      onClick={() => {
        selectRange(subHours(new Date(), 1), new Date());
      }}
      iconEnd={<FastForwardIcon />}
      sx={{ paddingTop: '7px', paddingBottom: '7px' }}
    >
      Last hour
    </IconTextButton>
  );
};
