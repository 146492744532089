import { QueryKey, useQueryClient } from 'react-query';

export const useRefetchQuery = (queryKey: QueryKey) => {
  const queryClient = useQueryClient();
  const invalidateQuery = async () => {
    await queryClient.invalidateQueries({
      queryKey
    });
  };

  return invalidateQuery;
};
