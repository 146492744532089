import React from 'react';
import Box from '@mui/material/Box';
import { AdditionalMenu } from '../../../../common/components/AdditionalMenu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { SimProfile } from '../../../SimInventoryApi/simInventoryApi.interface';
import { GoToDetailsMenuItem } from './GoToDetailsMenuItem';
import { useAbility } from 'permissions/hooks/useAbility';
import { Actions, Subjects } from 'permissions/ability';

type RowSettingsProps = {
  row: SimProfile;
  setEditedSim: (iccid: string) => void;
};

export const RowSettings: React.FC<RowSettingsProps> = ({ row, setEditedSim }) => {
  const ability = useAbility();
  const editingSimAvailable =
    ability.can(Actions.edit, Subjects.tags) || ability.can(Actions.edit, Subjects.customFields);
  return (
    <Box>
      <AdditionalMenu
        children={({ onClose }) => (
          <MenuList sx={{ p: 0 }}>
            <GoToDetailsMenuItem onClose={onClose} iccid={row.iccid?.toString()} />
            {editingSimAvailable && (
              <MenuItem
                onClick={() => {
                  setEditedSim(row.iccid);
                  onClose();
                }}
              >
                <ListItemText>Edit</ListItemText>
              </MenuItem>
            )}
          </MenuList>
        )}
      ></AdditionalMenu>
    </Box>
  );
};
