import { useQuery, UseQueryResult } from 'react-query';
import { usePageReset } from './usePageReset';
import { usePaginationWithUrlParams } from './usePaginationWithUrlParams';

interface IPagination {
  page: number;
  rowsPerPage: number;
  setPage: (page: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
}

type PaginatedQueryContext = {
  signal?: AbortSignal;
  page: number;
  rowsPerPage: number;
};

type PaginatedFunction = (paginatedQueryContext: PaginatedQueryContext) => void;

export const usePaginatedQuery = <TQueryResults = unknown, TQueryKeys = unknown>(
  queryKey: TQueryKeys[],
  queryFn: PaginatedFunction
): {
  pagination: IPagination;
  queryResult: UseQueryResult<TQueryResults>;
} => {
  const { page, setPage, rowsPerPage, setRowsPerPage } = usePaginationWithUrlParams();

  const queryResult = useQuery({
    queryKey: [...queryKey, page, rowsPerPage],
    queryFn: ({ signal }) => queryFn({ page, rowsPerPage, signal }),
    keepPreviousData: true
  });

  usePageReset([...queryKey, rowsPerPage]);

  return {
    pagination: {
      page,
      rowsPerPage,
      setPage,
      setRowsPerPage
    },
    queryResult: queryResult as UseQueryResult<TQueryResults>
  };
};
