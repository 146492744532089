import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ErrorCodes } from 'common/errorCodes';
import React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { centerContentHorizontalAndVertical, fillParentHeight } from 'theme/utility/utility.styles';
import { ReactComponent as CommonErrorImage } from 'assets/images/commonErrorBG.svg';
import { useQueryClient } from 'react-query';
import { IMSI_NOT_VALID_ERROR } from 'rSimInventory/Components/RSimSessionHistory/erros/ImsiNotValid';

const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation();

  let message = '';
  if (error.name === IMSI_NOT_VALID_ERROR) {
    message = t('rSimInventory.imsiNotValid');
  }

  return (
    <Box sx={{ ...centerContentHorizontalAndVertical, ...fillParentHeight }}>
      <CommonErrorImage />
      <Typography variant="h1">{t('auth.somethingWrong')}</Typography>
      {message && (
        <Typography variant="text5Secondary" mb={5}>
          {message}
        </Typography>
      )}
      <Button onClick={resetErrorBoundary}>{t('auth.tryAgain')}</Button>
    </Box>
  );
};

export const SessionHistoryErrorBoundary: React.FC<React.PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient();

  return (
    <ErrorBoundary
      onReset={() => {
        queryClient.refetchQueries({ queryKey: ['SessionHistory'] });
      }}
      FallbackComponent={ErrorFallback}
      onError={(error) => {
        if (error.message === ErrorCodes.AccessDenied) {
          throw error;
        }
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
