import { DialogProps } from 'common/components/Dialog/Dialog';
import { EditDialog as BaseEditDialog } from 'common/components/Dialog/EditDialog';
import { useEditableContext } from './EditableProvider';
export type ChildrenFunctionArgs = {
  notifyAboutChanges: () => void;
};

export type EditDialogProps = {
  children: React.ReactNode;
  onApplyChangesClick: () => Promise<void>;
} & Pick<DialogProps, 'subtitle'>;

export const EditDialog = ({ children, subtitle, onApplyChangesClick }: EditDialogProps) => {
  const { editing, disableEditnig, notifyAboutClearingChanges, changed } = useEditableContext();

  const applyChanges = async () => {
    await onApplyChangesClick();
    disableEditnig();
  };
  return (
    <BaseEditDialog
      subtitle={subtitle}
      open={editing}
      onApplyChangesClick={() => applyChanges()}
      onCancelClick={() => disableEditnig()}
      onClearChangesClick={() => notifyAboutClearingChanges()}
      unsavedChanges={changed}
    >
      {children}
    </BaseEditDialog>
  );
};
