import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ContentCopyExtension } from 'common/ContentCopyExtension/ContentCopyExtension';
import React from 'react';

export type InterimCellProps = {
  text: string;
  sx?: SxProps;
};

export const InterimCell: React.FC<InterimCellProps> = ({ text, sx }) => {
  return (
    <Box
      sx={{
        whiteSpace: 'nowrap',
        lineHeight: '21px',
        py: 3.5,
        ...sx
      }}
    >
      <ContentCopyExtension value={text}>
        <Typography variant={'text1Primary'}>{text}</Typography>
      </ContentCopyExtension>
    </Box>
  );
};
