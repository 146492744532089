import React from 'react';
import { darkTooltipSx, tooltipSx } from 'common/components/Tooltip/Tooltip.style';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

type IconCellProps = {
  title: string | null;
  children: React.ReactElement;
  disabled?: boolean;
  disabledLabel?: string;
};

export const TooltipCell: React.FC<IconCellProps> = ({
  title,
  children,
  disabled = false,
  disabledLabel,
}) => {
  const { t } = useTranslation();
  const tooltipStyle = { ...tooltipSx, ...darkTooltipSx };

  return (
    <>
      {!!title || disabled ? (
        <Tooltip
          showArrow={true}
          textVariant={'text3white'}
          title={
            disabled ? disabledLabel ?? t('customFields.unavailableWhileEditing') : title ?? ''
          }
          sx={tooltipStyle}
        >
          {children}
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
