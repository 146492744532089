import { palette } from '../../../theme/palette';

export const menuWidth = '180px';
export const menuWidthSmall = '48px';
export const contentPaddingTop = '12px';
export const mainPaddingBottom = '20px';
export const tabHeight = '32px';
export const paperPaddingTop = '16px';

export const tabStyle = {
  '&.MuiTab-root': {
    borderRadius: '12px 12px 0px 0px',
    padding: '6px 24px',
    minHeight: '32px',
    height: '32px',
  },
};

export const paperStyle = {
  position: 'relative',
  scrollbarWidth: 'none',
  borderRadius: '8px',
  borderTopLeftRadius: '0px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  flex: '1',
  mt: '-1px',
  overflow: 'hidden',
};

export const borderStyle = {
  borderRightWidth: '1px',
  borderColor: palette.grey['300'],
  borderWidth: '1px',
  borderRadius: '8px',
  borderTopLeftRadius: '0px',
  borderStyle: 'solid',
};
