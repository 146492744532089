import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import InventoryIcon from '@mui/icons-material/Inventory';
import { ReactComponent as BPUploadIcon } from 'assets/images/BPUploadIcon.svg';
import { ReactComponent as CSLLogo } from 'assets/images/CSLlogo.svg';
import LogoutIcon from '@mui/icons-material/Logout';
import AppBar from '@mui/material/AppBar';
import { logoutRedirect } from 'auth/AuthApi/authApi';
import { useAuth } from 'auth/AuthProvider';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';
import { appBarStyle, toolbarStyle } from './AppNavigation.styles';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Routes } from '../../../../Routes.constants';
import { ReactComponent as ArrowBack } from 'assets/images/arrowBack.svg';
import { typoColor } from '../../../../theme/palette';
import { menuWidth, menuWidthSmall } from '../AppLayout.styles';
import { usePreviousPath } from './usePreviousPath';
import { useParentPath } from './useParentPath';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import { useHasFeatureFlag } from '../../../../featureFlags/useHasFeatureFlag';

import { useState } from 'react';
import { SimInventoryCan } from 'permissions/PermissionProvider';
import { Actions, Subjects } from 'permissions/ability';

type RoutingParams = {
  iccid?: string;
  euicc?: string;
};

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  overflowX: 'hidden',
  transition: 'width 0.2s ease-in-out',
  '& .MuiListItemText-root': {
    transition: 'opacity 0.2s ease-in-out',
    opacity: '0%',
  },
  width: menuWidthSmall,
  '& .MuiDrawer-paper': {
    zIndex: '1200',
    transition: 'width 0.2s ease-in-out',
    width: menuWidthSmall,
  },
  ...(open && {
    '@media (hover: hover)': {
      '& .MuiListItemText-root': {
        opacity: '100%',
      },
      width: menuWidth,
      '& .MuiDrawer-paper': {
        width: menuWidth,
      },
    },
  }),
  a: {
    color: 'inherit',
  },
}));

const ListItemButton = styled(MuiListItemButton)(({ selected, theme }) => {
  return {
    borderRight: selected ? '4px solid ' : '',
    marginLeft: theme.spacing(1),
    borderTopLeftRadius: '12px',
    borderBottomLeftRadius: '12px',
    paddingLeft: theme.spacing(2),
    borderColor: theme.palette.grey[500],
    maxHeight: '40px',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  };
});

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => {
  return {
    minWidth: 0,
    marginRight: theme.spacing(2),
    color: theme.palette.text.secondary,
  };
});

const SimInventoryDetailsTitle = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { iccid } = useParams<RoutingParams>();
  const previousPath = usePreviousPath();
  const parentPath = useParentPath();

  const goBack = () => {
    navigate(parentPath, { replace: true });
  };

  return (
    <Grid container>
      <Grid item>
        {!previousPath && (
          <IconButton onClick={goBack} data-testid="backButton">
            <ArrowBack />
          </IconButton>
        )}
        {previousPath && (
          <IconButton data-testid="backButton" onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
        )}
      </Grid>
      <Grid item sx={{ mt: '9px' }}>
        <Box>{`${t('simInventory.sim')} ${iccid}`}</Box>
        <Breadcrumbs
          separator="›"
          aria-label="breadcrumb"
          sx={{
            mt: '-10px',
            '& .MuiBreadcrumbs-separator': {
              margin: '4px',
            },
          }}
        >
          <Typography variant="tableCellSecondary">
            <Link
              to={Routes.simInventory}
              style={{ color: typoColor.secondary, textDecoration: 'none' }}
            >
              {t('simInventory.simInventory')}
            </Link>
          </Typography>
          ,<Typography variant="tableCellSecondary">{t('simInventory.SIMDetails')}</Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

const RSimInventoryDetailsTitle = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { euicc } = useParams<RoutingParams>();
  const previousPath = usePreviousPath();
  const parentPath = useParentPath();

  const goBack = () => {
    navigate(parentPath, { replace: true });
  };

  return (
    <Grid container>
      <Grid item>
        {!previousPath && (
          <IconButton onClick={goBack} data-testid="backButton">
            <ArrowBack />
          </IconButton>
        )}
        {previousPath && (
          <IconButton data-testid="backButton" onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
        )}
      </Grid>
      <Grid item sx={{ mt: '9px' }}>
        <Box>{`${t('rSimInventory.title')} ${euicc}`}</Box>
        <Breadcrumbs
          separator="›"
          aria-label="breadcrumb"
          sx={{
            mt: '-10px',
            '& .MuiBreadcrumbs-separator': {
              margin: '4px',
            },
          }}
        >
          <Typography variant="tableCellSecondary">
            <Link
              to={Routes.rSimInventory}
              style={{ color: typoColor.secondary, textDecoration: 'none' }}
            >
              {t('rSimInventory.rSimInventory')}
            </Link>
          </Typography>
          ,<Typography variant="tableCellSecondary">{t('rSimInventory.rSIMDetails')}</Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

export const AppNavigation = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { user } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const customFieldsEnabled = useHasFeatureFlag('CustomFields');
  const previousPath = usePreviousPath();

  const isCustomSimInventoryRedirect =
    previousPath?.localeCompare(Routes.rSimInventory) === 0 ||
    previousPath?.localeCompare(Routes.provisioningRequest) === 0;

  const { iccid, euicc } = useParams<RoutingParams>();

  const getTitle = () => {
    if (location.pathname.includes(Routes.simInventory) && iccid) {
      return <SimInventoryDetailsTitle />;
    }

    if (location.pathname.includes(Routes.rSimInventory) && euicc) {
      return <RSimInventoryDetailsTitle />;
    }

    if (location.pathname.includes(Routes.admin)) {
      return t('customFields.admin');
    }

    if (location.pathname.includes(Routes.provisioningRequest)) {
      return t('bulkProvisioning.title');
    }

    return t('common.inventory');
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <Box
      sx={{
        marginBottom: 3,
        px: 0,
        ml: menuWidthSmall,
      }}
    >
      <AppBar elevation={0} color="transparent" sx={appBarStyle} position="static">
        <Toolbar sx={toolbarStyle}>
          <Typography variant="h2" component="div" sx={{ flexGrow: 1 }}>
            {getTitle()}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton aria-label={t('common.logout')} onClick={logoutRedirect}>
              <LogoutIcon />
            </IconButton>
            <Typography variant="userInfo">{`${user.givenName} ${user.surname}`}</Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        elevation={24}
        PaperProps={{ elevation: 8, sx: { borderRight: 0, overflowX: 'hidden' } }}
        open={isDrawerOpen}
        onMouseOver={handleDrawerOpen}
        onMouseOut={handleDrawerClose}
      >
        <Box sx={{ mb: 8, ml: 2.5, mt: 3 }}>
          <CSLLogo width={'28px'} height={'26px'} />
        </Box>
        <List sx={{ mt: '77px' }}>
          <ListItem
            disablePadding
            data-testid="sim-inventory-link"
            component={Link}
            to={isCustomSimInventoryRedirect ? (previousPath as string) : Routes.simInventory}
          >
            <ListItemButton
              selected={
                location.pathname.includes(Routes.simInventory) ||
                location.pathname.includes(Routes.rSimInventory)
              }
            >
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                sx={{ typography: 'h4' }}
                primary={t('common.inventory')}
              />
            </ListItemButton>
          </ListItem>
          {/*Menu item is hidden until separation of pages (SIMs and Bulk Provisioning) will be done*/}
          <SimInventoryCan I={Actions.read} a={Subjects.bulkProvisioning}>
            <ListItem
              disablePadding
              component={Link}
              data-testid="provisioning-link"
              to={Routes.provisioningRequest}
              sx={{ mt: '2px' }}
            >
              <ListItemButton selected={location.pathname.includes(Routes.provisioningRequest)}>
                <ListItemIcon>
                  <BPUploadIcon width={'24px'} height={'24px'} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  sx={{ typography: 'h4' }}
                  primary={t('bulkProvisioning.title')}
                />
              </ListItemButton>
            </ListItem>
          </SimInventoryCan>
          {customFieldsEnabled && (
            <SimInventoryCan I={Actions.edit} a={Subjects.customFields}>
              <ListItem
                disablePadding
                sx={{ mt: '2px' }}
                component={Link}
                data-testid="custom-fields-link"
                to={`${Routes.admin}/custom-fields`}
                state={{ previousPath: location.pathname }}
              >
                <ListItemButton selected={location.pathname.includes(Routes.admin)}>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    sx={{ typography: 'h4' }}
                    primary={t('common.admin')}
                  />
                </ListItemButton>
              </ListItem>
            </SimInventoryCan>
          )}
        </List>
      </Drawer>
    </Box>
  );
};
