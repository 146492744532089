import React from 'react';

import { TwoValueCellPropsWithoutVariants } from './TwoValueCell.interface';
import { TwoValueCell } from './TwoValueCell';

const sx = {
  '& .MuiTypography-tableCellPrimary': {
    lineHeight: '17px',
  },
  '& .MuiTypography-text1Secondary': {
    lineHeight: '17px',
  },
};

export const DateRangeTableCell: React.FC<TwoValueCellPropsWithoutVariants> = (props) => {
  return (
    <TwoValueCell { ...props } sx={ sx } primaryVariant="tableCellPrimary" secondaryVariant="text1Secondary"/>
  );
};
