import TablePagination from '@mui/material/TablePagination';
import {
  useGridApiContext,
  useGridSelector,
  gridPaginationModelSelector,
  gridPaginationRowCountSelector,
} from '@mui/x-data-grid-pro';

export const Pagination = () => {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPaginationRowCountSelector);
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector);

  return (
    <TablePagination
      color="primary"
      showLastButton={false}
      count={pageCount}
      page={paginationModel.page}
      rowsPerPageOptions={[25, 50, 100]}
      onPageChange={(event, newPage) => {
        apiRef.current.setPage(newPage);
      }}
      rowsPerPage={paginationModel.pageSize}
      onRowsPerPageChange={(event) => {
        apiRef.current.setPageSize(parseInt(event.target.value));
      }}
    />
  );
};

// export const Pagination = (props: any) => {
//   return <GridPagination ActionsComponent={CustomPagination} {...props} />;
// };
