const genreteTableCellPrefferedWidthSx = (width: string) => {
  return {
    minWidth: width,
    width,
  };
};
export const oldSimInventoryColumnsStyles: Record<string, any> = {
  iccid: genreteTableCellPrefferedWidthSx('240px'),
  'currentIpAddress&managementIpAddress': genreteTableCellPrefferedWidthSx('160px'),
  'commsPlan&mobileNetworkOperator': genreteTableCellPrefferedWidthSx('160px'),
  subscriptionStatus: genreteTableCellPrefferedWidthSx('170px'),
  msisdn: genreteTableCellPrefferedWidthSx('180px'),
  sessionStatus: genreteTableCellPrefferedWidthSx('150px'),
  'sessionStart&sessionEnd': genreteTableCellPrefferedWidthSx('200px'),
  liveDataUsage: genreteTableCellPrefferedWidthSx('120px'),
  textUsage: genreteTableCellPrefferedWidthSx('120px'),
  minuteUsage: genreteTableCellPrefferedWidthSx('120px'),
};

export const simInventoryColumnsStyles: Record<string, any> = {
  iccid: genreteTableCellPrefferedWidthSx('240px'),
  imsi: genreteTableCellPrefferedWidthSx('200px'),
  currentIpAddress: genreteTableCellPrefferedWidthSx('160px'),
  managementIpAddress: genreteTableCellPrefferedWidthSx('160px'),
  customerCommsPlan: genreteTableCellPrefferedWidthSx('160px'),
  subscriptionStatus: genreteTableCellPrefferedWidthSx('170px'),
  subscriptionStatusEntryTime: genreteTableCellPrefferedWidthSx('170px'),
  commsPlan: genreteTableCellPrefferedWidthSx('160px'),
  mobileNetworkOperator: genreteTableCellPrefferedWidthSx('160px'),
  organisation: genreteTableCellPrefferedWidthSx('160px'),
  accountName: genreteTableCellPrefferedWidthSx('160px'),
  accountRef: genreteTableCellPrefferedWidthSx('160px'),
  msisdn: genreteTableCellPrefferedWidthSx('180px'),
  liveDataUsage: genreteTableCellPrefferedWidthSx('120px'),
  inMinuteUsage: genreteTableCellPrefferedWidthSx('120px'),
  inTextUsage: genreteTableCellPrefferedWidthSx('150px'),
  sessionStatus: genreteTableCellPrefferedWidthSx('150px'),
  sessionStatusLastUpdated: genreteTableCellPrefferedWidthSx('150px'),
  sessionStart: genreteTableCellPrefferedWidthSx('200px'),
  sessionEnd: genreteTableCellPrefferedWidthSx('200px'),
  connectionId: genreteTableCellPrefferedWidthSx('120px'),
  orderNumber: genreteTableCellPrefferedWidthSx('120px'),
  tags: genreteTableCellPrefferedWidthSx('150px'),
  label: genreteTableCellPrefferedWidthSx('160px'),
};

export const simInventoryColumnsWidth: Record<string, number> = {
  iccid: 200,
  imsi: 200,
  currentIpAddress: 160,
  managementIpAddress: 160,
  customerCommsPlan: 160,
  subscriptionStatus: 170,
  subscriptionStatusEntryTime: 170,
  commsPlan: 160,
  mobileNetworkOperator: 160,
  organisation: 160,
  accountName: 160,
  accountRef: 160,
  msisdn: 180,
  liveDataUsage: 120,
  minuteUsage: 120,
  textUsage: 120,
  sessionStatus: 150,
  sessionStatusLastUpdated: 150,
  sessionStart: 200,
  sessionEnd: 200,
  connectionId: 120,
  orderNumber: 120,
  tags: 150,
  label: 160,
};
