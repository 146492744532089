import { useQuery } from 'react-query';
import { getInterimsTimeline } from '../services/SessionHistoryApi';
import { useEffect, useMemo, useState } from 'react';
import { fetchInterimsWithCache } from '../services/InterimsApi';

import { Interims } from '../utils/interims';

const ENTRY_PER_PAGE = 50;

export const useInterimsLoaderByDate = (
  imsi?: number,
  sessionId?: string,
  sessionStart?: Date,
  sessionEnd?: Date,
  toDate?: Date,
) => {
  const {
    data: interims,
    isFetched,
    isFetching,
  } = useQuery(
    ['interims', imsi, toDate?.getTime(), sessionId, sessionEnd?.getTime()],
    async ({ signal }) => {
      const interims = await fetchInterimsWithCache(
        imsi!.toString(),
        sessionId!,
        toDate!,
        sessionEnd || new Date(),
        ENTRY_PER_PAGE,
        signal,
      );
      return interims;
    },
    {
      enabled: !!imsi && !!sessionId && !!toDate,
      suspense: false,
      onError: (err) => {
        console.log(err);
      },
    },
  );

  const [bufferedInterims, setBufferedInterims] = useState<Interims>([]);

  useEffect(() => {
    if (isFetched) {
      setBufferedInterims(interims || []);
    }
  }, [interims, isFetched]);

  const timeline = useMemo(() => {
    return sessionStart ? getInterimsTimeline(interims || [], sessionStart) : [];
  }, [bufferedInterims, sessionStart]);

  return { timeline, interims, isFetching: isFetching };
};
