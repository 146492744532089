import { alpha, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import MUIButtonBase, { ButtonBaseProps as MUIButtonBaseProps } from '@mui/material/ButtonBase';

import { DEFAULT_BORDER_RADIUS } from 'theme/constants';
import { borderColor, typoColor } from '../../../theme/palette';

const buttonBase: SxProps = {
  borderRadius: DEFAULT_BORDER_RADIUS,
  px: 4,
  py: 2.5,
  typography: 'h4',
};

const buttonPrimarySX: SxProps<Theme> = {
  ...buttonBase,
  backgroundColor: ({ palette }) => palette.grey[600],
  color: ({ palette }) => palette.white,
  '&:hover, &:active': {
    backgroundColor: ({ palette }) => palette.grey[500],
    color: ({ palette }) => palette.white,
  },
  '&:disabled': {
    color: typoColor.tertiary,
    backgroundColor: borderColor,
  },
};

const buttonSecondarySX: SxProps<Theme> = {
  ...buttonBase,
  backgroundColor: ({ palette }) => palette.white,
  color: ({ palette }) => palette.text.secondary,
  boxShadow: '4',
  '&:hover': {
    boxShadow: 0,
    backgroundColor: ({ palette }) => palette.grey[200],
    color: ({ palette }) => palette.text.primary,
  },
  '&:active': {
    boxShadow: 0,
    backgroundColor: ({ palette }) => palette.grey[300],
    color: ({ palette }) => palette.text.secondary,
  },
};

const buttonSecondaryActiveSX: SxProps<Theme> = {
  ...buttonBase,
  backgroundColor: ({ palette }) => palette.grey[200],
  color: ({ palette }) => palette.text.secondary,
};

const buttonTetriarySX: SxProps<Theme> = {
  ...buttonBase,
  backgroundColor: ({ palette }) => alpha(palette.white, 0),
  color: ({ palette }) => palette.text.secondary,
  '&:hover, &:active': {
    boxShadow: 0,
    backgroundColor: ({ palette }) => palette.grey[200],
  },
};

export const buttonStyles = {
  primary: buttonPrimarySX,
  secondary: buttonSecondarySX,
  secondaryActive: buttonSecondaryActiveSX,
  tetriary: buttonTetriarySX,
};

export type ButtonProps = {
  variant: keyof typeof buttonStyles;
  children: string;
  onClick?: MUIButtonBaseProps['onClick'];
};

export const Button: React.FC<ButtonProps> = ({ variant, children, ...otherProps }) => {
  const buttonSx = buttonStyles[variant];
  return (
    <MUIButtonBase sx={buttonSx} disableTouchRipple disableRipple {...otherProps}>
      {children}
    </MUIButtonBase>
  );
};
