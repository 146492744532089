import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Suspense, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useRSimSessionImsis } from './useRSimSessionHistory';

import { PollingChart } from './PollingChart';
import { SessionHistoryContext, SessionHistoryProvider } from './SessionHistoryProvider';
import { SessionChart } from './SessionChart';
import { UsageChart } from './UsageChart';
import { SessionTooltip } from './SessionTooltip';
import { ControlPanel } from 'common/components/Graphs/ControlPanel';
import Stack from '@mui/material/Stack';
import { ScrollArea } from 'common/components/ScrollArea';
import { SessionDetailsWithInterimsGraphs } from 'common/components/SessionHistory/components/SessionDetailsWithInterimsGraphs';
import { GraphSessionRangeProvider } from 'common/components/Graphs/Sessions/GraphSessionRangeProvider';

const ChartArea = () => {
  const { sessionToDisplay, sessionToDisplaySelection, primaryIccid, secondaryIccid } =
    useContext(SessionHistoryContext);

  let iccid = '-';
  let profile: string | null = '-';
  if (sessionToDisplay) {
    iccid = sessionToDisplaySelection.setName === 'Primary' ? primaryIccid : secondaryIccid;
    profile = sessionToDisplaySelection.setName;
  }

  return (
    <Stack spacing={2}>
      <Box>
        <PollingChart />
      </Box>
      <SessionChart />
      <UsageChart />
      <Box mt={2} pl={10}>
        <SessionDetailsWithInterimsGraphs
          session={sessionToDisplay}
          profile={profile}
          iccid={iccid}
        />
      </Box>
    </Stack>
  );
};

const RSimHistoryAnalysisContent = () => {
  const { euicc } = useParams();

  const imsis = useRSimSessionImsis(euicc!);

  return imsis && (imsis.primarySimProfile || imsis.secondarySimProfile) ? (
    <GraphSessionRangeProvider>
      <SessionHistoryProvider
        primaryImsi={imsis.primarySimProfile!}
        secondaryImsi={imsis.secondarySimProfile!}
        primaryIccid={imsis.primaryIccid}
        secondaryIccid={imsis.secondaryIccid}
      >
        <Box sx={{ position: 'relative', height: '100%', pt: 2 }}>
          <ScrollArea>
            <SessionTooltip containerWidth={100} />
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                position: 'relative'
              }}
              spacing={2}
            >
              <Box
                sx={{
                  px: 4,
                  pt: 2,
                  pb: 2,
                  borderBottom: (theme) => `1px solid ${theme.palette.tableBorder.main}`
                }}
              >
                <ControlPanel
                  sliderSx={{
                    pl: 16,
                    pr: 6
                  }}
                />
              </Box>
              <ChartArea />
            </Stack>
          </ScrollArea>
        </Box>
      </SessionHistoryProvider>
    </GraphSessionRangeProvider>
  ) : null;
};

export const RSimHistoryAnalysis = () => {
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%'
          }}
        >
          <CircularProgress />
        </Box>
      }
    >
      <RSimHistoryAnalysisContent />
    </Suspense>
  );
};
