import { ObjectValues } from 'common/tsHelpers';
import { SIM_CARD_IDENTIFIERS } from 'data/sim.constants';

export const FILTER_SIM_CARD_IDENTIFIERS = {
  EID: SIM_CARD_IDENTIFIERS.EID,
  ICCID: SIM_CARD_IDENTIFIERS.ICCID,
} as const;

export type FilterSimCardIdentifier = ObjectValues<typeof FILTER_SIM_CARD_IDENTIFIERS>;

export const LAST_ACTIVE_PROFILE_FILTER = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  ANY: 'ANY',
} as const;

export type LastActiveProfileFilter = ObjectValues<typeof LAST_ACTIVE_PROFILE_FILTER>;

export const LAST_UPDATE_TIME = {
  MINUTES_15: 'MINUTES_15',
  MINUTES_30: 'MINUTES_30',
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
} as const;

export type LastUpdateTime = ObjectValues<typeof LAST_UPDATE_TIME>;

export const LAST_UPDATE_TIME_FILTERS = {
  ...LAST_UPDATE_TIME,
  NONE: 'NONE',
} as const;

export type LastUpdateTimeFilters = ObjectValues<typeof LAST_UPDATE_TIME_FILTERS>;

export const SIMULATE_OUTAGE_FILTER = {
  ON: 'ON',
  OFF: 'OFF',
  ANY: 'ANY',
} as const;

export type SimulateOutageFilter = ObjectValues<typeof SIMULATE_OUTAGE_FILTER>;

export type RSimFilters = {
  chosenIdType: FilterSimCardIdentifier;
  searchText: string;
  lastActiveProfile: LastActiveProfileFilter;
  lastUpdateTime: LastUpdateTimeFilters;
  tags: string;
  connectionId: string;
  orderNumber: string;
  simulateOutage: SimulateOutageFilter;
  accounts: string;
  eidFrom: string;
  eidTo: string;
};

export type ValidRSimFiltersKeys = keyof RSimFilters;

export const RSIM_INITIAL_FILTERS: RSimFilters = {
  chosenIdType: FILTER_SIM_CARD_IDENTIFIERS.EID,
  searchText: '',
  lastActiveProfile: LAST_ACTIVE_PROFILE_FILTER.ANY,
  lastUpdateTime: LAST_UPDATE_TIME_FILTERS.NONE,
  tags: '',
  connectionId: '',
  orderNumber: '',
  simulateOutage: SIMULATE_OUTAGE_FILTER.ANY,
  accounts: '',
  eidFrom: '',
  eidTo: '',
};
