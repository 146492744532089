import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  fetchSimInventoryTableConfiguration,
  revertSimInventoryTableConfiguration,
} from 'simInventory/SimInventoryApi/simInventoryTableConfigurationApi';
import {
  TABLE_VARIANT,
  TableVariant,
} from 'simInventory/SimInventoryApi/simInventoryTableConfigurationApi.interface';
import { saveSimInventoryTableConfiguration } from '../../../SimInventoryApi/simInventoryTableConfigurationApi';

const SimInventoryConfigurableKey = ['SimInventoryConfigurable'];

export const useColumnConfiguration = () => {
  const {
    data: configuration,
    refetch,
    status,
    isRefetching,
  } = useQuery(SimInventoryConfigurableKey, async () => fetchSimInventoryTableConfiguration(true), { cacheTime: 0 });

  const queryClient = useQueryClient();
  const { mutate: updateVariant, status: updatingStatus } = useMutation(
    async (variant: TableVariant) => {
      return saveSimInventoryTableConfiguration({
        variant,
        columnConfigurations:
          variant === TABLE_VARIANT.Flat
            ? configuration!.flatColumnConfigurations
            : configuration!.combinedColumnConfigurations,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: SimInventoryConfigurableKey });
      },
    },
  );

  const { mutate: revertToDefault, status: revertUpdatingStatus } = useMutation(
    () => {
      if (configuration?.enabledVariant === undefined) {
        throw new Error('need to fetch configuration first!');
      }

      return revertSimInventoryTableConfiguration(configuration.enabledVariant);
    },
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: SimInventoryConfigurableKey }),
    },
  );

  const currentColumns =
    configuration?.enabledVariant === TABLE_VARIANT.Flat
      ? configuration.flatColumnConfigurations
      : configuration?.combinedColumnConfigurations;

  let mutationStatus = updatingStatus;
  if (revertUpdatingStatus === 'loading') {
    mutationStatus = revertUpdatingStatus;
  }

  return {
    configuration,
    currentColumns,
    updateVariant,
    revertToDefault,
    refetch,
    status,
    updatingStatus: mutationStatus,
    isRefetching,
  };
};
