import { InputWrapper } from '../InputWrapper/InputWrapper';
import { TextFieldProps } from '../input.interface';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { palette } from 'theme/palette';
import { TextField } from '../TextField/TextField';

export type SearchTextFieldProps = TextFieldProps;

const InputWrapperStyled = styled(InputWrapper)({
  width: '100%',
  '& .clear-button': {
    position: 'absolute',
    right: 0,
    top: '2px',
  },
  '& .textfield-error': {
    color: palette.failed.contrastText,
  },
});

const CounterBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: '10px',
  transition: 'opacity 0.5s ease-out',
  opacity: 0,
  overflow: 'hidden',
});

export const TextfieldWithCounter: React.FC<SearchTextFieldProps> = ({
  label,
  fullWidth,
  onChange,
  value,
  sx,
  inputWrapperSx,
  maxLength,
  showCounter,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <InputWrapperStyled
      label={label}
      fullWidth={fullWidth}
      sx={{
        ...inputWrapperSx,
        transition: 'height 0.5s ease-out',
        height: !showCounter ? '40px !important' : isFocused ? '64px !important' : '40px !important',
      }}
    >
      <TextField
        value={(value as string) || ''}
        data-testid="Search textarea"
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        inputProps={{ maxLength: maxLength }}
        {...props}
      />
      {showCounter && (
        <CounterBox
          component="span"
          sx={{ opacity: isFocused ? 1 : 0, height: isFocused ? 'auto' : 0 }}
        >
          <Typography
            variant={'text1Secondary'}
            className={`${(value as string)?.length === maxLength ? 'textfield-error' : ''}`}
          >{`${(value as string)?.length || 0} / ${maxLength}`}</Typography>
        </CounterBox>
      )}
    </InputWrapperStyled>
  );
};
