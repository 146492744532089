import Table from "@mui/material/Table";

type StickyTableProps = {
  children: React.ReactElement | React.ReactElement[];
  'data-testid'?: string;
};

export const StickyTable: React.FC<StickyTableProps> = ({ children, ...props }) => (
  <Table sx={{ overflow: 'auto', position: 'relative' }} stickyHeader {...props}>
    {children}
  </Table>
);
