import { Scrollbars } from 'react-custom-scrollbars-2';
import { EmptyTableMessage } from 'common/components/EmptyTableMessage';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TablePagination from '@mui/material/TablePagination';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { ReactComponent as SpinnerImage } from 'assets/images/spinner.svg';
import React, { useRef } from 'react';

import { ContentBox } from '../AppLayout/ContentBox';
import { useTranslation } from 'react-i18next';
import { ScrollArea } from '../ScrollArea';

type TableLayoutProps = {
  page: number;
  rowsPerPage: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (pagePerPage: number) => void;
  totalNumberOfItems: number;
  noItemsMessage?: string | React.ReactElement;
  table: React.ReactElement | ((scrolRef: any) => React.ReactElement);
  paginationSx?: SxProps<Theme>;
};

export const TableLayoutWithPagination: React.FC<TableLayoutProps> = ({
  totalNumberOfItems,
  table,
  noItemsMessage = '',
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  paginationSx
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const scrollbarsRef = useRef<Scrollbars>(null);
  return (
    <ContentBox>
      <Box
        sx={{
          flex: '1',
          minHeight: '44px',
          backgroundColor: palette.grey['200']
        }}
      >
        <ScrollArea scrollAreaRef={scrollbarsRef} topOffsetScroll={45}>
          {React.isValidElement(table) ? table : table(scrollbarsRef)}
          {totalNumberOfItems === 0 ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                inset: 0
              }}
            >
              <EmptyTableMessage message={noItemsMessage} />
            </Box>
          ) : null}
        </ScrollArea>
      </Box>

      <TablePagination
        page={page || 0}
        showLastButton={false}
        count={totalNumberOfItems}
        onPageChange={(_event, page) => onPageChange(page)}
        onRowsPerPageChange={(event) => {
          onPageChange(0);
          onRowsPerPageChange(parseInt(event.target?.value));
        }}
        rowsPerPageOptions={[25, 50, 100]}
        rowsPerPage={rowsPerPage}
        sx={{ ...paginationSx }}
        component="div"
        labelRowsPerPage={t('common.rowsPerPage')}
        labelDisplayedRows={({ from, to, count }) => {
          if (count === -1) {
            return (
              <Container
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                component="span"
              >
                {`${from}–${to} of `}
                <Container
                  disableGutters
                  component="span"
                  sx={{
                    display: 'flex',
                    width: '16px',
                    m: 0,
                    ml: 1,
                    animation: 'spin 2s linear infinite',
                    '@keyframes spin': {
                      '0%': {
                        transform: 'rotate(0deg)'
                      },
                      '100%': {
                        transform: 'rotate(360deg)'
                      }
                    }
                  }}
                >
                  <SpinnerImage />
                </Container>
              </Container>
            );
          }

          return `${from}–${to} of ${count}`;
        }}
      />
    </ContentBox>
  );
};
