import { parseCustomFieldStringToSearchBody } from 'common/components/Filters/CustomFieldsFilter/customFields.utils';
import { uppercaseFirstLetter } from 'common/utils/stringUtil';
import { SimInventorySearch } from 'simInventory/Components/SimInventoryTable.interface';
import {
  NUMERIC_SIM_PROFILE_IDS,
  SimInventoryExtededFilterBody,
  SimInventoryExtededFilterBodyIdsProps,
  SimInventorySort,
} from 'simInventory/models/SimProfile.model';
import { convertStringToTags } from 'tags/utils';

export const prepareSimInventorySearchBody = (
  searchParams: SimInventorySearch,
  sort: SimInventorySort | null = null,
) => {
  const body: SimInventoryExtededFilterBody = {};

  if (searchParams.searchText.length === 1) {
    const id = searchParams.chosenIdType.toLowerCase() as SimInventoryExtededFilterBodyIdsProps;
    body[id] = searchParams.searchText[0];
  } else if (searchParams.searchText.length > 0 && searchParams.searchText[0]) {
    body['simProfileIds'] = {
      type: NUMERIC_SIM_PROFILE_IDS[searchParams.chosenIdType],
      ids: searchParams.searchText,
    };
  }

  if (searchParams.filters.label) {
    body['label'] = searchParams.filters.label;
  }

  if (searchParams.filters.connectionId) {
    body['connectionId'] = searchParams.filters.connectionId;
  }

  if (searchParams.filters.orderNumber) {
    body['orderNumber'] = searchParams.filters.orderNumber;
  }

  if (searchParams.filters.sessionStatus !== '') {
    body['sessionStatus'] = [searchParams.filters.sessionStatus];
  }

  if (searchParams.filters.accounts) {
    const accounts = convertStringToTags(searchParams.filters.accounts).map((account) =>
      parseInt(account),
    );
    body.accountRef = accounts;
  }

  if (searchParams.filters.mobileNetworkOperator) {
    body.cciis = convertStringToTags(searchParams.filters.mobileNetworkOperator);
  }

  if (searchParams.filters.tags) {
    body.tags = convertStringToTags(searchParams.filters.tags);
  }

  if (sort) {
    body.order = `${uppercaseFirstLetter(sort.columnName)}:${sort.sort?.toUpperCase()}`;
  }

  if (searchParams.filters.iccidFrom || searchParams.filters.iccidTo) {
    body.simProfileIdsRange = {
      type: 0,
      idsFrom: searchParams.filters.iccidFrom,
      idsTo: searchParams.filters.iccidTo,
    };
  }

  if (searchParams.filters.customFields) {
    body.customFieldSearch = parseCustomFieldStringToSearchBody(searchParams.filters.customFields);
  }

  return body;
};

export const preparePaginatedSimInventorySearchBody = (
  page: number,
  entryPerPage: number,
  searchParams: SimInventorySearch,
  sort: SimInventorySort | null = null,
) => {
  const body = prepareSimInventorySearchBody(searchParams, sort);
  body.pageNumber = page + 1;
  body.pageSize = entryPerPage;

  return body;
};
