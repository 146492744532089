import React from 'react';
import { ReactComponent as PrimaryProfileDisconnected } from 'assets/images/StatusIndicator/primaryDisconected.svg';
import { ReactComponent as SecondaryProfileDisconnected } from 'assets/images/StatusIndicator/secondaryDisconnected.svg';
import { ReactComponent as InactiveBoth } from 'assets/images/StatusIndicator/inactiveBoth.svg';
import { ReactComponent as PrimaryProfileActive } from 'assets/images/StatusIndicator/primaryRSimConnected.svg';
import { ReactComponent as SecondaryProfileActive } from 'assets/images/StatusIndicator/secondRSimConnected.svg';
import { ReactComponent as AlertrSIM } from 'assets/images/StatusIndicator/AlertrSIM.svg';
import { RsimProfileStatus } from 'rSimInventory/RSimApi/rSimApi.interface';
import Box from '@mui/material/Box';
import { i18 } from 'i18n/i18n';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { useKickSimContext } from './KickRsimProvider';
import Typography from '@mui/material/Typography';

type FCWithChildren = React.FC<React.PropsWithChildren>;

type StatusIndicatorProps = {
  status: RsimProfileStatus;
  euicc: string;
  hideTooltip?: boolean;
};

const statusIndicatorIconConfig = {
  width: '24px',
  height: '24px',
  role: 'img',
};

const IconRootBox: FCWithChildren = React.forwardRef(({ children, ...props }, ref) => (
  <Box ref={ref} sx={{ position: 'relative', width: '24px', height: '24px' }} {...props}>
    {children}
  </Box>
));

const IconAlertBox: FCWithChildren = ({ children }) => (
  <Box
    sx={{
      position: 'absolute',
      top: '-3px',
      right: '-3px',
    }}
  >
    {children}
  </Box>
);

const AlertIcon = () => (
  <IconAlertBox>
    <AlertrSIM height="13px" data-testid="Alert icon" />
  </IconAlertBox>
);

export const bothInactive = i18.t('common.unknown');
export const primaryProfileDisconnected = i18.t('rSimInventory.primaryProfileDisconnected');
export const secondaryProfileDisconnected = i18.t('rSimInventory.secondaryProfileDisconnected');

export const RSimStatusIndicator: React.FC<StatusIndicatorProps> = ({
  status,
  euicc,
  hideTooltip,
}) => {
  const { isPrimaryProfileDisabled, isSecondaryProfileDisabled } = useKickSimContext();

  const alert = isPrimaryProfileDisabled(euicc) || isSecondaryProfileDisabled(euicc);

  const { t } = useTranslation();
  const alertDecriptionRows = alert ? [`${t('common.simulateOutageRunning')}!`] : [];

  if (!hideTooltip) {
    if (status === RsimProfileStatus.PrimaryProfileActive) {
      const description = [...alertDecriptionRows];
      const profileActiveLabel = t('rSimInventory.primaryProfileActive');
      return (
        <Tooltip title={profileActiveLabel} description={description}>
          <IconRootBox>
            <PrimaryProfileActive {...statusIndicatorIconConfig} aria-label={profileActiveLabel} />
            {alert && <AlertIcon />}
          </IconRootBox>
        </Tooltip>
      );
    }
    if (status === RsimProfileStatus.SecondaryProfileActive) {
      const description = [...alertDecriptionRows];
      const profileActiveLabel = t('rSimInventory.secondaryProfileActive');

      return (
        <Tooltip title={profileActiveLabel} description={description}>
          <IconRootBox>
            <SecondaryProfileActive
              {...statusIndicatorIconConfig}
              aria-label={profileActiveLabel}
            />
            {alert && <AlertIcon />}
          </IconRootBox>
        </Tooltip>
      );
    }
    if (status === RsimProfileStatus.PrimaryProfileDisconnected) {
      const description = [
        `${t('rSimInventory.lastActiveProfile')}: ${t('common.primary')}`,
        ...alertDecriptionRows,
      ];

      return (
        <Tooltip title={t('common.disconnected')} description={description}>
          <IconRootBox>
            <PrimaryProfileDisconnected
              {...statusIndicatorIconConfig}
              aria-label={`${t('common.disconnected')}: ${t(
                'rSimInventory.lastActiveProfile',
              )}: ${t('common.primary')}`}
            />
            {alert && <AlertIcon />}
          </IconRootBox>
        </Tooltip>
      );
    }
    if (status === RsimProfileStatus.SecondaryProfileDisconnected) {
      const description = [
        `${t('rSimInventory.lastActiveProfile')}: ${t('common.backup')}`,
        ...alertDecriptionRows,
      ];
      return (
        <Tooltip title={t('common.disconnected')} description={description}>
          <IconRootBox>
            <SecondaryProfileDisconnected
              {...statusIndicatorIconConfig}
              aria-label={`${t('common.disconnected')}: ${t(
                'rSimInventory.lastActiveProfile',
              )}: ${t('common.backup')}`}
            />
            {alert && <AlertIcon />}
          </IconRootBox>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={bothInactive} description={alertDecriptionRows}>
        <IconRootBox>
          <InactiveBoth {...statusIndicatorIconConfig} aria-label={bothInactive} />
          {alert && <AlertIcon />}
        </IconRootBox>
      </Tooltip>
    );
  }

  if (status === RsimProfileStatus.PrimaryProfileActive) {
    const label = t('rSimInventory.primaryProfileActive');
    return (
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <IconRootBox>
          <PrimaryProfileActive {...statusIndicatorIconConfig} aria-label={label} />
          {alert && <AlertIcon />}
        </IconRootBox>
        <Typography variant="text1Primary">{label}</Typography>
      </Box>
    );
  }
  if (status === RsimProfileStatus.SecondaryProfileActive) {
    const label = t('rSimInventory.secondaryProfileActive');
    return (
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <IconRootBox>
          <SecondaryProfileActive {...statusIndicatorIconConfig} aria-label={label} />
          {alert && <AlertIcon />}
        </IconRootBox>
        <Typography variant="text1Primary">{label}</Typography>
      </Box>
    );
  }
  if (status === RsimProfileStatus.PrimaryProfileDisconnected) {
    const label = t('common.disconnected');
    return (
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <IconRootBox>
          <PrimaryProfileDisconnected
            {...statusIndicatorIconConfig}
            aria-label={`${label}: ${t('rSimInventory.lastActiveProfile')}: ${t('common.primary')}`}
          />
          {alert && <AlertIcon />}
        </IconRootBox>
        <Typography variant="text1Primary">{label}</Typography>
      </Box>
    );
  }
  if (status === RsimProfileStatus.SecondaryProfileDisconnected) {
    const label = t('common.disconnected');
    return (
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <IconRootBox>
          <SecondaryProfileDisconnected
            {...statusIndicatorIconConfig}
            aria-label={`${label}: ${t('rSimInventory.lastActiveProfile')}: ${t('common.backup')}`}
          />
          {alert && <AlertIcon />}
        </IconRootBox>
        <Typography variant="text1Primary">{label}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <IconRootBox>
        <InactiveBoth {...statusIndicatorIconConfig} aria-label={bothInactive} />
        {alert && <AlertIcon />}
      </IconRootBox>
      <Typography variant="text1Primary">{bothInactive}</Typography>
    </Box>
  );
};
