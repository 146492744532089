import React from 'react';
import { useSimInventoryFilters } from 'simInventory/Components/Filters/hooks/useSimInventoryFilter';

type FiltersContextProps = ReturnType<typeof useSimInventoryFilters>;

export const FiltersContext = React.createContext<FiltersContextProps>({} as FiltersContextProps);

export type FiltersProviderProps = {
  children?: React.ReactNode;
};

export const FiltersProvider = ({ children }: FiltersProviderProps) => {
  const filters = useSimInventoryFilters();

  return <FiltersContext.Provider value={filters}>{children}</FiltersContext.Provider>;
};
