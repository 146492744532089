import {
  SimInventoryResponsePagination,
  ErrorResponse,
  SimProfileDTO,
} from './simInventoryApi.interface';

import { SimInventorySearch } from '../Components/SimInventoryTable.interface';
import { authFetch } from 'auth/utils';
import { ErrorCodes } from 'common/errorCodes';

import { mapSessionStatusToStringSessionStatus } from '../utils/sessionStatus';
import { mapSimProfileDTO } from './simInventoryApi.utils';
import { normilizeAssignedTagsToSim } from 'tags/utils';
import { fetchAssignedTagToSims } from 'tags/services/tagsSimApi';

import { SimInventorySort } from 'simInventory/models/SimProfile.model';

import { preparePaginatedSimInventorySearchBody } from 'simInventory/utils/prepareSimInventorySearchBody';

const emptySimProfile = () => {
  const emptyItem: SimProfileDTO = {
    iccid: '',
    imsi: '',
    msisdn: '',
    euicc: '',
    imei: '',
    label: '',
    organisation: '',
    mobileNetworkOperator: '',
    customerCommsPlan: '',
    simType: 'Unknown',
    subscriptionStatus: 0,
    subscriptionStatusEntryTime: '',
    sessionStatus: 'Unknown',
    sessionStartTime: '',
    sessionEndTime: '',
    sessionStatusLastUpdated: '',
    apn: '',
    localization: null,
    liveDataUsage: null,
    inMinuteUsage: null,
    outMinuteUsage: null,
    inTextUsage: null,
    outTextUsage: null,
    orderNumber: '',
    connectionId: '',
    customerReference: '',
    managementIpAddress: '',
    currentIpAddress: '',
    accountRef: null,
    accountName: '',
    commsPlan: '',
    mnoRatePlan: '',
    accountIdentifier: '',
    rasClient: '',
    framedProtocol: '',
    nasIpAddress: '',
    serviceType: '',
    userName: '',
    timeZone: '',
    cumulativeInputUsage: null,
    cumulativeOutputUsage: null,
    eventTimestamp: '',
    ratType: '',
    tags: [],
    customFields: [],
    activeConfiguration: '',
  };
  const normalizedTagsData = normilizeAssignedTagsToSim([]);
  const simDetail = mapSimProfileDTO(emptyItem, {
    normalizedTagsData,
  });

  return {
    ...simDetail,
    profileStatus: mapSessionStatusToStringSessionStatus(emptyItem.sessionStatus),
  };
};

export const fetchSimInventoryExtendedFilters = async (
  page: number,
  entryPerPage: number,
  searchParams: SimInventorySearch,
  sort: SimInventorySort | null = null,
  signal?: AbortSignal,
) => {
  const body = preparePaginatedSimInventorySearchBody(page, entryPerPage, searchParams, sort);

  const simInventoryResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}`,
    {
      signal,
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  const simInventory: SimProfileDTO[] = await simInventoryResponse.json();

  const tags = await fetchAssignedTagToSims({ iccids: simInventory.map((sim) => sim.iccid) });
  const normalizedTagsData = normilizeAssignedTagsToSim(tags);

  return {
    items: simInventory.map((item) => mapSimProfileDTO(item, { normalizedTagsData })),
  };
};

export const fetchSimInventoryExtendedFiltersPagination = async (
  page: number,
  entryPerPage: number,
  searchParams: SimInventorySearch,
  signal?: AbortSignal,
) => {
  const body = preparePaginatedSimInventorySearchBody(page, entryPerPage, searchParams);

  const simInventoryPaginationResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/pagination`,
    {
      signal,
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  const simInventoryPaginationResponseData: SimInventoryResponsePagination =
    await simInventoryPaginationResponse.json();

  return {
    totalNumberOfItems:
      simInventoryPaginationResponseData.totalCount !== undefined
        ? simInventoryPaginationResponseData.totalCount
        : -1,
  };
};

export const fetchSimInventoryEntry = async (
  iccid: string,
  returnEmptyOnError?: boolean,
  signal?: AbortSignal,
) => {
  const simInventoryEntryResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/${iccid}`,
    { signal },
  );

  if (!simInventoryEntryResponse.ok) {
    const status = simInventoryEntryResponse.status.toString();

    if (returnEmptyOnError) {
      return emptySimProfile();
    }

    let errorMessage: ErrorResponse;
    try {
      errorMessage = await simInventoryEntryResponse.json();
    } catch (_err) {
      throw new Error(status);
    }

    const iccidNotFoundErrors = errorMessage.validationErrors.Iccid?.find(
      (error) => error.code === 'IccidValidator',
    );

    if (status === ErrorCodes.BadRequest && iccidNotFoundErrors) {
      throw new Error(ErrorCodes.NotFound);
    }

    throw new Error(status);
  }

  const simInventoryItem: SimProfileDTO = await simInventoryEntryResponse.json();

  const tags = await fetchAssignedTagToSims({ iccids: [iccid] });
  const normalizedTagsData = normilizeAssignedTagsToSim(tags);
  const simDetail = mapSimProfileDTO(simInventoryItem, {
    normalizedTagsData,
  });

  return simDetail;
};

export type MaxSimProfilesValidationError = {
  state: {
    maxSimProfilesCount: number;
  };
};
export class MaxSimProfilesCountError extends Error {
  maxSimProfilesCount: number;

  constructor(maxSimProfilesCount: number) {
    super(ErrorCodes.BadRequest);
    Object.setPrototypeOf(this, MaxSimProfilesCountError.prototype);
    this.maxSimProfilesCount = maxSimProfilesCount;
  }
}
