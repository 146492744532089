import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import { TagDisplay, TagDisplayProps } from './TagsDisplay';

export const TagsTableCell: React.FC<TagDisplayProps> = (props) => {
  return (
    <TableCell align="center" sx={{ maxWidth: '150px', py: 0 }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <TagDisplay {...props} />
      </Box>
    </TableCell>
  );
};
