import { Components } from '@mui/material';

export const MuiRadio: Components['MuiRadio'] = {
  styleOverrides: {
    root: {
      color: '#707676  !important',
      '&.Mui-checked': {
        color: '#0D90DC !important',
      },
    },
  },
};
