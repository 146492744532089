import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { tooltipSx } from './Tooltip.style';
import React from 'react';
import { SxProps } from '@mui/system/styleFunctionSx';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';

type TooltipProps = Pick<MuiTooltipProps, 'children'> & {
  title: string;
  description?: string | string[];
  component?: React.ReactNode;
  showArrow?: boolean;
  enterDelay?: number;
  enterTouchDelay?: number;
  enterNextDelay?: number;
  sx?: SxProps;
  textVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
  placement?: 'top' | 'bottom' | 'left' | 'right';
};

export const Tooltip: React.FC<TooltipProps> = React.memo(
  ({
    children,
    title,
    description,
    component,
    showArrow = false,
    enterDelay = 100,
    enterTouchDelay = 700,
    enterNextDelay = 0,
    sx = tooltipSx,
    textVariant = 'text3Primary',
    placement = 'top',
  }) => {
    let descriptionRows: string[] = [];

    if (Array.isArray(description)) {
      descriptionRows = description;
    } else if (typeof description === 'string') {
      descriptionRows = [description];
    }
    return (
      <MuiTooltip
        arrow={showArrow}
        placement={placement}
        enterDelay={enterDelay}
        enterTouchDelay={enterTouchDelay}
        enterNextDelay={enterNextDelay}
        componentsProps={{ tooltip: { sx: sx } }}
        key={`tooltip-${title}`}
        title={
          <>
            <Typography variant={textVariant} component="div">
              {title}
            </Typography>

            {descriptionRows.map((text, index) => (
              <Typography key={index} variant="text5Secondary" component="div">
                {text}
              </Typography>
            ))}

            {component}
          </>
        }
      >
        {children}
      </MuiTooltip>
    );
  },
);
