import { RSimTable } from './Components/RSimTable';
import { Outlet } from 'react-router-dom';
import { SuspenseLoadingView } from 'common/components/SuspenseLoadingView';
import { TableSkeleton } from 'common/components/Table/TableSkeleton';
import Box from '@mui/material/Box';
import { ContentBox } from 'common/components/AppLayout/ContentBox';
import Grid from '@mui/material/Grid';

import { TableWithFiltersLayout } from 'common/components/AppLayout/TableWithFiltersLayout';

import { RSimFiltersModal } from './Filters/RSimFiltersModal';
import { ValidRSimFiltersKeys } from './Filters/data/filters.constants';
import { RSimFiltersChips } from './Filters/RSimFiltersChips';
import { TagProvider } from 'tags/TagProvider';
import { convertStringToTags, convertTagsIdsToString } from 'tags/utils';
import { RefetchQueryButton } from 'common/components/Queries/RefetchQueryButton';
import { RSimIdSearchInput } from './Components/Inputs/RSimIdSearchInput';
import { RSimFiltersProvider } from './Filters/RSimFiltersProvider';
import { useRSimFilters } from './Filters/hooks/useRSimFilters';
import {
  TabBar,
  TabBarActions,
  TabBarChips,
  TabBarFilters,
} from 'common/components/AppLayout/TabBar/TabBar';

export const RSimInventory = () => {
  const { filters, updateOne, updateAll, clearAll, clearOne } = useRSimFilters();

  return (
    <TagProvider type="rsim">
      <TableWithFiltersLayout
        filters={
          <Box
            sx={{
              border: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              borderBottom: 'none',
            }}
          >
            <TabBar>
              <Grid container rowGap={4} sx={{ mt: 0 }} justifyContent={'space-between'}>
                <TabBarFilters>
                  <Box sx={{ flex: 1, maxWidth: 360 }}>
                    <RSimIdSearchInput
                      chosenId={filters.chosenIdType}
                      onChosenIdChange={function (chosenId: 'EID' | 'ICCID'): void {
                        updateOne('chosenIdType', chosenId);
                      }}
                      search={filters.searchText}
                      onSearchChange={(value: string) => {
                        updateOne('searchText', value);
                      }}
                    />
                  </Box>
                  <RSimFiltersModal
                    filters={filters}
                    onFiltersChange={(filters) => {
                      updateAll(filters);
                    }}
                  />
                </TabBarFilters>
                <TabBarActions>
                  <RefetchQueryButton queryKey={'RSimInventory'} />
                </TabBarActions>
              </Grid>
              <TabBarChips>
                <RSimFiltersChips
                  filters={{
                    lastActiveProfile: filters.lastActiveProfile,
                    lastUpdateTime: filters.lastUpdateTime,
                    tags: filters.tags,
                    connectionId: filters.connectionId,
                    orderNumber: filters.orderNumber,
                    simulateOutage: filters.simulateOutage,
                    accounts: filters.accounts,
                    eidFrom: filters.eidFrom,
                    eidTo: filters.eidTo,
                  }}
                  onFilterClear={(key) => clearOne(key as ValidRSimFiltersKeys)}
                  onFiltersClear={clearAll}
                  onTagRemoved={(id) => {
                    const tags = convertStringToTags(filters.tags);
                    const filteredTags = tags.filter((tag) => tag !== id);
                    updateOne('tags', convertTagsIdsToString(filteredTags));
                  }}
                  onAccountRemoved={(id) => {
                    const accounts = convertStringToTags(filters.accounts);
                    const filteredAccounts = accounts.filter((account) => account !== id);
                    updateOne('accounts', convertTagsIdsToString(filteredAccounts));
                  }}
                />
              </TabBarChips>
            </TabBar>
          </Box>
        }
      >
        <SuspenseLoadingView
          loadFallback={
            <ContentBox>
              <TableSkeleton showStartSkeleton numberOfRows={20} />
            </ContentBox>
          }
          queryKey={['RSimInventory']}
        >
          <RSimTable {...filters} />
          <Outlet />
        </SuspenseLoadingView>
      </TableWithFiltersLayout>
    </TagProvider>
  );
};

export const RSimInventoryTab = () => {
  return (
    <RSimFiltersProvider>
      <RSimInventory />
    </RSimFiltersProvider>
  );
};
