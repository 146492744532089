import React, { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { additionalMenuActionsButton } from './AdditionalMenuIds';
import ClickAwayListener from '@mui/material/ClickAwayListener';

type AdditionalMenuChildrenFunctionProps = {
  onClose: () => void;
};

export type AdditionalMenuProps = {
  children: (props: AdditionalMenuChildrenFunctionProps) => React.ReactNode;
};

export const AdditionalMenu: React.FC<AdditionalMenuProps> = ({ children }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => setAnchorEl(null);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box>
        <IconButton
          id={additionalMenuActionsButton}
          data-testid="additional-menu-actions-button"
          name="additional actions"
          aria-label={t('common.additionalActions')}
          aria-controls={open ? 'additional-actions-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={onOpen}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          sx={{ '& .MuiMenu-paper': { backgroundColor: ({ palette }) => '#fff' } }}
          id="additional-actions-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          MenuListProps={{
            'aria-labelledby': 'additional-actions-button',
          }}
        >
          {open ? children({ onClose }) : null}
        </Menu>
      </Box>
    </ClickAwayListener>
  );
};
