import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Select } from '../Select/Select';
import { SelectProps } from '../input.interface';
import { InputWrapper } from '../InputWrapper/InputWrapper';
import { SearchTextField } from '../SearchTextField/SearchTextField';

const Root = styled(Stack)({
  display: 'flex',
});

const MultiOptionInputSelect = styled(Select)(() => {
  return {
    flex: 0,
    '& .MuiInputBase-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  };
});

const Input = styled(SearchTextField)(() => {
  return {
    height: '40px',
    '& fieldset': {
      marginLeft: '-1px',
      borderTopRightRadius: '10px',
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '10px',
    },
  };
});

const SelectContainer = styled('div')(() => {
  return {
    flex: 0,
  };
});

export type MultiOptionInputSearchProps = {
  disabled?: boolean;
  placeholder?: string;
  filterRegex?: RegExp;
  inputLabel: string;
  inputValue: string;
  onInputValueChange: (value: string) => void;

  selectLabel: string;
  children: SelectProps['children'];
  optionValue: SelectProps['value'];
  onOptionChange: (value: string) => void;
};

export const MultiOptionInputSearch = (props: MultiOptionInputSearchProps) => {
  const {
    disabled = false,
    inputLabel,
    inputValue,
    filterRegex,
    onInputValueChange,

    selectLabel,
    children,
    optionValue,
    onOptionChange,
    placeholder,
    ...other
  } = props;

  const handleInputValueChange = (value: string) => {
    if (!filterRegex) {
      onInputValueChange(value);
    } else if (filterRegex.test(value)) {
      onInputValueChange(value);
    }
  };

  return (
    <Root direction="row" {...other}>
      <SelectContainer>
        <MultiOptionInputSelect
          disabled={disabled}
          data-testid="Multi option select"
          fullWidth={false}
          label={selectLabel}
          value={optionValue}
          onChange={(event) => {
            onOptionChange(event.target.value as string);
          }}
        >
          {children}
        </MultiOptionInputSelect>
      </SelectContainer>

      <InputWrapper label={inputLabel}>
        <Input
          disabled={disabled}
          placeholder={placeholder}
          value={inputValue}
          onChange={(event) => {
            handleInputValueChange(event.target.value);
          }}
        />
      </InputWrapper>
    </Root>
  );
};
