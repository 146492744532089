import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import {
  ColumnConfiguration,
  TABLE_VARIANT,
  TableVariant,
} from '../../../SimInventoryApi/simInventoryTableConfigurationApi.interface';
import { ReactComponent as CombinedIcon } from 'assets/images/combined.svg';
import { ReactComponent as ResetWidthIcon } from 'assets/images/resetWidth.svg';
import { useTranslation } from 'react-i18next';
import { useDrag, useDrop } from 'react-dnd';
import { CheckboxStyled } from 'common/components/CheckboxStyled/CheckboxStyled';
import { greyColor, typoColor } from 'theme/palette';
import {
  gridColumnVisibilityModelSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { styled } from '@mui/material';
import { tooltipSx } from 'common/components/Tooltip/Tooltip.style';

type TableSettingsItemProps = {
  column: ColumnConfiguration;

  handleWidthChange: (columnName: string, width: 'auto', isCustomField: boolean) => void;
  handleDrop: (oldIndex: number, newIndex: number) => void;
  variant: TableVariant;
  idLabelMap: Record<string, string>;
  index: number;
};

const NameTypoStyled = styled(Typography)({
  marginTop: '2px',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 'calc(100% - 45px)'
});

const NameBoxStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
});

export const TableSettingsItem: React.FC<TableSettingsItemProps> = ({
  column,
  index,
  handleWidthChange,
  handleDrop,
  variant,
  idLabelMap,
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, dragRef, dragPreview] = useDrag(
    () => ({
      type: 'column',
      item: { columnName: column.name, columnIndex: index },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [column.name],
  );

  const [{ isOver }, dropRef] = useDrop({
    accept: 'column',
    drop: (item: any) => {
      handleDrop(item.columnIndex, index);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const ref = useGridApiContext();

  const visibilityModel = useGridSelector(ref, gridColumnVisibilityModelSelector);

  return (
    <Box ref={dropRef}>
      <Box ref={dragPreview}>
        <Box
          ref={dragRef}
          sx={{
            display: 'grid',
            gridTemplateColumns: '24px auto',
            gridAutoRows: 'minmax(26px,28px)',
            alignItems: 'center',
            columnGap: '4px',
            rowGap: '0',
            '&:hover': {
              backgroundColor: greyColor[100],
            },
            cursor: 'move',
            borderBottom: `2px solid ${isOver ? greyColor[600] : typoColor.white}`,
          }}
        >
          <CheckboxStyled
            isChecked={visibilityModel[column.name]}
            handleCheckboxClick={(isChecked) => {
              const test = async () => {
                ref.current.setColumnVisibility(column.name, isChecked);
              };
              test();
            }}
            testId={`configurable-table-settings-checkbox-${column.name}`}
          />
          <NameBoxStyled>
            {column.isCustomField ? (
              <Tooltip title={column.name} sx={{...tooltipSx, textTransform: 'capitalize'}}>
                <NameTypoStyled variant="text1">{column.name}</NameTypoStyled>
              </Tooltip>
            ) : (
              <NameTypoStyled variant="text1">{idLabelMap[column.name]}</NameTypoStyled>
            )}
            {column.width !== 'auto' && (
              <Tooltip title={t('common.resetColumnWidth')}>
                <ResetWidthIcon
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                  width="15px"
                  height="15px"
                  role="img"
                  data-testid={`reset-width-${column.name}`}
                  onClick={() => handleWidthChange(column.name, 'auto', column.isCustomField)}
                />
              </Tooltip>
            )}
          </NameBoxStyled>
          {variant === TABLE_VARIANT.Combined && column.children?.length > 0 ? (
            <>
              <div style={{ height: '28px' }}>
                <CombinedIcon
                  style={{ marginLeft: '11px' }}
                  width="15px"
                  height="17px"
                  role="img"
                  data-testid={`combined-icon-${column.name}`}
                />
              </div>
              <Typography
                variant={column.enabled ? 'text1' : 'text1Secondary'}
                sx={{
                  ml: 2,
                  height: '28px',
                  textTransform: 'capitalize',
                }}
              >
                {idLabelMap[column.children[0]?.name]}
              </Typography>
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
