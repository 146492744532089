import { Components } from '@mui/material';
import { backgroundColor, greyColor, palette } from 'theme/palette';
import { typographyBase } from 'theme/typography';
import {
  scrollbarHorizontalSX,
  scrollBarsSX,
  scrollbarVerticalSX,
} from 'theme/utility/utility.styles';

export const MuiDataGrid: Components['MuiDataGrid'] = {
  defaultProps: {
    disableRowSelectionOnClick: true,
  },
  styleOverrides: {
    root: {
      border: 'none',
      '& .MuiDataGrid-container--top': {
        '&::after': {
          backgroundColor: greyColor[200],
        },
      },

      '& .MuiDataGrid-filler--pinnedRight': {
        borderLeft: 'none',
      },
      '& .MuiDataGrid-columnHeader': {
        ...typographyBase.text3,
        height: '42px !important',

        borderBottom: '1px solid #dfdfdf',
        '&:focus': {
          outline: 'none',
        },
      },

      '& .MuiDataGrid-columnHeaders': {
        height: '42px',
        minHeight: '42px !important',
        maxHeight: '42px !important',
        lineHeight: '42px !important',
        backgroundColor: greyColor[100],
        position: 'sticky',
        top: 0,
        '& .MuiDataGrid-columnHeader--pinnedRight': {
          backgroundColor: greyColor[100],

          borderLeft: 'none',
          opacity: '0 !important',
          '& *': {
            opacity: '0 !important',
          },
          '& .MuiDataGrid-columnHeader': {
            opacity: '0 !important',
          },
        },
        '&:hover': {
          '& .MuiDataGrid-columnHeader--pinnedRight': {
            opacity: '1 !important',
            '& *': {
              opacity: '1 !important',
            },
            '& .MuiDataGrid-columnHeader': {
              opacity: '1 !important',
            },
          },
        },
      },
      '& .MuiDataGrid-row': {
        background: palette.white,
        '&:hover': {
          backgroundColor: backgroundColor,

          '& .MuiDataGrid-cell--pinnedRight': {
            backgroundColor: `${backgroundColor} !important`,
          },
          '& .MuiDataGrid-cell--pinnedRight > *': {
            opacity: 1,
          },
        },

        '& .MuiDataGrid-cell--pinnedRight': {
          borderLeft: 'none !important',

          '&  > *': {
            opacity: 0,
          },
        },
      },
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
        outline: 'none !important',
      },
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-row--firstVisible': {
        '& .MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--borderTop': {
          borderTop: 'none',
        },
      },
      '& .MuiDataGrid-columnHeaderTitleContainerContent': {
        width: '100%',
      },
      '& .MuiDataGrid-cell': {
        ...typographyBase.text1,
        display: 'flex',
        alignItems: 'center',
      },
      '& .MuiDataGrid-scrollbar--vertical::-webkit-scrollbar': {
        width: '14px',
      },

      '& .MuiDataGrid-scrollbar--horizontal': {
        ...scrollbarHorizontalSX,
        ...scrollBarsSX,
      },

      '& .MuiDataGrid-scrollbar--vertical': {
        ...scrollbarVerticalSX,
        ...scrollBarsSX,
      },
      '& .MuiDataGrid-virtualScroller': {
        backgroundColor: '#f9f9f9',
      },
    },
    footerContainer: {
      '& .MuiTablePagination-root': {
        flex: 1,
      },
    },
  },
};
