import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { TableHeaderCell } from 'common/components/Table/TableHeaderCell';
import {
  SimInventoryHeaderResponse,
  SimProfile,
  ValuesTypes,
} from '../SimInventoryApi/simInventoryApi.interface';
import { StickyTable } from 'common/components/Table/StickyTable';
import { useTranslation } from 'react-i18next';
import { PrioritizedTwoValuesCell } from 'common/components/Table/PrioritizedTwoValuesCell';
import { DateRangeTableCell } from 'common/components/Table/DateRangeTableCell';
import { SimInventoryTableBaseProps } from './SimInventoryTableBase.interface';
import { SessionStatusIndicator } from './SessionStatusIndicator';
import { simsInventoryTableDataTestId } from '../SimInventoryDataTestIds';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from 'Routes.constants';
import { simInventoryColumnsStyles } from './SimInventoryTableBase.style';
import { TwoValueCellLayout, ValueBox } from 'common/components/Table/TwoValueCell';
import { StickyCell } from './StickyCell';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { CopyableLink } from 'common/components/Table/Link';
import { SimInventoryEditDialog } from 'simInventory/SimInventoryDetails/SimInventoryEditDialog';
import { DefaultThemeProvider } from 'theme/DefaultThemeProvider';
import { TagsTableCell } from 'tags/TagsTableCell';
import { RowWithStickyMenu } from 'common/components/Table/RowWithStickyMenu';
import { getSessionStatusTranslatedLabel } from '../utils/sessionStatus';
import { getHuminizeDurationInSecFunction } from 'i18n/dateLocale';
import { useAbility } from 'permissions/hooks/useAbility';
import { Actions, Subjects } from 'permissions/ability';

const humanize = getHuminizeDurationInSecFunction();

export const SimInventoryTableBase: React.FC<SimInventoryTableBaseProps> = React.memo(
  ({
    items,
    headers,
    headerSx,
    additionalHeadersStart,
    additionalHeadersEnd,
    renderAdditionalCellsStart,
    renderAdditionalCellsEnd,
    onTagsUpdate,
  }) => {
    const { t } = useTranslation();
    const idLabelMap: Partial<Record<ValuesTypes, string>> = useMemo(
      () => ({
        iccid: t('common.iccid'),
        imsi: t('common.imsi'),
        msisdn: t('common.msisdn'),
        currentIpAddress: t('common.currentIp'),
        managementIpAddress: t('common.managementIp'),
        mnoRatePlan: t('common.plan'),
        organisation: t('common.organisation'),
        label: t('common.label'),
        subscriptionStatus: t('common.subscriptionStatus'),
        subscriptionStatusEntryTime: t('simInventory.subscriptionStatusEntryTime_combined'),
        customerCommsPlan: t('simInventory.customerCommsPlan'),
        commsPlan: t('simInventory.commsPlan'),
        mobileNetworkOperator: t('simInventory.mobileNetworkOperator'),
        accountName: t('common.accountName'),
        accountRef: t('common.accountNumber'),
        simStatus: t('simInventory.simStatus'),
        liveDataUsage: t('common.usage'),
        inMinuteUsage: t('common.inMinuteUsage'),
        outMinuteUsage: t('common.outMinuteUsage'),
        inTextUsage: t('common.inTextUsage'),
        outTextUsage: t('common.outTextUsage'),
        sessionStartTime: t('simInventory.sessionStartTime'),
        sessionEndTime: t('simInventory.sessionEndTime'),
        sessionStatus: t('simInventory.sessionStatus'),
        sessionStatusLastUpdated: t('simInventory.sessionStatusLastUpdated_combined'),
        connectionId: t('simInventory.connectionId'),
        orderNumber: t('simInventory.orderNumber'),
        tags: t('common.tag'),
      }),
      [],
    );

    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const [editedSim, setEditedSim] = useState<string | null>(null);

    const handleEditDialogClose = useCallback(() => {
      setEditedSim(null);
    }, []);

    const ability = useAbility();
    const editingSimAvailable =
      ability.can(Actions.edit, Subjects.tags) || ability.can(Actions.edit, Subjects.customFields);
    return (
      <Box sx={{ position: 'relative' }}>
        <StickyTable data-testid={simsInventoryTableDataTestId}>
          <TableHead>
            <TableRow>
              {additionalHeadersStart}
              {headers.map(({ id, types }: SimInventoryHeaderResponse) => {
                if (id === 'PollingSimStatus') {
                  return (
                    <React.Fragment key={id}>
                      <TableHeaderCell verticalAlign="top" sx={headerSx} />
                    </React.Fragment>
                  );
                }
                return (
                  <React.Fragment key={id}>
                    <TableHeaderCell
                      primaryText={idLabelMap[types[0]]}
                      secondaryText={idLabelMap[types[1]]}
                      verticalAlign="top"
                      sx={headerSx}
                    />
                  </React.Fragment>
                );
              })}
              {additionalHeadersEnd}
              <StickyCell sx={{ borderLeftStyle: 'none', ...headerSx }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item: SimProfile) => {
              return (
                <React.Fragment key={item.iccid}>
                  <RowWithStickyMenu
                    renderMenu={({ onClose }) => (
                      <MenuList sx={{ p: 0 }}>
                        <MenuItem
                          onClick={() => {
                            navigate(`${Routes.simInventory}/${item.iccid?.toString()}`, {
                              state: { previousPath: `${pathname}${search}` },
                            });
                            onClose();
                          }}
                        >
                          <ListItemText>{t('bulkProvisioning.viewDetails')}</ListItemText>
                        </MenuItem>
                        {editingSimAvailable && (
                          <MenuItem
                            onClick={() => {
                              setEditedSim(item.iccid);
                              onClose();
                            }}
                          >
                            <ListItemText>Edit</ListItemText>
                          </MenuItem>
                        )}
                      </MenuList>
                    )}
                  >
                    {renderAdditionalCellsStart && renderAdditionalCellsStart(item)}
                    {headers.map((column: SimInventoryHeaderResponse) => {
                      if (column.id === 'sessionStart&sessionEnd') {
                        return (
                          <React.Fragment key={column.id}>
                            <DateRangeTableCell
                              sx={simInventoryColumnsStyles[column.id]}
                              primaryText={item[column.types[0] as ValuesTypes]}
                              secondaryText={item[column.types[1]]}
                            />
                          </React.Fragment>
                        );
                      }

                      if (column.id === 'sessionStatus') {
                        return (
                          <React.Fragment key={column.id}>
                            <TableCell align="center" sx={{ py: 1.5 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <SessionStatusIndicator status={item.sessionStatus} />
                              </Box>
                            </TableCell>
                          </React.Fragment>
                        );
                      }

                      if (column.id === 'sessionStatus&sessionStatusLastUpdated') {
                        return (
                          <React.Fragment key={column.id}>
                            <PrioritizedTwoValuesCell
                              sx={simInventoryColumnsStyles[column.id]}
                              primaryText={
                                getSessionStatusTranslatedLabel(
                                  item[column.types[0]]?.toString(),
                                ) || ''
                              }
                              secondaryText={item[column.types[1]]?.toString() || ''}
                            />
                          </React.Fragment>
                        );
                      }

                      if (column.id === 'iccid&imsi') {
                        return (
                          <React.Fragment key={column.id}>
                            <TwoValueCellLayout
                              sx={simInventoryColumnsStyles[column.id]}
                              primaryContent={
                                <CopyableLink
                                  value={item[column.types[0]]?.toString()}
                                  to={`${Routes.simInventory}/${item.iccid?.toString()}`}
                                />
                              }
                              secondaryContent={
                                <ValueBox
                                  variant="tableCellSecondary"
                                  text={item[column.types[1]]?.toString() || ''}
                                />
                              }
                            />
                          </React.Fragment>
                        );
                      }

                      if (column.id === 'iccid') {
                        return (
                          <React.Fragment key={column.id}>
                            <TwoValueCellLayout
                              sx={simInventoryColumnsStyles[column.id]}
                              primaryContent={
                                <CopyableLink
                                  value={item[column.types[0]]?.toString()}
                                  to={`${Routes.simInventory}/${item.iccid?.toString()}`}
                                />
                              }
                              secondaryContent={
                                <ValueBox
                                  variant="tableCellSecondary"
                                  text={item[column.types[1]]?.toString() || ''}
                                />
                              }
                            />
                          </React.Fragment>
                        );
                      }

                      if (column.id === 'tags') {
                        return (
                          <React.Fragment key={column.id}>
                            <TagsTableCell
                              initialTags={item.tags?.items?.map((tag) => tag.name) || []}
                              id={item.iccid}
                              hasMore={item.tags?.hasMore || false}
                            />
                          </React.Fragment>
                        );
                      }

                      if (column.id === 'inMinuteUsage&outMinuteUsage') {
                        return (
                          <React.Fragment key={column.id}>
                            <PrioritizedTwoValuesCell
                              sx={simInventoryColumnsStyles[column.id]}
                              primaryText={humanize(item[column.types[0]])}
                              secondaryText={humanize(item[column.types[1]])}
                            />
                          </React.Fragment>
                        );
                      }

                      return (
                        <React.Fragment key={column.id}>
                          <PrioritizedTwoValuesCell
                            sx={simInventoryColumnsStyles[column.id]}
                            primaryText={item[column.types[0]]?.toString() || ''}
                            secondaryText={item[column.types[1]]?.toString() || ''}
                          />
                        </React.Fragment>
                      );
                    })}
                    {renderAdditionalCellsEnd && renderAdditionalCellsEnd(item)}
                  </RowWithStickyMenu>
                </React.Fragment>
              );
            })}
          </TableBody>
        </StickyTable>
        {editedSim ? (
          <Suspense>
            <DefaultThemeProvider>
              <SimInventoryEditDialog
                open
                iccid={editedSim}
                onClose={handleEditDialogClose}
                onTagsUpdate={onTagsUpdate}
                queryKey={'RSimSimProfiles'}
              />
            </DefaultThemeProvider>
          </Suspense>
        ) : null}
      </Box>
    );
  },
);
