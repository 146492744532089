import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import NotFoundSmall from 'assets/images/notFoundSmall.png';

const NoResultsRoot = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  gap: '10px',
});

export const NoRowsOverlay = () => {
  const { t } = useTranslation();

  return (
    <NoResultsRoot>
      <img src={NotFoundSmall} alt="" />
      <Typography variant="h3Secondary">{t('common.noResults')}</Typography>
    </NoResultsRoot>
  );
};
