import React from 'react';

import { TwoValueCell } from './TwoValueCell';
import { TwoValueCellPropsWithoutVariants } from './TwoValueCell.interface';

export const PrioritizedTwoValuesCell: React.FC<TwoValueCellPropsWithoutVariants> = React.memo(
  (props) => {
    return (
      <TwoValueCell
        {...props}
        primaryVariant="tableCellPrimary"
        secondaryVariant="tableCellSecondary"
      />
    );
  }
);
