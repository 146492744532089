import React from 'react';
import Typography, { TypographyTypeMap } from '@mui/material/Typography';

type CounterProps = {
  count: number;
  variant?: TypographyTypeMap['props']['variant'];
};

export const Counter: React.FC<CounterProps> = ({ count, variant = 'counter' }) => (
  <Typography variant={variant}>{count === 0 ? '-' : count.toString()}</Typography>
);
