import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';
import CSS from 'csstype';

export type BoxHeaderCellProps = {
  primaryText?: string;
  secondaryText?: string;
  verticalAlign?: CSS.Property.VerticalAlign;
  sx?: SxProps<Theme>;
};

export const BoxHeaderCell: React.FC<BoxHeaderCellProps> = ({
  primaryText,
  secondaryText,
  verticalAlign,
  sx
}) => {
  return (
    <Box sx={{ verticalAlign, textTransform: 'capitalize', ...sx }}>
      <Typography variant="tableHeaderPrimary">
        <Box data-testid="primaryText">{primaryText}</Box>
      </Typography>
      <Typography variant="tableHeaderSecondary">
        <Box data-testid="secondaryText">{secondaryText}</Box>
      </Typography>
    </Box>
  );
};
