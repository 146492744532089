import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

import { ContentCopyExtension } from 'common/ContentCopyExtension/ContentCopyExtension';
import { TwoValueCellProps } from './TwoValueCell.interface';

export const BoxTwoValueCell: React.FC<TwoValueCellProps> = ({
  primaryText = '',
  primaryVariant,
  secondaryText = '',
  secondaryVariant,
  sx
}) => {
  return (
    <Box sx={{ verticalAlign: 'center', py: 1.5, ...sx }}>
      <Box sx={{ whiteSpace: 'nowrap', lineHeight: '21px' }} data-testid="primaryText">
        <ContentCopyExtension value={primaryText}>
          <Typography variant={primaryVariant}>{primaryText}</Typography>
        </ContentCopyExtension>
      </Box>
      <Box sx={{ whiteSpace: 'nowrap', lineHeight: '18px' }} data-testid="secondaryText">
        <ContentCopyExtension value={secondaryText}>
          <Typography variant={secondaryVariant}>{secondaryText}</Typography>
        </ContentCopyExtension>
      </Box>
    </Box>
  );
};
