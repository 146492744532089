import { useMemo } from 'react';
import { cleanFalsyEntries } from 'common/utils/objectUtils';
import { useSearchParams } from 'react-router-dom';
import {
  SIM_PROFILE_IDS,
  SimInventoryFilters,
  SimProfileId,
  simInventoryFiltersSchema,
} from 'simInventory/models/SimProfile.model';
import { parseSessionStatus } from 'simInventory/FiltersModal/filterParsers.utils';
import { getUpdatedUrlSearchParamsWithObject } from 'common/utils/urlSearchParamsUtils';
import { useMultipleIdSearch } from '../../../hooks/useMultipleIdSearch';
import { useSimInventorySearchStorageSaver } from '../../../hooks/useSimInventorySearchStorageSaver';
import { useSearchStorage } from 'simInventory/Components/Filters/hooks/useSearchStorage';
import { INITIAL_FILTERS } from 'simInventory/FiltersModal/hooks/useSimInventoryFiltersModal';

export const useSimInventoryFilters = () => {
  const { searchQuery: savedSearchquery } = useSearchStorage();

  const { idType, ids, searchQuery, updateIdType, updateSearchQuery } =
    useMultipleIdSearch<SimProfileId>(SIM_PROFILE_IDS.ICCID, savedSearchquery);

  useSimInventorySearchStorageSaver(searchQuery);

  const [searchParams, setSearchParams] = useSearchParams();
  const updateSearchParams = (updateObject: any) => {
    const currentSearchParams = Object.fromEntries(searchParams.entries());
    const updatedSearchParams = {
      ...currentSearchParams,
      ...updateObject,
    };
    setSearchParams(cleanFalsyEntries(updatedSearchParams), { replace: true });
  };

  const filters = useMemo(() => {
    try {
      return simInventoryFiltersSchema.parse({
        sessionStatus: parseSessionStatus(searchParams.get('sessionStatus')),
        accounts: searchParams.get('accounts') || '',
        mobileNetworkOperator: searchParams.get('mobileNetworkOperator') || '',
        label: searchParams.get('label') || '',
        connectionId: searchParams.get('connectionId') || '',
        orderNumber: searchParams.get('orderNumber') || '',
        tags: searchParams.get('tags') || '',
        iccidFrom: searchParams.get('iccidFrom') || '',
        iccidTo: searchParams.get('iccidTo') || '',
        customFields: searchParams.get('customFields') || '',
      });
    } catch (err) {
      updateSearchParams(INITIAL_FILTERS);
      return INITIAL_FILTERS;
    }
  }, [searchParams]);

  const setFilters = (filter: SimInventoryFilters) => {
    setSearchParams(
      getUpdatedUrlSearchParamsWithObject(
        {
          ...filter,
          sessionStatus: filter.sessionStatus?.toString(),
        },
        searchParams,
      ),
    );
  };

  return {
    idType,
    ids,
    searchQuery,
    updateSearchQuery,
    updateIdType,
    filters,
    setFilters,
  };
};
