import { Theme } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system';
import { tableRowsWithTopBorder } from 'theme/utility/components.styles';

import {
  backgroundDefault,
  noBorder,
  padding4,
  paddingHorizontal3,
  removeMarginsAndPaddings,
  rightBorder,
} from 'theme/utility/utility.styles';

export const collapseContent: SystemStyleObject<Theme> = {
  ...padding4
};

export const collapseRowSeparator: SystemStyleObject<Theme> = {
  ...noBorder,
  ...backgroundDefault,
  ...removeMarginsAndPaddings,
  height: '16px'
};

export const collapseRow: SystemStyleObject<Theme> = {
  ...tableRowsWithTopBorder
};

export const stickyBorder: SystemStyleObject<Theme> = {
  width: '1px',
  minWidth: '1px',
  position: 'sticky',
  zIndex: 10,
  ...rightBorder,
  ...removeMarginsAndPaddings
};

export const expandButtonCell: SystemStyleObject<Theme> = {
  ...removeMarginsAndPaddings,
  ...paddingHorizontal3
};
