import { SystemStyleObject } from '@mui/system';
import { Theme } from '@mui/material/styles';
import inputCloseImage from 'assets/images/inputClose.svg';

export const SearchTextFieldHTMLInputSX: SystemStyleObject<Theme> = {
  '::-webkit-search-cancel-button': {
    '-webkit-appearance': 'none',
    height: '12px',
    width: '12px',
    backgroundImage: `url(${inputCloseImage})`,
    m: 0
  }
};

export const SearchTextFieldMuiInputSX: SystemStyleObject<Theme> = {
  height: '40px',
  p: 0
};
