import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro';
import { NoRowsOverlay } from './Components/NoRowsOverlay';
import { CellConfiguration, CellLoader } from './Components/Cells/CellLoader';
import { useMemo } from 'react';
import {
  DataGridProProps,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { ActionCell } from './Components/Cells/ActionCell';
import { EditableProvider } from './Components/Editing/EditableProvider';
import { EditDialog } from './Components/Editing/EditDialog';
import { HeaderCell } from './Components/Header/HeaderCell';
import { Pagination } from './Components/Pagination/Pagination';

export type Configuration = Pick<
  GridColDef,
  | 'field'
  | 'resizable'
  | 'sortable'
  | 'headerName'
  | 'pinnable'
  | 'flex'
  | 'width'
  | 'maxWidth'
  | 'minWidth'
>;

type ConfigurableTableProps = {
  disableColumnReorder?: boolean;
  configuration: Configuration[];
  cellConfiguration: CellConfiguration;
  //TODO: Imporve typings (generic)
  rows?: Record<string, any>[];
  totalNumberOfItems?: number;
  actions?: any;
  'data-testid'?: string;
  page?: number;
  pageSize?: number;
  onPaginationChange?: (pagination: GridPaginationModel) => void;
  slots?: DataGridProProps['slots'];
};

export const configurableCellRender =
  (cellConfiguration: any) => (params: GridRenderCellParams) => {
    return <CellLoader {...params} cellConfiguration={cellConfiguration} />;
  };

export const ConfigurableTableContent = ({
  configuration,
  cellConfiguration,
  rows = [],
  totalNumberOfItems,
  actions,
  page = 0,
  pageSize = 25,
  onPaginationChange,
  slots,
  ...props
}: ConfigurableTableProps) => {
  const columnsConfiguration = useMemo(() => {
    const columns = configuration.map((column) => {
      const columnDef: GridColDef = {
        ...column,
        renderCell: configurableCellRender(cellConfiguration),
        renderHeader: (props) => {
          return <HeaderCell primaryText={props.colDef.headerName || ''} />;
        },
      };

      return columnDef;
    });

    if (actions) {
      const actionsColDef: GridColDef = {
        field: 'actions',
        headerName: '',
        resizable: false,
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        renderCell: (props) => {
          return <ActionCell actions={actions} data={props.row} />;
        },

        sortable: false,
        pinnable: false,
      };
      columns.push(actionsColDef);
    }
    return columns;
  }, [configuration, actions]);

  const paginationModel = useMemo(() => {
    return {
      page: page,
      pageSize: pageSize,
    };
  }, [page, pageSize]);

  return (
    <DataGridPro
      {...props}
      pagination
      disableColumnMenu
      //@ts-ignore
      columns={columnsConfiguration}
      columnBufferPx={9999}
      rowBufferPx={420}
      rowCount={totalNumberOfItems}
      onPaginationModelChange={onPaginationChange}
      paginationModel={paginationModel}
      rows={rows}
      slots={{
        noRowsOverlay: NoRowsOverlay,
        pagination: Pagination,
        ...slots,
      }}
      paginationMode="server"
      pinnedColumns={{
        right: ['actions'],
      }}
    />
  );
};

export const ConfigurableTable = (props: ConfigurableTableProps) => {
  return (
    <EditableProvider>
      <ConfigurableTableContent {...props} />;
      <EditDialog
        onApplyChangesClick={function (): Promise<void> {
          throw new Error('Function not implemented.');
        }}
      >
        <></>
      </EditDialog>
    </EditableProvider>
  );
};
