import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MuiDialogTitle from '@mui/material/DialogTitle';

type DialogTitleProps = {
  title: string;
  subtitle?: string;
};

export const DialogTitle: React.FC<DialogTitleProps> = ({ title, subtitle }) => {
  return (
    <MuiDialogTitle sx={{ pt: 2, pb: 1, px: 5 }}>
      <Stack>
        <Typography variant="h2" component="span">
          {title}
        </Typography>
        {subtitle ? (
          <Typography variant="text4Secondary" mt={-1.5}>
            {subtitle}
          </Typography>
        ) : null}
      </Stack>
    </MuiDialogTitle>
  );
};
