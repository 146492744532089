import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AutocompleteSelect } from 'common/components/Inputs/AutocompleteSelect/AutocompleteSelect';

import { EditDialog } from 'common/components/Dialog/EditDialog';
import { useTagsAutocompleteSelect } from 'tags/hooks/useTagsAutocompleteSelect';
import { SimInventoryCan } from 'permissions/PermissionProvider';
import { Actions, Subjects } from 'permissions/ability';
import { useAbility } from 'permissions/hooks/useAbility';

type RSimInventoryEditDialogProps = {
  open: boolean;
  euicc: string;
  onClose: () => void;
  onTagsUpdate?: (euicc: string, tags: any) => void;
};

export const RSimInventoryEditDialog = ({
  open,
  euicc,
  onClose,
  onTagsUpdate,
}: RSimInventoryEditDialogProps) => {
  const ability = useAbility();
  const {
    isLoading,
    tags,
    assignedSimTags,

    addNewTags,
    selectTags,
    save,
    refresh,

    changed,

    setChanges,
    searchValue,
    setSearchValue,
    errors,
    validateTagLength,
  } = useTagsAutocompleteSelect({
    id: euicc,
    onTagsUpdate,
    enabled: ability.can(Actions.edit, Subjects.tags),
  });

  return (
    <EditDialog
      open={open}
      isLoading={isLoading}
      subtitle={`rSIM ${euicc}`}
      unsavedChanges={changed}
      onApplyChangesClick={async () => {
        if (ability.can(Actions.edit, Subjects.tags)) {
          await save();
        }

        onClose();
      }}
      onCancelClick={onClose}
      onClearChangesClick={async () => {
        await refresh();
        setChanges(false);
      }}
    >
      <Stack
        direction="row"
        gap={12}
        alignItems="center"
        sx={{ borderLeft: (theme) => `1px solid ${theme.palette.text.tertiary}` }}
      >
        <SimInventoryCan I={Actions.edit} a={Subjects.tags}>
          <Typography variant="text2Secondary" sx={{ ml: 4 }}>
            Tags
          </Typography>
          <Box flex={1}>
            <AutocompleteSelect
              errors={errors}
              values={assignedSimTags!}
              options={tags || []}
              onChange={(values) => {
                if (values.length <= 20) {
                  selectTags(values.map((value) => value.id));
                  setChanges(true);
                }
              }}
              onAdd={(newValues) => {
                addNewTags(newValues);
              }}
              inputValue={searchValue}
              onTextInputChange={(value) => {
                if (validateTagLength(value)) {
                  setSearchValue(value);
                }
              }}
              testId="tags-multiselect"
            />
          </Box>
        </SimInventoryCan>
      </Stack>
    </EditDialog>
  );
};
