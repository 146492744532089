import { useEffect, useRef } from 'react';

export const usePrevSearchValue = function <T>(value: T, refetching: boolean): T | undefined {
  const prevValue = useRef<T>();
  useEffect(() => {
    if (!refetching) {
      prevValue.current = value;
    }
  }, [refetching]);

  return prevValue.current;
};
