import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import React from 'react';

import { ContentCopyExtension } from 'common/ContentCopyExtension/ContentCopyExtension';
import {
  TwoValueCellProps,
  TwoValueCellLayoutProps,
  ValueBoxProps
} from './TwoValueCell.interface';

export const TwoValueCellLayout: React.FC<TwoValueCellLayoutProps> = ({
  sx,
  primaryContent,
  secondaryContent
}) => {
  return (
    <TableCell sx={{ verticalAlign: 'center', py: 2, ...sx, height: '52px' }}>
      <Box sx={{ whiteSpace: 'nowrap' }} data-testid="primaryText">
        {primaryContent}
      </Box>
      <Box sx={{ whiteSpace: 'nowrap' }} data-testid="secondaryText">
        {secondaryContent}
      </Box>
    </TableCell>
  );
};

export const ValueBox: React.FC<ValueBoxProps> = ({ text, variant, testId }) => {
  return (
    <ContentCopyExtension value={text}>
      <Typography data-testid={testId} variant={variant}>{text}</Typography>
    </ContentCopyExtension>
  );
};

export const TwoValueCell: React.FC<TwoValueCellProps> = React.memo(
  ({ primaryText = '', primaryVariant, secondaryText = '', secondaryVariant, sx }) => {
    return (
      <TwoValueCellLayout
        sx={sx}
        primaryContent={<ValueBox variant={primaryVariant} text={primaryText} />}
        secondaryContent={<ValueBox variant={secondaryVariant} text={secondaryText} />}
      />
    );
  }
);
