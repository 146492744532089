import { useEffect, useMemo, useState } from 'react';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';
import { FilterSection } from 'common/components/Filters/FilterSection';
import { FiltersDialog } from 'common/components/Filters/FiltersDialog';
import { FiltersButton } from 'common/components/Filters/FiltersButton';
import { FilterListItem } from 'common/components/Filters/FilterListItem';
import { SESSION_STATUS_NUMERIC } from 'simInventory/utils/sessionStatus';
import {
  filterArray,
  AutocompleteSelect,
  Option,
} from 'common/components/Inputs/AutocompleteSelect/AutocompleteSelect';
import { useQuery } from 'react-query';
import { convertStringToTags } from 'tags/utils';
import { useDebounce } from 'usehooks-ts';
import { fetchMnos } from '../SimInventoryApi/mnoApi';
import { ToggleButton } from '../../common/components/Inputs/ToggleGroup/ToggleButton';
import { ToggleGroup } from '../../common/components/Inputs/ToggleGroup/ToggleGroup';
import Box from '@mui/material/Box';
import {
  mapOptionsToString,
  TextFieldWithHints,
} from 'common/components/Inputs/TextFieldWithHints/TextFieldWithHints';
import { fetchAccounts } from '../../rSimInventory/Filters/services/accountsApi';
import { sessionStatusFilterSchema } from 'simInventory/models/SimProfile.model';
import { SimIdSearchInput } from 'simInventory/Components/Inputs/SimIdSearchInput';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';
import { IdRangeSearch } from 'common/components/Inputs/IdRangeSearch/IdRangeSearch';

import { FilterBox } from 'common/components/Filters/FilterBox';
import { fetchCustomFields } from '../../admin/customFields/api/customFieldsApi';
import {
  CustomField,
  CustomFieldSelectedOption,
} from '../../admin/customFields/entities/customFields.entity';
import { CustomFieldsFilter } from '../../common/components/Filters/CustomFieldsFilter/CustomFieldsFilter';
import {
  addDropdownValueToCustomFieldFilter,
  addTextValueToCustomFieldFilter,
} from '../../common/components/Filters/CustomFieldsFilter/customFields.utils';
import { INITIAL_FILTERS, useSimInventoryFiltersModal } from './hooks/useSimInventoryFiltersModal';
import { RadioSection } from 'common/components/Filters/IdFilterSelector/RadioSection';
import { useTagsWithHints } from '../../common/components/Filters/useTagWithHints';
import { useAbility } from 'permissions/hooks/useAbility';
import { Actions, Subjects } from 'permissions/ability';
import { SimInventoryCan } from 'permissions/PermissionProvider';

const MAX_ACCOUNTS_TO_FILTER = 10;

export const FiltersModal = () => {
  const [open, setOpen] = useState(false);
  const ability = useAbility();

  const customFieldsEnabled =
    useHasFeatureFlag('CustomFields') && ability.can(Actions.edit, Subjects.customFields);

  const { data: customFieldsData, refetch: refetchCustomFieldsQuery } = useQuery(
    ['customFields'],
    async () => {
      const fields = await fetchCustomFields();
      return fields.filter((f: CustomField) => f.enabled);
    },
    {
      suspense: false,
      enabled: false,
    },
  );

  const { setTempHints, removeHint, hints, applyHints, tagsData, refetchTags } = useTagsWithHints();

  const { filtersActive, apply, cancel, clear, modalFilters, setModalFilters } =
    useSimInventoryFiltersModal();

  const { t } = useTranslation();

  useEffect(() => {
    refetchTags();
    refetchMnoQuery();
    refetchAccountsQuery();
    if (customFieldsEnabled) {
      refetchCustomFieldsQuery();
    }
  }, [open]);

  const tags = useMemo(() => {
    return convertStringToTags(modalFilters.tags);
  }, [modalFilters.tags]);

  const [mnoFilter, setMnoFilter] = useState('');
  const [mnoChosen, setMnoChosen] = useState<Option[]>();

  const [initialOpen, setInitialOpen] = useState(true);

  const { data: mnoData, refetch: refetchMnoQuery } = useQuery(
    ['mno', mnoFilter],
    async () => {
      const mnos = await fetchMnos(mnoFilter);

      const mnoOptions = mnos.map((mno) => ({
        id: mno.ccii.toString(),
        name: mno.name,
        toAdd: false,
        showBothIdAndName: false,
      }));

      return mnoOptions;
    },
    {
      suspense: false,
      enabled: false,
    },
  );

  useEffect(() => {
    if (open && initialOpen) {
      setInitialOpen(false);
    }
  }, [open, initialOpen]);

  useEffect(() => {
    if (open && initialOpen) {
      const currentlySelected = convertStringToTags(modalFilters.mobileNetworkOperator);
      const mnoChosen = mnoData?.filter((a) => currentlySelected.includes(a.id));
      setMnoChosen(mnoChosen || []);
    }
  }, [open, initialOpen, modalFilters, mnoData]);

  const debounceMno = useDebounce(mnoFilter, 500);

  useEffect(() => {
    refetchMnoQuery();
  }, [debounceMno]);

  const mnos = useMemo(() => {
    return convertStringToTags(modalFilters.mobileNetworkOperator);
  }, [modalFilters.mobileNetworkOperator]);

  const [accountFilter, setAccountFilter] = useState('');
  const [accountsChosen, setAccountsChosen] = useState<Option[]>();

  const { data: accountsData, refetch: refetchAccountsQuery } = useQuery(
    ['accounts', accountFilter],
    async () => {
      const accounts = await fetchAccounts(accountFilter);

      const accountOptions = accounts.map((account) => ({
        id: account.accountRef.toString(),
        name: account.accountName,
        toAdd: false,
        showBothIdAndName: true,
      }));

      if (open && initialOpen) {
        const currentlySelected = convertStringToTags(modalFilters.accounts);
        const acc = accountOptions?.filter((a) => currentlySelected.includes(a.id));
        setAccountsChosen(acc || []);
      }

      return accountOptions;
    },
    {
      suspense: false,
      enabled: false,
    },
  );

  const { errors, warnings } = useMemo(() => {
    let errors: string[] = [];
    let warnings: string[] = [];
    if (accountsChosen && accountsChosen.length >= MAX_ACCOUNTS_TO_FILTER + 1) {
      errors = [t('common.limitReached')];
      warnings = [];
    } else if (accountsChosen && accountsChosen.length >= MAX_ACCOUNTS_TO_FILTER) {
      errors = [];
      warnings = [t('common.limitReached')];
    }

    return { errors, warnings };
  }, [accountsChosen]);

  const debounceAccount = useDebounce(accountFilter, 500);

  useEffect(() => {
    refetchAccountsQuery();
  }, [debounceAccount]);

  const accounts = useMemo(() => {
    return convertStringToTags(modalFilters.accounts);
  }, [modalFilters.accounts]);

  useEffect(() => {
    setAccountsChosen((prevState) => {
      return prevState?.filter((item) => accounts.includes(item.id));
    });
  }, [accounts]);

  const customFields = useMemo(() => {
    return modalFilters.customFields;
  }, [modalFilters.customFields]);

  const [selectedSearch, setSelectedSearch] = useState<'search' | 'iccidRange'>('search');
  useEffect(() => {
    if (selectedSearch === 'search') {
      setModalFilters((currentFilters) => ({
        ...currentFilters,
        iccidFrom: '',
        iccidTo: '',
      }));
    } else if (selectedSearch === 'iccidRange') {
      setModalFilters((currentFilters) => ({
        ...currentFilters,
        searchQuery: '',
        idType: INITIAL_FILTERS.idType,
      }));
    }
  }, [selectedSearch]);

  const customFieldsAvailable =
    customFieldsEnabled && customFieldsData && customFieldsData.length > 0;

  return (
    <>
      <FiltersButton onClick={() => setOpen(true)} active={filtersActive} />
      <FiltersDialog
        open={open}
        onApply={() => {
          applyHints();
          apply();
          setOpen(false);
        }}
        onCancel={() => {
          cancel();
          setOpen(false);
        }}
        onClear={clear}
        onClose={() => {
          cancel();
          setOpen(false);
        }}
        oneColumn={!customFieldsAvailable}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
          {
            <FilterSection title={t('common.idSearch')}>
              <FilterBox>
                <RadioSection
                  options={['search', 'iccidRange']}
                  value={selectedSearch}
                  onValueChange={(newValue) =>
                    setSelectedSearch(newValue as 'search' | 'iccidRange')
                  }
                  labels={{
                    search: 'Search',
                    iccidRange: 'ICCID Range',
                  }}
                >
                  {(value) => {
                    return (
                      <Box>
                        <Box
                          data-selector="search"
                          sx={{ opacity: value === 'search' ? 1 : 0 }}
                          aria-hidden={value !== 'search'}
                        >
                          <SimIdSearchInput
                            chosenId={modalFilters.idType}
                            onChosenIdChange={(idType) =>
                              setModalFilters((prevFilters) => ({
                                ...prevFilters,
                                idType,
                              }))
                            }
                            search={modalFilters.searchQuery || ''}
                            onSearchChange={(searchQuery) => {
                              setModalFilters((prevFilters) => ({
                                ...prevFilters,
                                searchQuery,
                              }));
                            }}
                          />
                        </Box>
                        <Box
                          data-selector="iccidRange"
                          sx={{ opacity: value === 'iccidRange' ? 1 : 0 }}
                          aria-hidden={value !== 'iccidRange'}
                        >
                          <IdRangeSearch
                            data-testid="iccid range search"
                            from={modalFilters.iccidFrom}
                            to={modalFilters.iccidTo}
                            onChange={(from: string, to: string) => {
                              setModalFilters((prevFilters) => ({
                                ...prevFilters,
                                iccidFrom: from,
                                iccidTo: to,
                              }));
                            }}
                          />
                        </Box>
                      </Box>
                    );
                  }}
                </RadioSection>
              </FilterBox>
            </FilterSection>
          }
          <FilterSection title={t('simInventory.sim')}>
            <List>
              <FilterListItem label={t('simInventory.sessionStatus')} htmlFor="select status">
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <ToggleGroup
                    sx={{
                      width: '100%',
                      justifyContent: 'stretch',
                    }}
                    value={modalFilters.sessionStatus}
                    onChange={(event, value) => {
                      try {
                        const sessionStatus = sessionStatusFilterSchema.parse(value);
                        setModalFilters((prevFilters) => ({
                          ...prevFilters,
                          sessionStatus: sessionStatus,
                        }));
                      } catch (err) {
                        setModalFilters((prevFilters) => ({
                          ...prevFilters,
                          sessionStatus: '',
                        }));
                      }
                    }}
                    data-testid="Select status"
                    aria-label="Select status"
                  >
                    <ToggleButton
                      sx={{ flex: 1 }}
                      value={SESSION_STATUS_NUMERIC.IN_SESSION}
                      data-testid={'select-status-inSession'}
                    >
                      {t('common.inSession')}
                    </ToggleButton>
                    <ToggleButton sx={{ flex: 1 }} value="" data-testid={'select-status-any'}>
                      Any
                    </ToggleButton>
                    <ToggleButton
                      sx={{ flex: 1 }}
                      value={SESSION_STATUS_NUMERIC.DISCONNECTED}
                      data-testid={'session-status-outSession'}
                    >
                      {t('common.outSession')}
                    </ToggleButton>
                  </ToggleGroup>
                </Box>
              </FilterListItem>
              <SimInventoryCan I={Actions.filter} a={Subjects.internalData}>
                <FilterListItem label="Account" htmlFor="account">
                  <AutocompleteSelect
                    testId="accounts-multiselect"
                    hints={hints?.accounts}
                    errors={errors}
                    warnings={warnings}
                    values={accounts}
                    options={filterArray([...(accountsData || []), ...(accountsChosen || [])])}
                    onChange={(values) => {
                      setModalFilters((prevFilters) => ({
                        ...prevFilters,
                        accounts: values.map((value) => value.id).join(','),
                      }));
                      setTempHints((prevHints) => ({ ...prevHints, accounts: values }));
                      setAccountsChosen(values);
                    }}
                    onRemoveHint={(hint: any) => removeHint(hint, 'accounts')}
                    allowAdding={false}
                    inputValue={accountFilter}
                    onTextInputChange={(val) => setAccountFilter(val)}
                    allowFilteringById={true}
                    placeholder={modalFilters.accounts.length > 0 ? '' : t('simInventory.accountFilterPlaceholder')}
                  />
                </FilterListItem>
              </SimInventoryCan>
              <FilterListItem
                label={t('simInventory.mobileNetworkOperator')}
                htmlFor="mobileNetworkOperator"
              >
                <AutocompleteSelect
                  testId="mno-multiselect"
                  hints={hints?.mobileNetworkOperator}
                  values={mnos}
                  options={filterArray([...(mnoData || []), ...(mnoChosen || [])])}
                  onChange={(values) => {
                    setModalFilters((prevFilters) => ({
                      ...prevFilters,
                      mobileNetworkOperator: values.map((value) => value.id).join(','),
                    }));
                    setTempHints((prevHints) => ({ ...prevHints, mobileNetworkOperator: values }));
                    setMnoChosen(values);
                  }}
                  allowAdding={false}
                  inputValue={mnoFilter}
                  onTextInputChange={(val) => setMnoFilter(val)}
                  allowFilteringById={false}
                  onRemoveHint={(hint: any) => removeHint(hint, 'mobileNetworkOperator')}
                  placeholder={modalFilters.mobileNetworkOperator.length > 0 ? '' : t('simInventory.mnoFilterPlaceholder')}
                />
              </FilterListItem>
              <FilterListItem label={t('common.label')} htmlFor="label">
                <TextFieldWithHints
                  testId="Label text field"
                  hints={hints?.label ? mapOptionsToString(hints.label) : []}
                  value={modalFilters.label}
                  onChange={(value) => {
                    setModalFilters((prevState) => ({
                      ...prevState,
                      label: value || '',
                    }));
                    setTempHints((prevHints) => ({ ...prevHints, label: [{ id: value || '' }] }));
                  }}
                  onRemoveHint={(hint: string) => removeHint({ id: hint }, 'label')}
                  placeholder={t('simInventory.labelFilterPlaceholder')}
                />
              </FilterListItem>
              <FilterListItem label={'Tag'} htmlFor="tag">
                <AutocompleteSelect
                  testId="tags-multiselect"
                  hints={hints?.tags}
                  values={tags}
                  options={tagsData || []}
                  onChange={(values) => {
                    setModalFilters((prevFilters) => ({
                      ...prevFilters,
                      tags: values.map((value) => value.id).join(','),
                    }));
                    setTempHints((prevHints) => ({ ...prevHints, tags: values }));
                  }}
                  allowAdding={false}
                  onRemoveHint={(hint: any) => removeHint(hint, 'tags')}
                  placeholder={modalFilters.tags.length > 0 ? '' : t('simInventory.tagsFilterPlaceholder')}
                />
              </FilterListItem>
            </List>
          </FilterSection>
          <FilterSection title={t('common.order')}>
            <List>
              <FilterListItem label={t('common.connectionId')} htmlFor="connectionId">
                <TextFieldWithHints
                  testId="connectionId text field"
                  hints={hints?.connectionId ? mapOptionsToString(hints.connectionId) : []}
                  value={modalFilters.connectionId}
                  onChange={(value) => {
                    setModalFilters((prevState) => ({
                      ...prevState,
                      connectionId: value || '',
                    }));
                    setTempHints((prevHints) => ({
                      ...prevHints,
                      connectionId: [{ id: value || '' }],
                    }));
                  }}
                  onRemoveHint={(hint: string) => removeHint({ id: hint }, 'connectionId')}
                  placeholder={t('simInventory.connectionIdFilterPlaceholder')}
                />
              </FilterListItem>
              <FilterListItem label={t('simInventory.orderNumber')} htmlFor="orderNumber">
                <TextFieldWithHints
                  testId="orderNumber text field"
                  hints={hints?.orderNumber ? mapOptionsToString(hints.orderNumber) : []}
                  value={modalFilters.orderNumber}
                  onChange={(value) => {
                    setModalFilters((prevState) => ({
                      ...prevState,
                      orderNumber: value || '',
                    }));
                    setTempHints((prevHints) => ({
                      ...prevHints,
                      orderNumber: [{ id: value || '' }],
                    }));
                  }}
                  onRemoveHint={(hint: string) => removeHint({ id: hint }, 'orderNumber')}
                  placeholder={t('simInventory.orderNumberFilterPlaceholder')}
                />
              </FilterListItem>
            </List>
          </FilterSection>
        </Box>
        {customFieldsAvailable && (
          <FilterSection title={t('customFields.customFields')}>
            <List>
              {customFieldsData?.map((field: CustomField) => {
                return (
                  <FilterListItem key={field.id} label={field.label} htmlFor={field.id.toString()}>
                    <CustomFieldsFilter
                      field={field}
                      currentFilter={customFields}
                      onTextChange={(value) => {
                        setModalFilters((prevFilters) => {
                          return {
                            ...prevFilters,
                            customFields: addTextValueToCustomFieldFilter(
                              prevFilters.customFields,
                              field.id.toString(),
                              value,
                            ),
                          };
                        });

                        setTempHints((prevHints) => ({
                          ...prevHints,
                          [field.id.toString()]: [{ id: value || '' }],
                        }));
                      }}
                      onDropdownChange={(values) => {
                        setModalFilters((prevFilters) => {
                          return {
                            ...prevFilters,
                            customFields: addDropdownValueToCustomFieldFilter(
                              prevFilters.customFields,
                              field.id.toString(),
                              values.map((value) => value.id),
                            ),
                          };
                        });

                        const mappedHints = values.map((v) => {
                          const option = field.selectionOptions?.find(
                            (option: CustomFieldSelectedOption) =>
                              option.id?.toString() === v.id.toString(),
                          );
                          return { id: v.id.toString(), name: option?.label || '' };
                        });

                        setTempHints((prevHints) => ({
                          ...prevHints,
                          [field.id.toString()]: mappedHints,
                        }));
                      }}
                      hints={hints}
                      onRemoveHint={(id) => removeHint({ id }, field.id.toString())}
                    />
                  </FilterListItem>
                );
              })}
            </List>
          </FilterSection>
        )}
      </FiltersDialog>
    </>
  );
};
