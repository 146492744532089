import React, { useEffect, useState } from 'react';
import { isArray } from 'lodash';
import {
  Option,
  SelectWithSearch,
} from '../common/components/Inputs/SelectWithSearch/SelectWithSearch';
import { useFieldsAuditTrail, useUsersAuditTrail } from './hooks/useAuditTrail';
import {
  FilterBox,
  TableWithFiltersLayout,
} from '../common/components/AppLayout/TableWithFiltersLayout';
import Box from '@mui/material/Box';
import { MultiSelect } from '../common/components/Inputs/MultiSelect/MultiSelect';
import { RefetchQueryButton } from '../common/components/Queries/RefetchQueryButton';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Stack from '@mui/material/Stack';
import { AutoHideSnackbar } from '../common/Snackbar/AutoHideSnackbar';
import { AlertTitle } from '../common/Snackbar/AlertTitle';
import Typography from '@mui/material/Typography';
import { SuspenseLoadingView } from '../common/components/SuspenseLoadingView';
import { ContentBox } from '../common/components/AppLayout/ContentBox';
import { TableSkeleton } from '../common/components/Table/TableSkeleton';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { downloadAuditTrail } from './auditTrailApi/auditTrailApi';
import { AuthError } from '../auth/utils';
import { AuditTrailTable } from './components/AuditTrailTable';
import {
  DateRangePickerMui,
  DateRangeValuePiece,
} from '../common/components/Inputs/DateRangePickerMui/DateRangePickerMui';
import { SimInventoryCan } from 'permissions/PermissionProvider';
import { Actions, Subjects } from 'permissions/ability';
import { endOfDay, isValid } from 'date-fns';
import { basicErrorHandling } from '../common/components/Inputs/DateRangePickerMui/DateRangePickerMui.util';

type ExportError = {
  title: string;
  description: string;
};

export const AuditTrail = () => {
  const { t } = useTranslation();
  const { iccid } = useParams();

  const [dateRange, setDateRange] = useState<[DateRangeValuePiece, DateRangeValuePiece]>([
    null,
    null,
  ]);

  const [selectedUsername, setSelectedUsername] = useState<Option | null>(null);
  const [selectedField, setSelectedField] = useState<string[]>([]);

  const { users: usersAuditTrail = [] } = useUsersAuditTrail(undefined, iccid);
  const { fields = [] } = useFieldsAuditTrail();

  const fieldsOptions = fields?.map((field: string) => ({
    label: field,
    value: field,
  }));

  const usernameOptions = usersAuditTrail
    ?.filter((user: string) => !!user)
    .map((user: string, index: number) => ({
      label: user,
      id: index,
    }));

  const { refetch: exportAuditTrail, isFetching: isExporting } = useQuery(
    ['AuditTrail', 'download'],
    async () => {
      await downloadAuditTrail(
        iccid!,
        selectedUsername?.label as string,
        dateRange && dateRange[0] ? new Date(dateRange[0]).toISOString() : null,
        dateRange && dateRange[1] ? new Date(endOfDay(dateRange[1])).toISOString() : null,
        selectedField,
      );
    },
    {
      retry: 0,
      enabled: false,
      suspense: false,
      onError: (error) => {
        if (error instanceof AuthError) {
          throw error;
        } else {
          setExportError({
            title: t('common.exportFailed'),
            // @ts-ignore
            description: error.message,
          });
        }
      },
    },
  );

  const [exportError, setExportError] = useState<ExportError | null>(null);

  const [downloadingSnackbarOpened, setDownloadingSnackbarOpened] = useState(false);
  useEffect(() => {
    if (isExporting) {
      setDownloadingSnackbarOpened(true);
    }
  }, [isExporting]);

  return (
    <TableWithFiltersLayout
      filters={
        <FilterBox>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box data-testid={'auditTrailsFilters'} display={'flex'} alignItems={'center'}>
              <DateRangePickerMui
                disableFuture={true}
                onChange={(value) => {
                  //@ts-ignore
                  if (value && isArray(value) && value[0] && value[1]) {
                    if (!isValid(value[0]) || !isValid(value[1])) {
                      return;
                    }
                    setDateRange([value[0], endOfDay(value[1])]);
                  } else {
                    setDateRange([null, null]);
                  }
                }}
                value={dateRange}
                allowClearing={true}
                onError={(err) => {
                  setDateRange((prevDateRange) => {
                    if (
                      (prevDateRange[0] && err[0] === null) ||
                      (prevDateRange[1] && err[1] === null)
                    ) {
                      const range = basicErrorHandling(prevDateRange, err);
                      //@ts-ignore
                      return [range[0], endOfDay(range[1])];
                    }
                    return prevDateRange;
                  });
                }}
              />
              <MultiSelect
                data-testid={'field-multiselect'}
                showSelectAll={true}
                options={fieldsOptions || []}
                value={selectedField}
                onChange={(val) => {
                  setSelectedField(val);
                }}
                placeholder={t('common.field')}
                sx={{ width: '170px', ml: 4 }}
              />
              <SelectWithSearch
                data-testid={`username-select`}
                placeholder={t('simInventory.userName')}
                options={usernameOptions || []}
                value={selectedUsername ?? null}
                onChange={(value) => setSelectedUsername(value)}
                sx={{ width: '170px', ml: 4 }}
              />
            </Box>
            <Box data-testid={'auditTrailsActionButtons'}>
              <Box component="span">
                <RefetchQueryButton queryKey={'AuditTrail'} />
              </Box>
              <SimInventoryCan I={Actions.export} a={Subjects.auditTrails}>
                <Button
                  startIcon={<FileDownloadIcon />}
                  disabled={isExporting}
                  color="secondary"
                  onClick={() => exportAuditTrail()}
                >
                  {t('common.export')}
                </Button>
              </SimInventoryCan>
            </Box>
          </Box>
          <Stack>
            <AutoHideSnackbar
              severity="success"
              open={downloadingSnackbarOpened}
              onClose={() => {
                setDownloadingSnackbarOpened(false);
              }}
            >
              {''} {/* Without this component throw a error and crash app */}
              <AlertTitle> {t('common.downloading')}!</AlertTitle>
            </AutoHideSnackbar>
            <AutoHideSnackbar
              open={!!exportError}
              severity="error"
              onClose={() => setExportError(null)}
            >
              {''} {/* Without this component throw a error and crash app */}
              <AlertTitle>{exportError?.title || ''}!</AlertTitle>
              <Typography variant="text1White">{exportError?.description}</Typography>
            </AutoHideSnackbar>
          </Stack>
        </FilterBox>
      }
    >
      <SuspenseLoadingView
        loadFallback={
          <ContentBox>
            <TableSkeleton showStartSkeleton showEndSkeleton numberOfRows={20} />
          </ContentBox>
        }
        queryKey={['AuditTrail']}
      >
        <AuditTrailTable
          iccid={iccid!}
          dateRange={dateRange}
          selectedField={selectedField}
          selectedUsername={selectedUsername}
        />
      </SuspenseLoadingView>
    </TableWithFiltersLayout>
  );
};
