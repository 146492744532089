import { useLocation, useSearchParams } from 'react-router-dom';

import {
  FilterSimCardIdentifier,
  LAST_ACTIVE_PROFILE_FILTER,
  LAST_UPDATE_TIME_FILTERS,
  LastActiveProfileFilter,
  LastUpdateTimeFilters,
  RSIM_INITIAL_FILTERS,
  RSimFilters,
  SIMULATE_OUTAGE_FILTER,
  SimulateOutageFilter,
  ValidRSimFiltersKeys,
} from '../data/filters.constants';
import { useContext, useEffect, useState } from 'react';
import { usePassBackState } from 'common/hooks/usePassBackState';
import { LocationStoreContext } from 'common/components/LocationStorage/LocationStateStoreProvider';
export const useRSimFiltersParams = () => {
  const stateBack = usePassBackState();
  const { saveCurrentLocationState } = useContext(LocationStoreContext);
  const location = useLocation();

  const [searchQuery, setSearchQuery] = useState(
    stateBack?.searchText?.at(-1) || RSIM_INITIAL_FILTERS.searchText,
  );

  useEffect(() => {
    saveCurrentLocationState({ searchText: [searchQuery] });
  }, [location.key]);

  const [filters, setFilters] = useSearchParams(RSIM_INITIAL_FILTERS);

  const updateAll = (filters: RSimFilters) => {
    const { searchText, ...otherFilters } = filters;
    setSearchQuery(searchText);
    setFilters({ searchText: '', ...otherFilters }, { replace: true });
  };

  const updateOne = (name: ValidRSimFiltersKeys, value: string) => {
    if (name === 'searchText') {
      setSearchQuery(value);
    } else {
      setFilters({ ...Object.fromEntries(filters.entries()), [name]: value });
    }
  };

  const clearAll = () => {
    setFilters(RSIM_INITIAL_FILTERS);
  };

  const clearOne = (name: ValidRSimFiltersKeys) => {
    updateOne(name, RSIM_INITIAL_FILTERS[name]);
  };

  const searchText = searchQuery;

  const chosenIdType = (filters.get('chosenIdType') ||
    RSIM_INITIAL_FILTERS.chosenIdType) as FilterSimCardIdentifier;

  const lastActiveProfile = (filters.get('lastActiveProfile') ||
    LAST_ACTIVE_PROFILE_FILTER.ANY) as LastActiveProfileFilter;

  const lastUpdateTime = (filters.get('lastUpdateTime') ||
    LAST_UPDATE_TIME_FILTERS.NONE) as LastUpdateTimeFilters;

  const tags = filters.get('tags') || '';

  const connectionId = filters.get('connectionId') || '';
  const orderNumber = filters.get('orderNumber') || '';

  const simulateOutage = (filters.get('simulateOutage') ||
    SIMULATE_OUTAGE_FILTER.ANY) as SimulateOutageFilter;

  const accounts = filters.get('accounts') || '';

  const eidFrom = filters.get('eidFrom') || '';
  const eidTo = filters.get('eidTo') || '';

  return {
    filters: {
      searchText,
      chosenIdType,
      lastActiveProfile,
      lastUpdateTime,
      tags,
      connectionId,
      orderNumber,
      simulateOutage,
      accounts,
      eidFrom,
      eidTo,
    },
    updateAll,
    updateOne,
    clearAll,
    clearOne,
  };
};
