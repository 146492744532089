import { useEffect } from 'react';
import { useMounted } from './useMounted';
import { getUpdatedUrlSearchParams } from './utils/urlSearchParamsUtils';
import { useSearchParams } from 'react-router-dom';

export const usePageReset = (deps: React.DependencyList) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isMounted = useMounted();
  useEffect(() => {
    const page = 0;
    if (isMounted) {
      setSearchParams(getUpdatedUrlSearchParams('page', page.toString(), searchParams), {
        replace: true
      });
    }
  }, [...deps]);
};
