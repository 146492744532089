import { useQuery, UseQueryResult } from 'react-query';
import { usePaginationWithUrlParams } from './usePaginationWithUrlParams';
import { usePageReset } from './usePageReset';

interface IPagination {
  page: number;
  rowsPerPage: number;
  setPage: (page: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  totalNumberOfItems: number;
}

type PaginatedQueryContext = {
  signal?: AbortSignal;
  page: number;
  rowsPerPage: number;
};

type PaginationQueryResult = {
  totalNumberOfItems: number;
};

type DataQuery = (paginatedQueryContext: PaginatedQueryContext) => void;
type PaginationQuery = (
  paginatedQueryContext: PaginatedQueryContext,
) => Promise<PaginationQueryResult>;

type AdditionalOptions = {
  refetchOnWindowFocus?: boolean;
};

export const useLazyPaginationQuery = <TQueryResults = unknown, TQueryKeys = unknown>(
  queryKey: TQueryKeys[],
  dataQueryFn: DataQuery,
  paginationQuery: PaginationQuery,
  options: AdditionalOptions = {
    refetchOnWindowFocus: true,
  },
): {
  pagination: IPagination;
  queryResult: UseQueryResult<TQueryResults>;
  queryKey: TQueryKeys[];
} => {
  const { refetchOnWindowFocus } = options;
  const { page, setPage, rowsPerPage, setRowsPerPage } = usePaginationWithUrlParams();

  const simInventoryQueryKey = [...queryKey, page, rowsPerPage] as TQueryKeys[];
  const queryResult = useQuery({
    queryKey: simInventoryQueryKey,
    queryFn: ({ signal }) => dataQueryFn({ page, rowsPerPage, signal }),
    keepPreviousData: true,
    refetchOnWindowFocus: refetchOnWindowFocus,
  });

  const { status, data: paginationData } = useQuery(
    [...queryKey, 'Pagination'],
    ({ signal }) => paginationQuery({ page, rowsPerPage, signal }),
    {
      suspense: false,
    },
  );

  usePageReset([rowsPerPage, ...queryKey]);

  return {
    pagination: {
      page,
      rowsPerPage,
      setPage,
      setRowsPerPage,
      totalNumberOfItems: status === 'success' ? paginationData?.totalNumberOfItems : -1,
    },
    queryResult: queryResult as UseQueryResult<TQueryResults>,
    queryKey: simInventoryQueryKey,
  };
};
