import { SxProps } from '@mui/system';
import { typoColor } from '../../../theme/palette';

export const tooltipSx: SxProps = {
  px: 2,
  pt: 1,
  pb: 0.75,
  m: 0,
  boxShadow: ' 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 0px 5px rgba(0, 0, 0, 0.08)',
  borderRadius: '12px'
};

export const darkTooltipSx: SxProps = {
  backgroundColor: typoColor.secondary,
  opacity: 0.9,
  '& .MuiTooltip-arrow::before': { backgroundColor: typoColor.secondary }
};
