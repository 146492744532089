import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AutocompleteSelect } from 'common/components/Inputs/AutocompleteSelect/AutocompleteSelect';
import { SimInventoryCan } from 'permissions/PermissionProvider';

import React from 'react';
import { EditDialog } from 'common/components/Dialog/EditDialog';
import { useTagsAutocompleteSelect } from 'tags/hooks/useTagsAutocompleteSelect';
import {
  CustomField,
  CustomFieldSelectedOption,
  FIELD_TYPE,
} from '../../admin/customFields/entities/customFields.entity';
import { useSimDetails } from '../hooks/useSimDetails';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material';
import { useCustomFields } from '../../admin/customFields/hooks/useCustomFields';
import {
  addAssignedValue,
  getAssignedOption,
  mapAssignedCustomFieldsToCustomField,
} from 'common/components/Filters/CustomFieldsFilter/customFields.utils';
import { useRefetchQuery } from 'common/useRefetchQuery';
import { useSimCustomFields } from '../hooks/useSimCustomFields';
import { Actions, Subjects } from 'permissions/ability';
import { SelectWithSearch } from '../../common/components/Inputs/SelectWithSearch/SelectWithSearch';
import { TextfieldWithCounter } from '../../common/components/Inputs/TextfieldWithCounter/TextfieldWithCounter';
import { useAbility } from 'permissions/hooks/useAbility';

export type SimInventoryEditDialogProps = {
  open: boolean;
  iccid: string;
  onTagsUpdate?: (iccid: string, tags: any) => void;
  onClose: () => void;
  queryKey?: string;
};

const GridLabelStyled = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
});

const TypographyStyled = styled(Typography)({
  marginLeft: '16px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const SimInventoryEditDialog = React.memo(
  ({ open, iccid, onClose, onTagsUpdate, queryKey }: SimInventoryEditDialogProps) => {
    const ability = useAbility();

    const {
      isLoading,
      tags,
      assignedSimTags,

      addNewTags,
      selectTags,
      save: saveTags,
      refresh,

      changed,
      setChanges,
      searchValue,
      setSearchValue,
      errors,
      validateTagLength,
    } = useTagsAutocompleteSelect({
      id: iccid,
      onTagsUpdate,
      enabled: ability.can(Actions.edit, Subjects.tags),
    });

    const { data: simData, refetch: refetchSimData } = useSimDetails(iccid!);

    const {
      customFieldsData,
      assignedCustomFields,
      originalCustomFields,
      setAssignedCustomFields,
      clearCustomFields,
    } = useSimCustomFields(simData?.customFields || []);

    const { editSimCustomFields } = useCustomFields();

    const refetchByQuery = useRefetchQuery(queryKey || '');

    const refetch = () => {
      if (queryKey) {
        refetchByQuery();
      }
      refetchSimData();
    };

    return (
      <EditDialog
        isLoading={isLoading}
        subtitle={`SIM ${iccid}`}
        unsavedChanges={changed}
        onApplyChangesClick={async () => {
          if (ability.can(Actions.edit, Subjects.customFields)) {
            await editSimCustomFields(
              {
                iccid,
                customFields: mapAssignedCustomFieldsToCustomField(
                  assignedCustomFields,
                  customFieldsData,
                  originalCustomFields,
                ),
              },
              {
                onSuccess: () => {
                  refetch();
                },
              },
            );
          }

          if (ability.can(Actions.edit, Subjects.tags)) {
            await saveTags();
            refetch();
          }

          onClose();
        }}
        onCancelClick={onClose}
        onClearChangesClick={() => {
          refresh();
          clearCustomFields();
          setChanges(false);
        }}
        open={open}
      >
        <Stack
          direction="row"
          gap={12}
          alignItems="center"
          sx={{ borderLeft: (theme) => `1px solid ${theme.palette.text.tertiary}` }}
        >
          <Grid container columnSpacing={10} rowSpacing={4}>
            <SimInventoryCan I={Actions.edit} a={Subjects.tags}>
              <GridLabelStyled item xs={4}>
                <Typography variant="text2Secondary" sx={{ ml: 4 }}>
                  Tags
                </Typography>
              </GridLabelStyled>
              <Grid item xs={8}>
                <AutocompleteSelect
                  errors={errors}
                  values={assignedSimTags!}
                  options={tags || []}
                  onChange={(values) => {
                    if (values.length <= 20) {
                      selectTags(values.map((value) => value.id));
                      setChanges(true);
                    }
                  }}
                  onAdd={(newValues) => {
                    addNewTags(newValues);
                  }}
                  inputValue={searchValue}
                  onTextInputChange={(value) => {
                    if (validateTagLength(value)) {
                      setSearchValue(value);
                    }
                  }}
                  testId="tags-multiselect"
                />
              </Grid>
            </SimInventoryCan>
            <SimInventoryCan I={Actions.edit} a={Subjects.customFields}>
              {customFieldsData?.map((field: CustomField) => {
                const index = assignedCustomFields.findIndex((f) => f.label === field.label);
                const assignedOption: CustomFieldSelectedOption | null = getAssignedOption(
                  index,
                  field.selectionOptions || [],
                  assignedCustomFields,
                );

                return (
                  <React.Fragment key={field.id}>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: 'flex',
                        alignItems: field.dataType === FIELD_TYPE.Text ? 'flex-start' : 'center',
                        ...(field.dataType === FIELD_TYPE.Text
                          ? { paddingTop: '28px !important' }
                          : {}),
                      }}
                    >
                      <TypographyStyled variant="text2Secondary">{field.label}</TypographyStyled>
                    </Grid>
                    <Grid item xs={8}>
                      <>
                        {field.dataType === FIELD_TYPE.Text && (
                          <TextfieldWithCounter
                            data-testid={`customField-${field.label}`}
                            name={`customField-text-${field.id}`}
                            value={assignedCustomFields[index]?.value}
                            onChange={(e) => {
                              setAssignedCustomFields((prev) =>
                                addAssignedValue(index, e.target.value, field, prev),
                              );
                              setChanges(true);
                            }}
                            maxLength={255}
                            showCounter={true}
                          />
                        )}
                        {field.dataType === FIELD_TYPE.Dropdown && (
                          <SelectWithSearch
                            data-testid={`customField-${field.label}`}
                            value={
                              assignedOption
                                ? { id: assignedOption.id!, label: assignedOption.label }
                                : null
                            }
                            onChange={(value) => {
                              setAssignedCustomFields((prev) =>
                                addAssignedValue(
                                  index,
                                  value ? value.id.toString() : null,
                                  field,
                                  prev,
                                ),
                              );
                              setChanges(true);
                            }}
                            options={field.selectionOptions.map((option) => ({
                              id: option.id!,
                              label: option.label,
                            }))}
                          />
                        )}
                      </>
                    </Grid>
                  </React.Fragment>
                );
              })}
            </SimInventoryCan>
          </Grid>
        </Stack>
      </EditDialog>
    );
  },
);
