import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { InputWrapperProps } from '../input.interface';
import { styled } from '@mui/material';

const Root = styled(FormControl, {
  name: 'InputWrapper',
  slot: 'Root',
})({});

const Label = styled(InputLabel, {
  name: 'InputWrapper',
  slot: 'Label',
})(({ theme }) => ({
  backgroundColor: theme.palette.tertiary.main,
  transform: 'translate(14px, -8px)',
  padding: '0 4px',
  fontSize: '12px',
  textTransform: 'capitalize',
}));

export const InputWrapper: React.FC<InputWrapperProps> = ({
  label,
  children,
  fullWidth = true,
  className,
  sx,
}) => (
  <Root fullWidth={fullWidth} sx={sx} className={className}>
    {label && <Label shrink>{label}</Label>}
    {children}
  </Root>
);
