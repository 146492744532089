import { FilterChipProps, FiltersChips } from 'common/components/Filters/FiltersChips';
import {
  LAST_ACTIVE_PROFILE_FILTER,
  LAST_UPDATE_TIME_FILTERS,
  LastActiveProfileFilter,
  LastUpdateTime,
  RSimFilters,
  SIMULATE_OUTAGE_FILTER,
  SimulateOutageFilter,
} from './data/filters.constants';
import { mapLastUpdateTimeToLabels } from './utils/lastUpdateTimeMappers';
import {
  mapLastActiveProfileFilterTLabel,
  mapSimulateOutageFilterToValue,
} from './utils/lastActiveProfileFilterMappers';
import React, { useMemo } from 'react';
import { TagFilterChip } from 'common/components/FilterChip/TagFilterChip';
import { convertStringToTags } from 'tags/utils';
import { useTranslation } from 'react-i18next';
import { AccountFilterChip } from '../../common/components/FilterChip/AccountFilterChip';

type RSimChipsFilters = Omit<RSimFilters, 'searchText' | 'chosenIdType'>;

type RSimFiltersChipsProps = Pick<FilterChipProps, 'onFilterClear' | 'onFiltersClear'> & {
  filters: RSimChipsFilters;
  onTagRemoved: (id: string) => void;
  onAccountRemoved: (id: string) => void;
};

export const RSimFiltersChips: React.FC<RSimFiltersChipsProps> = ({
  filters,
  onFilterClear,
  onFiltersClear,
  onTagRemoved,
  onAccountRemoved,
}) => {
  const { t } = useTranslation();
  const createValuesForChips = (
    filters: Omit<RSimChipsFilters, 'tags' | 'accounts' | 'eidFrom' | 'eidTo'>,
  ) => {
    const filtersNames = {
      lastActiveProfile: 'Last active profile',
      lastUpdateTime: 'No update for',
      orderNumber: t('common.orderNumber'),
      connectionId: t('common.connectionId'),
      simulateOutage: t('common.simulateOutage'),
      eidRange: '',
    };

    const chipsValues: Partial<Record<keyof typeof filtersNames, string>> = {};

    Object.entries(filters).forEach(([key, value]) => {
      if (
        (key === 'lastUpdateTime' && value === LAST_UPDATE_TIME_FILTERS.NONE) ||
        (key === 'lastActiveProfile' && value === LAST_ACTIVE_PROFILE_FILTER.ANY) ||
        (key === 'simulateOutage' && value === SIMULATE_OUTAGE_FILTER.ANY)
      ) {
        return;
      }

      if (key === 'lastUpdateTime' && value !== LAST_UPDATE_TIME_FILTERS.NONE) {
        const valueText = mapLastUpdateTimeToLabels(value as LastUpdateTime);
        chipsValues[key as keyof typeof filtersNames] = `${
          filtersNames[key as keyof typeof filtersNames]
        }: ${valueText}`;
      } else if (key === 'lastActiveProfile' && value !== LAST_ACTIVE_PROFILE_FILTER.ANY) {
        const valueText = mapLastActiveProfileFilterTLabel(value as LastActiveProfileFilter);
        chipsValues[key as keyof typeof filtersNames] = `${
          filtersNames[key as keyof typeof filtersNames]
        }: ${valueText}`;
      } else if (key === 'simulateOutage' && value !== SIMULATE_OUTAGE_FILTER.ANY) {
        const valueText = mapSimulateOutageFilterToValue(value as SimulateOutageFilter);
        chipsValues[key as keyof typeof filtersNames] = `${
          filtersNames[key as keyof typeof filtersNames]
        }: ${valueText}`;
      } else if (value !== '') {
        chipsValues[key as keyof typeof filtersNames] = `${
          filtersNames[key as keyof typeof filtersNames]
        }: ${value}`;
      }
    });

    return chipsValues;
  };

  const { tags, accounts, eidFrom, eidTo, ...otherFilters } = filters;
  const filtersChips = createValuesForChips(otherFilters);

  if (eidFrom !== '' || eidTo !== '') {
    filtersChips.eidRange = `EID`;
    if (eidFrom) {
      filtersChips.eidRange += ` ${t('common.from')}: ${eidFrom}`;
    }

    if (eidTo) {
      filtersChips.eidRange += ` ${t('common.to')}: ${eidTo}`;
    }
  }

  const parsedTags = useMemo(() => {
    if (!tags) {
      return [];
    }
    return convertStringToTags(tags) || [];
  }, [tags]);

  const parsedAccounts = useMemo(() => {
    if (!accounts) {
      return [];
    }
    return convertStringToTags(accounts) || [];
  }, [accounts]);

  const parsedChips = [
    ...parsedTags.map((t) => ({ item: t, type: 'tag' })),
    ...parsedAccounts.map((a) => ({ item: a, type: 'account' })),
  ];

  return (
    <FiltersChips
      externalChips={
        parsedChips.length
          ? parsedChips.map((i) => (
              <React.Fragment key={i.item}>
                {i.type === 'tag' && <TagFilterChip id={i.item} onCancel={onTagRemoved} />}
                {i.type === 'account' && (
                  <AccountFilterChip id={i.item} onCancel={onAccountRemoved} />
                )}
              </React.Fragment>
            ))
          : undefined
      }
      filters={filtersChips}
      onFilterClear={onFilterClear}
      onFiltersClear={onFiltersClear}
    />
  );
};
