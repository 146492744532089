import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

type UploadDialogProps = {
  open: boolean;
  fileName: string;
  onConfirm: () => void;
  onCancel: () => void;
};
export const UploadDialog = ({ open, fileName, onConfirm, onCancel }: UploadDialogProps) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} fullWidth maxWidth={'xs'}>
      <DialogTitle variant="h2">{t('bulkProvisioning.title')}</DialogTitle>
      <DialogContent>
        <Typography variant="text1">{t('bulkProvisioning.fileUploadingInfo')}</Typography>
        <Typography variant="text1Strong" component="div" sx={{ overflowWrap: 'anywhere' }}>
          {fileName}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={onConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
