import { Claim, User, UserResponse } from './authApi.interface';
import { ClaimType } from './authApi.constants';
import { authFetch } from 'auth/utils';

const getAuthPath = () => {
  if (window.location.href.includes('provisioning-requests')) {
    return process.env.REACT_APP_BULK_PROVISIONING_AUTH_API;
  }

  if (window.location.href.includes('audit-trail')) {
    return process.env.REACT_APP_AUDIT_TRAIL_AUTH_API;
  }

  return process.env.REACT_APP_SIM_INVENTORY_AUTH_API;
};

export const loginRedirect = async () => {
  const urlQuery = new URLSearchParams({
    returnUrl: window.location.href,
  });

  const authPath = getAuthPath();

  window.location.href = `${authPath}/auth/login?` + urlQuery.toString();
};

export const logoutRedirect = async () => {
  const urlQuery = new URLSearchParams({
    returnUrl: window.location.origin,
  });

  const authPath = getAuthPath();

  window.location.href = `${authPath}/auth/logout?` + urlQuery.toString();
};

export const fetchUser = async () => {
  const getUserFromClaims = (claims: Claim[]) => {
    const user = claims.reduce((user, claim) => {
      if (claim.type === ClaimType.GivenName) {
        return { ...user, givenName: claim.value };
      }

      if (claim.type === ClaimType.Surname) {
        return { ...user, surname: claim.value };
      }

      if (claim.type === ClaimType.EmailAddress) {
        return { ...user, emailAddress: claim.value };
      }

      if (claim.type === ClaimType.PreferredUsername) {
        return { ...user, username: claim.value };
      }

      if (claim.type === ClaimType.Role && claim.value === 'admin') {
        return { ...user, role: 'admin' };
      }

      if (claim.type === ClaimType.Role && claim.value === 'user') {
        return { ...user, role: 'user' };
      }

      return user;
    }, {});

    return user as User;
  };

  const authPath = getAuthPath();

  const response = await authFetch(`${authPath}/auth/user`);

  if (!response.ok) {
    throw new Error(response.status.toString());
  }

  const data: UserResponse = await response.json();

  return getUserFromClaims(data.claims);
};
