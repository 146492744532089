import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  saveSimInventoryColumnWidth,
  saveSimInventoryTableConfiguration,
} from 'simInventory/SimInventoryApi/simInventoryTableConfigurationApi';
import {
  ColumnConfiguration,
  TABLE_VARIANT,
  TableConfiguration,
  TableVariant,
} from 'simInventory/SimInventoryApi/simInventoryTableConfigurationApi.interface';

import { reorderColumns } from '../TableBase.util';
import { cloneDeep } from 'lodash';

const SimInventoryConfigurableKey = ['SimInventoryConfigurable'];

const updateColumnsVisibility = (
  columnsConfiguration: ColumnConfiguration[],
  columnName: string,
  enabled: boolean,
) => {
  const newColumnsConfiguration = [...columnsConfiguration];
  const indexOfColumn = newColumnsConfiguration.findIndex(({ name }) => {
    return name === columnName;
  });

  if (indexOfColumn < 0) {
    throw new Error('Column not found');
  }

  const columnConfiguration = newColumnsConfiguration[indexOfColumn];
  newColumnsConfiguration[indexOfColumn] = {
    ...columnConfiguration,
    enabled,
  };

  return newColumnsConfiguration;
};

const updateSelectedConfigurationColumnVisibility = (
  configurationName: 'combinedColumnConfigurations' | 'flatColumnConfigurations',
  currentConfiguration: TableConfiguration,
  columnName: string,
  enabled: boolean,
) => {
  try {
    const newColumnConfiguration = updateColumnsVisibility(
      currentConfiguration[configurationName],
      columnName,
      enabled,
    );

    return newColumnConfiguration;
  } catch {
    return currentConfiguration[configurationName];
  }
};

const updateColumnsWidth = (
  columnsConfiguration: ColumnConfiguration[],
  columnName: string,
  width: string,
  isCustomField: boolean,
) => {
  const newColumnsConfiguration: ColumnConfiguration[] = cloneDeep(columnsConfiguration);
  const indexOfColumn = newColumnsConfiguration.findIndex(({ name }) => {
    return name === columnName;
  });

  if (indexOfColumn < 0) {
    throw new Error('Column not found');
  }

  newColumnsConfiguration[indexOfColumn] = {
    ...newColumnsConfiguration[indexOfColumn],
    width,
    isCustomField
  };

  return newColumnsConfiguration;
};

const updateSelectedConfigurationColumnWidth = (
  configurationName: 'combinedColumnConfigurations' | 'flatColumnConfigurations',
  currentConfiguration: TableConfiguration,
  columnName: string,
  width: string,
  isCustomField: boolean,
) => {
  try {
    const newColumnConfiguration = updateColumnsWidth(
      currentConfiguration[configurationName],
      columnName,
      width,
      isCustomField,
    );
    return newColumnConfiguration;
  } catch {
    console.log('failed');
    return currentConfiguration[configurationName];
  }
};

const tableVariantToConfigurationPropMap: Record<
  TableVariant,
  'combinedColumnConfigurations' | 'flatColumnConfigurations'
> = {
  0: 'combinedColumnConfigurations',
  1: 'flatColumnConfigurations',
} as const;

export const useColumnConfigurationActions = () => {
  const queryClient = useQueryClient();

  const updateLocallyColumnConfiguration = (
    variant: TableVariant,
    columnConfigurations: ColumnConfiguration[],
  ) => {
    queryClient.setQueryData<TableConfiguration>(
      SimInventoryConfigurableKey,
      (currentConfiguration: TableConfiguration | undefined): TableConfiguration => {
        if (!currentConfiguration) {
          throw new Error('Configuration had to be fetched before!');
        }

        if (variant === TABLE_VARIANT.Flat) {
          return {
            ...currentConfiguration,
            enabledVariant: variant,
            flatColumnConfigurations: columnConfigurations,
          };
        } else {
          return {
            ...currentConfiguration,
            combinedColumnConfigurations: columnConfigurations,
          };
        }
      },
    );
  };

  const { mutate: mutateColumnConfigurtations } = useMutation(
    async ({
      variant,
      columnConfigurations,
    }: {
      variant: TableVariant;
      columnConfigurations: ColumnConfiguration[];
    }) => {
      saveSimInventoryTableConfiguration({
        variant: variant,
        columnConfigurations,
      });
    },
    {
      onMutate: ({
        variant,
        columnConfigurations,
      }: {
        variant: TableVariant;
        columnConfigurations: ColumnConfiguration[];
      }) => {
        updateLocallyColumnConfiguration(variant, columnConfigurations);
      },
    },
  );

  const { mutate: mutateWidth } = useMutation(saveSimInventoryColumnWidth);

  const updateVisibiltyOfColumn = useCallback((columnName: string, enabled: boolean) => {
    const configuration = queryClient.getQueryData<TableConfiguration>(SimInventoryConfigurableKey);
    if (!configuration) {
      throw new Error('Configuration had to be fetched before!');
    }

    const configurationKey = tableVariantToConfigurationPropMap[configuration.enabledVariant];

    const columnConfigurations = updateSelectedConfigurationColumnVisibility(
      configurationKey,
      configuration,
      columnName,
      enabled,
    );

    mutateColumnConfigurtations({ variant: configuration.enabledVariant, columnConfigurations });
  }, []);

  const updateVisibiltyOfColumns = useCallback((visiblityModel: Record<string, boolean>) => {
    const configuration = queryClient.getQueryData<TableConfiguration>(SimInventoryConfigurableKey);
    if (!configuration) {
      throw new Error('Configuration had to be fetched before!');
    }

    const configurationKey = tableVariantToConfigurationPropMap[configuration.enabledVariant];

    const columnConfigurations = configuration[configurationKey].map((column) => ({
      ...column,
      enabled: visiblityModel[column.name],
    }));

    mutateColumnConfigurtations({ variant: configuration.enabledVariant, columnConfigurations });
  }, []);

  const updateWidthOfColumn = useCallback((columnName: string, width: 'auto' | number, isCustomField: boolean) => {
    const configuration = queryClient.getQueryData<TableConfiguration>(SimInventoryConfigurableKey);

    if (!configuration) {
      throw new Error('Configuration had to be fetched before!');
    }

    const configurationKey = tableVariantToConfigurationPropMap[configuration.enabledVariant];

    const newWidth = width === 'auto' ? 'auto' : Math.floor(+width).toString();

    const columnConfigurations = updateSelectedConfigurationColumnWidth(
      configurationKey,
      configuration!,
      columnName,
      newWidth,
      isCustomField,
    );

    mutateWidth({ columnName, width: newWidth, isCustomField });

    updateLocallyColumnConfiguration(configuration.enabledVariant, columnConfigurations);
  }, []);

  const reorder = useCallback((srcIndex: number, dstIndex: number) => {
    const configuration = queryClient.getQueryData<TableConfiguration>(SimInventoryConfigurableKey);

    if (!configuration) {
      throw new Error('Configuration had to be fetched before!');
    }

    const configurationKey = tableVariantToConfigurationPropMap[configuration.enabledVariant];

    const columnConfigurations = reorderColumns(
      configuration[configurationKey],
      srcIndex,
      dstIndex,
    );

    mutateColumnConfigurtations({ variant: configuration.enabledVariant, columnConfigurations });
  }, []);

  return {
    updateVisibiltyOfColumn,
    updateVisibiltyOfColumns,
    updateWidthOfColumn,
    reorder,
  };
};
