import { userRoles } from 'auth/AuthApi/authApi.constants';
import { UserRole } from 'auth/AuthApi/authApi.interface';
import {
  AUDIT_TRAILS_PERMISSIONS,
  AuditTrailsPermissions,
  BulkProvisioningPermissions,
  SIM_INVENOTRY_PERMISSIONS,
  SimInventoryPermissions,
} from './Permissions.model';

export const BULK_ADMIN_RULES: BulkProvisioningPermissions[] = [
  'downloadReport',
  'viewTableAndDetails',
  'uploadFile',
  'setSimStatus',
];

export const BULK_USER_RULES: BulkProvisioningPermissions[] = [];

export const getBulkRulesByRole = (role: UserRole) =>
  role === userRoles.admin ? BULK_ADMIN_RULES : BULK_USER_RULES;

export const AUDIT_TRAILS_ADMIN_RULES: AuditTrailsPermissions[] = [
  AUDIT_TRAILS_PERMISSIONS.viewAuditTrailList,
  AUDIT_TRAILS_PERMISSIONS.export,
];

export const getAuditTrailsRulesByRole = () => AUDIT_TRAILS_ADMIN_RULES;

export const SIM_INVENTORY_ADMIN_RULES: SimInventoryPermissions[] =
  Object.values(SIM_INVENOTRY_PERMISSIONS);

export const SIM_INVENTORY_USER_RULES: SimInventoryPermissions[] = [
  SIM_INVENOTRY_PERMISSIONS.ViewSimListAndDetails,
  SIM_INVENOTRY_PERMISSIONS.Export,
  SIM_INVENOTRY_PERMISSIONS.EditTags,
  SIM_INVENOTRY_PERMISSIONS.ExportPoolingLogs,
  SIM_INVENOTRY_PERMISSIONS.SimulateOutage,
];

export const getSimInventoryRulesByRole = (role: UserRole) =>
  role === userRoles.admin ? SIM_INVENTORY_ADMIN_RULES : SIM_INVENTORY_USER_RULES;
