import { Link, Navigate, Outlet, Route, Routes as RouterRoutes } from 'react-router-dom';
import { SimInventoryTab } from 'simInventory/SimInventory';
import { SimInventoryEntry } from 'simInventory/SimInventoryDetails/SimInventoryEntry';
import { RSimInventoryTab } from 'rSimInventory/RSimInventory';
import { Routes } from './Routes.constants';
import { BulkProvisioningJobs } from 'bulkProvisioning/BulkProvisioningJobs';
import { BulkProvisioningJob } from 'bulkProvisioning/BulkProvisioningJob';
import { TabAppLayout } from 'common/components/AppLayout/TabAppLayout';
import Tab from '@mui/material/Tab';
import { tabStyle } from 'common/components/AppLayout/AppLayout.styles';
import { SimInventoryTabsLayout } from 'simInventory/SimInventoryDetails/SimInventoryTabsLayout';
import { BorderBox } from 'common/components/AppLayout/BorderBox';
import { SessionHistoryWithInterims } from 'simInventory/SessionHistory/SessionHistoryWithInterims';
import { HistoryAnalysis } from 'simInventory/SessionHistory/HistoryAnalysis/HistoryAnalysis';
import { RSimHistoryAnalysis } from 'rSimInventory/HistoryAnalysis/RSimHistoryAnalysis';
import { RSimInventoryTabsLayout } from 'rSimInventory/Components/RSimInventoryTabsLayout';
import { RSimInventoryEntry } from './rSimInventory/Components/RSimInventoryDetails/RSimInventoryEntry';
import { RSimSessionHistoryEntry } from './rSimInventory/Components/RSimSessionHistory/RSimSessionHistoryEntry';
import { FeatureFlagResolver } from 'featureFlags/FeatureFlagResolver';
import { AdminTabsLayout } from './admin/AdminTabsLayout';
import { CustomFieldsEntry } from './admin/customFields/CustomFieldsEntry';
import { SearchStoreProvider } from 'simInventory/Components/Filters/SearchStoreProvider';

import { AppNavigation } from './common/components/AppLayout/AppNavigation/AppNavigation';
import { Actions, Subjects } from 'permissions/ability';
import { useAbility } from 'permissions/hooks/useAbility';
import { AuditTrailPage } from './auditTrail/AuditTrailPage';
import { SingleTabLayout } from 'common/components/AppLayout/SingleTabLayout';
import { LocationStateStoreProvider } from 'common/components/LocationStorage/LocationStateStoreProvider';
import { RSimManufacturingFilePage } from './admin/rSimManufacturingFile/RSimManufacturingFilePage';
import { useHasFeatureFlag } from './featureFlags/useHasFeatureFlag';

type TabsProps = {
  firstTabHighlited?: boolean;
};

const Tabs: React.FC<TabsProps> = ({ firstTabHighlited = false }) => {
  return (
    <TabAppLayout firstTabHighlited={firstTabHighlited}>
      <Tab
        value={Routes.simInventory}
        label="SIM"
        component={Link}
        to={Routes.simInventory}
        sx={tabStyle}
      />
      <Tab
        sx={tabStyle}
        value={Routes.rSimInventory}
        label="rSIM"
        component={Link}
        to={Routes.rSimInventory}
      />
    </TabAppLayout>
  );
};

const RsimLoader = () => {
  return <RSimInventoryTab />;
};

const SimDetailsLoader = () => {
  const ability = useAbility();

  return ability.can('read', 'simDetails') ? (
    <BorderBox>
      <SimInventoryEntry />
    </BorderBox>
  ) : (
    <Navigate to={Routes.simInventory} replace />
  );
};

export const AppRoutes = () => {
  const ability = useAbility();
  const importRsimEnabled = useHasFeatureFlag('ImportRsims');

  return (
    <RouterRoutes>
      <Route
        element={
          <>
            <AppNavigation />
            <SingleTabLayout />
          </>
        }
      >
        <Route path={Routes.provisioningRequest} element={<BulkProvisioningJobs />}>
          <Route path=":id" element={<BulkProvisioningJob />} />
        </Route>
      </Route>
      <Route
        element={
          <LocationStateStoreProvider>
            <AppNavigation />
            <Outlet />
          </LocationStateStoreProvider>
        }
      >
        <Route element={<Tabs />}>
          <Route path={Routes.rSimInventory} element={<RsimLoader />} />
        </Route>

        <Route element={<RSimInventoryTabsLayout />}>
          <Route path={`${Routes.rSimInventory}/:euicc`} element={<RSimInventoryEntry />} />

          <Route
            path={`${Routes.rSimInventory}/:euicc/session-history`}
            element={
              <FeatureFlagResolver requiredFlag="SimCardSessionsHistory">
                <RSimSessionHistoryEntry />
              </FeatureFlagResolver>
            }
          />
          <Route
            path={`${Routes.rSimInventory}/:euicc/history-analysis`}
            element={
              <FeatureFlagResolver requiredFlag="SimCardSessionsHistory">
                <RSimHistoryAnalysis />
              </FeatureFlagResolver>
            }
          />
        </Route>

        <Route element={<SearchStoreProvider />}>
          <Route element={<Tabs firstTabHighlited />}>
            <Route
              path={Routes.simInventory}
              element={
                <BorderBox>
                  <SimInventoryTab />
                </BorderBox>
              }
            />
          </Route>
          <Route element={<SimInventoryTabsLayout />}>
            <Route path={`${Routes.simInventory}/:iccid`} element={<SimDetailsLoader />} />

            <Route
              path={`${Routes.simInventory}/:iccid/history`}
              element={
                <FeatureFlagResolver requiredFlag="SimCardSessionsHistory">
                  <SessionHistoryWithInterims />
                </FeatureFlagResolver>
              }
            />

            <Route
              path={`${Routes.simInventory}/:iccid/history-analysis`}
              element={
                <FeatureFlagResolver requiredFlag="SimCardSessionsHistory">
                  <HistoryAnalysis />
                </FeatureFlagResolver>
              }
            />
            {ability.can(Actions.read, Subjects.auditTrails) && (
              <Route
                element={
                  <BorderBox>
                    <AuditTrailPage />
                  </BorderBox>
                }
                path={`${Routes.simInventory}/:iccid/audit-trail`}
              />
            )}
          </Route>
        </Route>

        {(ability.can(Actions.edit, Subjects.customFields) ||
          ability.can(Actions.edit, Subjects.importRsims)) && (
          <Route element={<AdminTabsLayout />}>
            {ability.can(Actions.edit, Subjects.customFields) && (
              <Route
                path={`${Routes.admin}/custom-fields`}
                element={
                  <BorderBox>
                    <CustomFieldsEntry />
                  </BorderBox>
                }
              />
            )}
            {importRsimEnabled && ability.can(Actions.edit, Subjects.importRsims) && (
              <Route
                path={`${Routes.admin}/rsim-manufacturing-file`}
                element={
                  <BorderBox>
                    <RSimManufacturingFilePage />
                  </BorderBox>
                }
              />
            )}
          </Route>
        )}
      </Route>
      <Route path="*" element={<Navigate to={Routes.simInventory} replace />} />
    </RouterRoutes>
  );
};
