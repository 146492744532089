import { Bar, getElementAtEvent } from 'react-chartjs-2';
import React, { useMemo, useRef } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  TimeScale,
  CategoryScale,
  BarElement,
  TimeSeriesScale,
  Decimation
} from 'chart.js';

import zoom from 'chartjs-plugin-zoom';
import 'chartjs-adapter-luxon';
import Box from '@mui/material/Box';
import {
  GRAPH_DEFAULT_OPTIONS,
  STATUS_BORDER_COLOR_MAP,
  STATUS_COLOR_MAP,
  chartBorderPlugin
} from '../graphConfig';
import { highlightPlugin, middleLine } from '../utils/graphPlugins';
import { useDispatchEventToChart } from '../common/Graphs/hooks/useDispatchEventToChart';
import { useGraphTooltipConfig } from './hooks/useGraphTooltipConfig';
import { useGraphZoomConfig } from '../common/Graphs/hooks/useGraphZoomConfig';
ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  Tooltip,
  PointElement,
  BarElement,
  TimeSeriesScale,
  Decimation
);
ChartJS.register(zoom);

export type ChartEntryData = {
  x: [Date, Date];
  y: string;
  dataIndex: number;
};

export type RSimSessionGraphRangeProps = {
  primaryEntries: ChartEntryData[];
  backupEntries: ChartEntryData[];
  from: number;
  to: number;
  min: number;
  max: number;
  highlightFrom?: Date | null;
  highlightTo?: Date | null;
  onZoomComplete: (range: [Date, Date]) => void;
  tooltip: (positionX: number, index: number | null, setName: string | null) => void;
  onClick: (index: number, setName: 'Primary' | 'Backup' | null) => void;
  onPan?: (newMin: Date, newMax: Date) => void;
};

const DEFAULT_BAR_SETTINGS = {
  borderWidth: 1,
  borderRadius: 4,
  borderSkipped: false,
  barThickness: 20
};

const prepareBarData = (
  entries: ChartEntryData[],
  filterValue: string,
  type: 'Primary' | 'Backup'
) => {
  return entries
    .filter((entry) => entry.y === filterValue)
    .map((entry) => ({
      x: entry.x,
      y: type,
      dataIndex: entry.dataIndex
    }));
};

const prepareSimDatasets = (entries: ChartEntryData[], type: 'Primary' | 'Backup') => {
  const normalDatasets = {
    ...DEFAULT_BAR_SETTINGS,
    label: 'NORMAL',
    data: prepareBarData(entries, 'NORMAL', type),
    backgroundColor: STATUS_COLOR_MAP.NORMAL,
    borderColor: STATUS_BORDER_COLOR_MAP.NORMAL
  };
  const notFinishedDatasets = {
    ...DEFAULT_BAR_SETTINGS,
    label: 'NOT FINISHED',
    data: prepareBarData(entries, 'NOT_FINALIZED', type),
    backgroundColor: STATUS_COLOR_MAP.NOT_FINALIZED,
    borderColor: STATUS_BORDER_COLOR_MAP.NOT_FINALIZED
  };

  const zeroByteDatasets = {
    ...DEFAULT_BAR_SETTINGS,
    label: 'ZERO BYTE SESSION',
    data: prepareBarData(entries, 'ZERO_BYTE_SESSION', type),
    backgroundColor: STATUS_COLOR_MAP.ZERO_BYTE_SESSION,
    borderColor: STATUS_BORDER_COLOR_MAP.ZERO_BYTE_SESSION
  };

  return [normalDatasets, notFinishedDatasets, zeroByteDatasets];
};

export const RSimSessionGraphRange: React.FC<RSimSessionGraphRangeProps> = React.memo(
  ({
    primaryEntries,
    backupEntries,
    from,
    to,
    min,
    max,
    highlightFrom,
    highlightTo,
    onZoomComplete,
    tooltip,
    onClick,
    onPan
  }) => {
    const barData = useMemo(() => {
      return {
        labels: ['Primary', 'Backup'],
        datasets: [
          ...prepareSimDatasets(primaryEntries, 'Primary'),
          ...prepareSimDatasets(backupEntries, 'Backup')
        ]
      };
    }, [primaryEntries, backupEntries]);

    const tooltipConfig = useGraphTooltipConfig(from, to, tooltip);
    const zoomConfig = useGraphZoomConfig(5 * 60_000, min, max, onZoomComplete, onPan);

    const barOptions = useMemo(
      () => ({
        ...GRAPH_DEFAULT_OPTIONS,
        indexAxis: 'y',
        scales: {
          x: {
            ...GRAPH_DEFAULT_OPTIONS!.scales!.x,
            type: 'time',
            min: new Date(from),
            max: new Date(to)
          },
          y: {
            ...GRAPH_DEFAULT_OPTIONS!.scales!.y,
            stacked: true,
            beginAtZero: false,
            display: false
          }
        },
        plugins: {
          ...GRAPH_DEFAULT_OPTIONS!.plugins,
          zoom: zoomConfig,
          highlightPlugin: {
            from: highlightFrom,
            to: highlightTo
          },
          middleLine: {
            borderColor: '#C0CACC',
            borderWidth: 1
          },
          tooltip: tooltipConfig
        }
      }),
      [from, to, barData, highlightFrom, highlightTo, tooltipConfig, zoomConfig]
    );

    const chartRef = useRef();
    const onChartClick = (event: any) => {
      //@ts-ignore
      const elementsAtEvent = getElementAtEvent(chartRef.current, event);
      if (elementsAtEvent.length === 0) {
        return;
      }

      const [chartElement] = elementsAtEvent;

      //@ts-ignore
      const pointRaw = chartElement.element['$context'].raw;

      //@ts-ignore
      const index = pointRaw.dataIndex;

      //@ts-ignore
      const setName = pointRaw.y;

      onClick(index, setName);
    };

    useDispatchEventToChart(chartRef);

    return (
      <Box sx={{ height: 100 }}>
        <Bar
          //@ts-ignore
          options={barOptions}
          data={barData}
          plugins={[chartBorderPlugin, middleLine, highlightPlugin]}
          ref={chartRef}
          onClick={onChartClick}
        />
      </Box>
    );
  }
);
