import Box from '@mui/material/Box';
import { SessionHistoryTimeline } from '../services/SessionHistoryApi';
import { ValuesType } from 'utility-types';
import Stack from '@mui/material/Stack';
import { toLocaleDateTime } from 'common/utils/dateUtils';
import Typography from '@mui/material/Typography';

import { getHuminizeDurationFunction } from 'i18n/dateLocale';
import { SxProps } from '@mui/material';
import { Routes } from 'Routes.constants';
import { TypographyLink } from 'common/components/Link/TypographyLink';
import React from 'react';
import { ThreeColumnLayout } from 'common/components/AppLayout/ThreeColumnLayout';
import { useTranslation } from 'react-i18next';

export type SessionDetailsProps = {
  session: ValuesType<SessionHistoryTimeline> | null;
  profile?: string | null;
  iccid?: string;
};

export type SessionDetailsPropProps = {
  label: string;
  value: string | null;
  link?: boolean;
  'data-testid'?: string;
};

const mapNullToChar = (value: string | null, char: string = '-') => (value === null ? char : value);

const sessionDetailsPropLabelSx: SxProps = {
  textTransform: 'capitalize',
};

const SessionDetailsProp: React.FC<SessionDetailsPropProps> = ({
  label,
  value,
  link = false,
  'data-testid': dataTestId,
}) => {
  const formatedValue = mapNullToChar(value);
  return (
    <Stack direction="row" spacing={2} data-testid={dataTestId}>
      <Box sx={{ minWidth: 140 }}>
        <Typography variant="text5Secondary" sx={sessionDetailsPropLabelSx}>
          {label}
        </Typography>
      </Box>
      <Box>
        {link ? (
          <TypographyLink
            to={`${Routes.simInventory}/${value}`}
            variant="text3Secondary"
            data-testid={`${dataTestId} label`}
          >
            {formatedValue}
          </TypographyLink>
        ) : (
          <Typography variant="text3Secondary" data-testid={`${dataTestId} value`}>
            {formatedValue}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};

const humanize = getHuminizeDurationFunction();

const getSessionStatusLabelBaseOnSession = (session: SessionDetailsProps['session']) => {
  if (!session) {
    return '-';
  }

  return session.sessionEnd ? 'Finalized' : 'Not finalized';
};

export const SessionDetails: React.FC<SessionDetailsProps> = ({ session, profile, iccid }) => {
  const sessionToDisplay = session
    ? {
        ...session,
        cumulativeUsage: session?.cumulativeUsage?.toFixed(2),
        cumulativeInputUsage: session?.cumulativeInputUsage.toFixed(2),
        cumulativeOutputUsage: session?.cumulativeOutputUsage.toFixed(2),
      }
    : {
        sessionStart: null,
        sessionEnd: null,
        statusLastUpdated: null,
        duration: null,
        status: getSessionStatusLabelBaseOnSession(session),
        localization: '-',
        cumulativeUsage: '-',
        cumulativeOutputUsage: '-',
        cumulativeInputUsage: '-',
        framedIpaddress: '-',
        calledStationId: '-',
        detectedImei: '-',
        acctSessionId: '-',
        operatorCode: '-',
        ratType: '-',
        framedProtocol: '-',
        rasClient: '-',
        nasIpAddress: '-',
        serviceType: '-',
        username: '-',
        roamingCode: '-',
      };

  const { t } = useTranslation();
  return (
    <Box sx={{ px: 10, pt: 2 }}>
      <Box>
        <Typography variant="h4">Session details</Typography>
      </Box>
      <ThreeColumnLayout
        firstColumnChildren={[
          <SessionDetailsProp
            data-testid="Session Start Time"
            label="Session Start Time"
            value={`${toLocaleDateTime(sessionToDisplay.sessionStart, '-')}`}
          />,
          <SessionDetailsProp
            data-testid="Session End Time"
            label="Session End Time"
            value={`${toLocaleDateTime(sessionToDisplay?.sessionEnd, '-')}`}
          />,
          <SessionDetailsProp
            data-testid="Duration"
            label="Duration"
            value={`${sessionToDisplay?.duration ? humanize(sessionToDisplay.duration) : '-'}`}
          />,
          <SessionDetailsProp
            data-testid="Status"
            label="Status"
            value={getSessionStatusLabelBaseOnSession(session)}
          />,
          <SessionDetailsProp
            data-testid="Update Timestamp"
            label="Update Timestamp"
            value={`${toLocaleDateTime(sessionToDisplay.statusLastUpdated, '-')}`}
          />,
          <SessionDetailsProp
            data-testid="Last location"
            label="Last location"
            value={sessionToDisplay?.localization}
          />,
          <SessionDetailsProp
            data-testid="Roaming Code"
            label={t('simInventory.roamingCode')}
            value={sessionToDisplay?.roamingCode}
          />,
        ]}
        secondColumnChildren={[
          <SessionDetailsProp
            data-testid="Cumulative Usage (KB)"
            label="Cumulative Usage (KB)"
            value={`${sessionToDisplay.cumulativeUsage} `}
          />,
          <SessionDetailsProp
            data-testid="Data Output (KB)"
            label="Data Output (KB)"
            value={`${sessionToDisplay.cumulativeOutputUsage} `}
          />,
          <SessionDetailsProp
            data-testid="Data Input (KB)"
            label="Data Input (KB)"
            value={`${sessionToDisplay.cumulativeInputUsage} `}
          />,
          <SessionDetailsProp
            data-testid="IP Address"
            label="IP Address"
            value={sessionToDisplay.framedIpaddress}
          />,
          <SessionDetailsProp
            data-testid="APN"
            label="APN"
            value={sessionToDisplay.calledStationId}
          />,
          <SessionDetailsProp
            data-testid="Detected IMEI"
            label="Detected IMEI"
            value={sessionToDisplay.detectedImei}
          />,
          profile && (
            <SessionDetailsProp data-testid="rSIM profile" label="rSIM profile" value={profile} />
          ),
          iccid && <SessionDetailsProp data-testid="ICCID" label="ICCID" value={iccid} link />,
        ]}
        thirdColumnChildren={[
          <SessionDetailsProp
            data-testid="Session ID"
            label="Session ID"
            value={sessionToDisplay.acctSessionId}
          />,
          <SessionDetailsProp
            data-testid="Mobile Network Operator"
            label="Mobile Network Operator"
            value={sessionToDisplay.operatorCode}
          />,
          <SessionDetailsProp
            data-testid="Last RAT"
            label="Last RAT"
            value={sessionToDisplay.ratType}
          />,
          <SessionDetailsProp
            data-testid="Framed Protocol"
            label="Framed Protocol"
            value={sessionToDisplay.framedProtocol}
          />,
          <SessionDetailsProp
            data-testid="RAS client"
            label="RAS client"
            value={sessionToDisplay.rasClient}
          />,
          <SessionDetailsProp
            data-testid="NAS IP Address"
            label="NAS IP Address"
            value={sessionToDisplay.nasIpAddress}
          />,
          <SessionDetailsProp
            data-testid="Service type"
            label="Service type"
            value={sessionToDisplay.serviceType}
          />,
          <SessionDetailsProp
            data-testid="User Name"
            label="User Name"
            value={sessionToDisplay.username}
          />,
        ]}
      />
    </Box>
  );
};
