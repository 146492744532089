import { useHints } from 'common/hooks/useHints';
import { useState } from 'react';

export const useModalHints = (hintsKey: string) => {
  const [tempHints, setTempHints] = useState<{ [key: string]: any }>({});
  const { internalHints, setInternalHints, saveHintsToStorage, removeHint, getHintsFromStorage } =
    useHints(hintsKey);

  const applyHints = () => {
    setInternalHints(tempHints);
    saveHintsToStorage(tempHints);
  };

  return {
    applyHints,
    internalHints,
    setTempHints,
    removeHint,
    getHintsFromStorage,
  };
};
