import { authFetch } from 'auth/utils';
import { ErrorCodes } from 'common/errorCodes';
import saveAs from 'file-saver';
import { SimInventorySearch } from 'simInventory/Components/SimInventoryTable.interface';
import {
  MaxSimProfilesCountError,
  MaxSimProfilesValidationError,
} from 'simInventory/SimInventoryApi/simInventoryApi';
import { SimInventorySort } from 'simInventory/models/SimProfile.model';
import { prepareSimInventorySearchBody } from 'simInventory/utils/prepareSimInventorySearchBody';

const getFileNameFromContentDispositionHeader = (headerValue: string | null) =>
  headerValue
    ?.split(';')
    ?.find((n) => n.includes('filename='))
    ?.replace('filename=', '')
    .trim()
    .replace(/"/g, '');

export const downloadSimProfilesReport = async (
  searchParams: SimInventorySearch,
  sort: SimInventorySort | null = null,
) => {
  const body = prepareSimInventorySearchBody(searchParams, sort);

  const fileResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_REPORTING_API}/sim-profiles`,
    {
      method: 'POST',
      body: JSON.stringify({
        ...body,
        timeZoneIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  if (!fileResponse.ok && fileResponse.status.toString() === ErrorCodes.BadRequest) {
    const { validationErrors } = await fileResponse.json();
    if ('MaxSimProfilesCount' in validationErrors) {
      const maxSimProfilesValidationError = validationErrors
        .MaxSimProfilesCount[0] as MaxSimProfilesValidationError;
      throw new MaxSimProfilesCountError(maxSimProfilesValidationError.state.maxSimProfilesCount);
    }
  }

  if (!fileResponse.ok) {
    throw new Error(fileResponse.statusText);
  }

  const filename = getFileNameFromContentDispositionHeader(
    fileResponse.headers.get('Content-Disposition'),
  );

  const blob = await fileResponse.blob();

  saveAs(blob, filename);

  return fileResponse;
};
