import { cleanFalsyEntries } from './objectUtils';

export const getObjectFromUrlSearchParams = (searchParams: URLSearchParams) => {
  return Object.fromEntries(searchParams.entries());
};

export const getUpdatedUrlSearchParams = (
  key: string,
  value: string,
  currentSearchParams: URLSearchParams
) => {
  const objectCurrentSearchParams = getObjectFromUrlSearchParams(currentSearchParams);
  const updatedSearchParams = { ...objectCurrentSearchParams, [key]: value };
  return cleanFalsyEntries(updatedSearchParams);
};

export const getUpdatedUrlSearchParamsWithObject = (
  object: Record<string, string>,
  currentSearchParams: URLSearchParams
) => {
  const objectCurrentSearchParams = getObjectFromUrlSearchParams(currentSearchParams);
  const updatedSearchParams = { ...objectCurrentSearchParams, ...object };
  return cleanFalsyEntries(updatedSearchParams);
};
