import Grid from "@mui/material/Grid";
import { TableCellSkeleton } from './TableCellSkeleton';

export const SkeletonPagination = () => {
  return (
    <Grid
      container
      sx={{ background: ({ palette }) => palette.grey[100], height: '44px' }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item sx={{ pl: '24px', width: '48px' }}>
        <TableCellSkeleton />
      </Grid>
      <Grid item sx={{ width: '128px' }}>
        <TableCellSkeleton />
      </Grid>
      <Grid item sx={{ pr: '24px', width: '48px' }}>
        <TableCellSkeleton />
      </Grid>
    </Grid>
  );
};
