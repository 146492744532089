import FormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch, { SwitchProps } from '@mui/material/Switch';

export type LabelPlacement = 'bottom' | 'top' | 'start' | 'end';

type EnablingSwitchProps = {
  enabledLabel?: string;
  disabledLabel?: string;
  labelPlacement?: LabelPlacement;
  disabled?: boolean;
} & Required<Pick<SwitchProps, 'onChange' | 'checked'>>;

export const EnablingSwitch = ({
  checked,
  enabledLabel,
  disabledLabel,
  onChange,
  labelPlacement,
  disabled,
}: EnablingSwitchProps) => {
  return (
    <FormControlLabel
      onClick={(event) => {
        event.stopPropagation();
      }}
      value="top"
      control={<MuiSwitch onChange={onChange} checked={checked} />}
      label={checked ? enabledLabel : disabledLabel}
      labelPlacement={labelPlacement || 'bottom'}
      componentsProps={{
        typography: {
          variant: 'text5Primary',
          sx: {
            mt: !labelPlacement || labelPlacement === 'bottom' ? '-7px' : '',
          }
        }
      }}
      disabled={disabled}
    />
  );
};
