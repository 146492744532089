import { FC } from 'react';
import { ReactComponent as Arrow } from 'assets/images/arrowBack.svg';
import { t } from 'i18next';
import Box from '@mui/material/Box';
import { Tooltip } from '../Tooltip/Tooltip';
import { darkTooltipSx, tooltipSx } from '../Tooltip/Tooltip.style';

type SortingIndicatorProps = {
  direction: 'asc' | 'desc' | null;
  sorting: () => void;
  className?: string;
  testId?: string;
};

export const SortingIndicator: FC<SortingIndicatorProps> = ({
  sorting,
  direction,
  className,
  testId,
}) => {
  const style = {
    transform: direction === 'desc' ? 'rotate(270deg)' : 'rotate(90deg)',
    transition: 'transform 150ms ease',
  };

  const label =
    direction === 'asc'
      ? t('common.asc')
      : direction === 'desc'
      ? t('common.desc')
      : t('common.toSort');

  const sx = {...tooltipSx, ...darkTooltipSx};

  return (
    <Box
      data-testid={testId}
      aria-label={label}
      className={className}
      role="button"
      sx={{
        display: 'inline-flex',
        '&:hover': {
          background: '#E5E5E5',
          borderRadius: 2000,
        },
      }}
      onClick={() => {
        sorting();
      }}
    >
      <Tooltip
        showArrow={ true }
        placement={ 'top' }
        enterDelay={ 1000 }
        enterTouchDelay={ 1000 }
        enterNextDelay={ 1000 }
        textVariant={ 'text3white' }
        sx={sx}
        title={!direction || direction === 'desc' ? t('common.sortByAscending') : t('common.sortByDescending')}>
        <Arrow style={style} width="16px" height="16px" role="img" />
      </Tooltip>
    </Box>
  );
};
