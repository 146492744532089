import { i18 } from 'i18n/i18n';
import { z } from 'zod';

export const SESSION_STATUS = {
  UNKNOWN: 'Unknown',
  IN_SESSION: 'InSession',
  DISCONNECTED: 'Disconnected',
} as const;

export const SESSION_STATUS_NUMERIC = {
  DISCONNECTED: 0,
  IN_SESSION: 1
} as const;

export const SessionStatusSchema = z.union([
  z.literal(SESSION_STATUS.UNKNOWN),
  z.literal(SESSION_STATUS.IN_SESSION),
  z.literal(SESSION_STATUS.DISCONNECTED)
]);

export const sessionStatusNumericSchema = z.union([
  z.literal(SESSION_STATUS_NUMERIC.DISCONNECTED),
  z.literal(SESSION_STATUS_NUMERIC.IN_SESSION)
]);

export type SessionStatus = z.infer<typeof SessionStatusSchema>;

export type NumericSessionStatus = z.infer<typeof sessionStatusNumericSchema>;

export const SESSION_STATUS_UI = {
  OUT_OF_SESSION: 'OUT_OF_SESSION',
  IN_SESSION: 'IN_SESSION',
  UNKNOWN: 'UNKNOWN',
  NONE: 'NONE'
} as const;

export type SessionStatusUi = (typeof SESSION_STATUS_UI)[keyof typeof SESSION_STATUS_UI];

export const mapSessionStatusToStringSessionStatus = (
  sessionStatusValue: SessionStatus | null | undefined
): SessionStatusUi => {
  switch (sessionStatusValue) {
    case SESSION_STATUS.IN_SESSION:
      return SESSION_STATUS_UI.IN_SESSION;
    case SESSION_STATUS.DISCONNECTED:
      return SESSION_STATUS_UI.OUT_OF_SESSION;
    case undefined:
      return SESSION_STATUS_UI.NONE;
    default:
      return SESSION_STATUS_UI.UNKNOWN;
  }
};

export const getSessionStatusTranslatedLabel = (sessionStatus: SessionStatusUi, noneLabel: string = '-') => {
  switch (sessionStatus) {
    case SESSION_STATUS_UI.IN_SESSION:
      return i18.t('common.inSession');
    case SESSION_STATUS_UI.OUT_OF_SESSION:
      return i18.t('common.outSession');
    case SESSION_STATUS_UI.UNKNOWN:
      return i18.t('common.unknown');
    case SESSION_STATUS_UI.NONE:
      return noneLabel;
  }
};


export const SIM_STATUS_VALUE_LABEL_MAP: Record<NumericSessionStatus, SessionStatusUi> = {
  [SESSION_STATUS_NUMERIC.DISCONNECTED]: SESSION_STATUS_UI.OUT_OF_SESSION,
  [SESSION_STATUS_NUMERIC.IN_SESSION]: SESSION_STATUS_UI.IN_SESSION
};
