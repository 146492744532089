import TableRow from '@mui/material/TableRow';
import React from 'react';
import { StickyCell } from 'simInventory/Components/StickyCell';
import { AdditionalMenu, AdditionalMenuProps } from '../AdditionalMenu';
import { SxProps, Theme } from '@mui/material';

const style = {
  row: {
    '& .stickyCell': {
      visibility: 'hidden'
    },
    '&:hover': {
      '& .stickyCell': {
        visibility: 'visible'
      }
    }
  }
};

export type RowWithStickyMenuProps = {
  children: React.ReactNode;
  renderMenu: AdditionalMenuProps['children'];
  sx?: SxProps<Theme>;
};

export const RowWithStickyMenu: React.FC<RowWithStickyMenuProps> = ({
  children,
  renderMenu,
  sx
}) => {
  return (
    <TableRow sx={{ ...style.row, ...sx }}>
      {children}
      <StickyCell sx={{ background: '#fff' }} className="stickyCell">
        <AdditionalMenu>{renderMenu}</AdditionalMenu>
      </StickyCell>
    </TableRow>
  );
};
