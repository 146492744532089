import MenuItem from '@mui/material/MenuItem';
import { ValuesType } from 'utility-types';
import { MultipleIdInput } from 'common/components/Inputs/IdAdvanceSearch/MultipleIdInput';
import { useTranslation } from 'react-i18next';

export const SIM_INVENTORY_ID_SELECTION = {
  ICCID: 'ICCID',
  IMSI: 'IMSI',
  MSISDN: 'MSISDN',
} as const;

export type SimId = ValuesType<typeof SIM_INVENTORY_ID_SELECTION>;

export type SimIdSearchInputProps = {
  disabled?: boolean;
  chosenId: SimId;
  onChosenIdChange: (chosenId: SimId) => void;
  search: string;
  onSearchChange: (searchText: string) => void;
};

export const SimIdSearchInput = ({
  disabled = false,
  chosenId,
  onChosenIdChange,
  search,
  onSearchChange,
}: SimIdSearchInputProps) => {
  const { t } = useTranslation();

  return (
    <MultipleIdInput
      disabled={disabled}
      optionValue={chosenId}
      onOptionChange={onChosenIdChange as (chosenId: string) => void} // typescript cannot deduct becuase it base on menu items in children
      inputValue={search}
      onInputValueChange={onSearchChange}
      inputLabel={t('common.search')}
      selectLabel="ID"
      filterRegex={undefined}
    >
      <MenuItem value={SIM_INVENTORY_ID_SELECTION.ICCID}>
        {SIM_INVENTORY_ID_SELECTION.ICCID}
      </MenuItem>
      <MenuItem value={SIM_INVENTORY_ID_SELECTION.IMSI}>{SIM_INVENTORY_ID_SELECTION.IMSI}</MenuItem>
      <MenuItem value={SIM_INVENTORY_ID_SELECTION.MSISDN}>
        {SIM_INVENTORY_ID_SELECTION.MSISDN}
      </MenuItem>
    </MultipleIdInput>
  );
};
