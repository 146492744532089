import { QueryKey, useQueryClient } from 'react-query';
import { SimProfile } from 'simInventory/SimInventoryApi/simInventoryApi.interface';
import { useSimSignalRSubscription } from './useSimSignalRSubscription';

export const useSimDetailSubscription = (iccid: string, queryKey: QueryKey) => {
  const queryClient = useQueryClient();
  const updateEntry = (entryToUpdate: SimProfile) => {
    const simProfile: SimProfile | undefined = queryClient.getQueryData(queryKey);

    if (!entryToUpdate || !simProfile) {
      return;
    }

    const updatedSimProfile = {
      ...simProfile,
      ...entryToUpdate,
    };

    queryClient.setQueryData(queryKey, updatedSimProfile);
  };

  useSimSignalRSubscription([iccid], updateEntry);
};
