import ToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import React from 'react';

type ToggleGroupProps = Pick<ToggleButtonGroupProps, 'children' | 'value' | 'onChange' | 'sx'>;

export const ToggleGroup: React.FC<ToggleGroupProps> = ({ children, ...props }) => {
  return (
    <ToggleButtonGroup {...props} exclusive>
      {children}
    </ToggleButtonGroup>
  );
};
