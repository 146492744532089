import React, { useContext, useState } from 'react';

export type EditableProviderProps = {
  children: React.ReactNode;
};

type EditableContextProps = {
  editing: boolean;
  changed: boolean;
  enableEditnig: () => void;
  disableEditnig: () => void;
  notifyAboutChanges: () => void;
  notifyAboutClearingChanges: () => void;
};

export const EditableContext = React.createContext<EditableContextProps>({
  editing: false,
  changed: false,
  enableEditnig: () => {},
  disableEditnig: () => {},
  notifyAboutChanges: () => {},
  notifyAboutClearingChanges: () => {},
});

export const useEditableContext = () => {
  return useContext(EditableContext);
};

export const EditableProvider = ({ children }: EditableProviderProps) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  const enableEditnig = () => {
    setEditing(true);
  };

  const disableEditnig = () => {
    setEditing(false);
  };

  const notifyAboutChanges = () => {
    setChanged(true);
  };

  const notifyAboutClearingChanges = () => {
    setChanged(false);
  };

  return (
    <EditableContext.Provider
      value={{
        changed,
        editing,
        enableEditnig,
        disableEditnig,
        notifyAboutChanges,
        notifyAboutClearingChanges,
      }}
    >
      {children}
    </EditableContext.Provider>
  );
};
