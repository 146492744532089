import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChartPosition } from './HistoryAnalysis.types';
import useMeasure from 'react-use-measure';
import { useHistorySessionGraphData } from 'common/components/SessionHistory/hooks/useHistorySessionGraphData';
import { useDebounce, useOnClickOutside } from 'usehooks-ts';
import { SignleSessionGraphRange } from 'common/components/Graphs/Sessions/SignleSessionGraphRange';
import {
  convertSessionHistoryToGraphEntries,
  convertSessionHistoryToGraphUsageEntries
} from './utils';
import { SingleUsageGraph } from 'common/components/Graphs/SingleUsageGraph';
import CircularProgress from '@mui/material/CircularProgress';
import { ErrorOverflowBoxWithRefreshAction } from 'common/components/Graphs/ErrorOverflowBoxWithRefreshAction';
import { ErrorOverflowBox } from 'common/components/Graphs/ErrorOverflowBox';
import { toLocaleDateTime } from 'common/utils/dateUtils';
import { ReactComponent as HelpIcon } from 'assets/images/help.svg';
import { useTranslation } from 'react-i18next';
import {
  STATUS_COLOR_MAP,
  USAGE_BAR_BACKGROUND_COLORS
} from 'common/components/Graphs/graphConfig';
import { TooltipAdditionalRowsRender } from 'common/components/Tooltip/TooltipAdditionalRowsRender/TooltipAdditionalRowsRender';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { SessionDetailsWithInterimsGraphs } from 'common/components/SessionHistory/components/SessionDetailsWithInterimsGraphs';
import { useGraphSessionRange } from 'common/components/Graphs/Sessions/hooks/useGraphSessionRange';

type SessionsProps = {
  imsi: string;
};

const ENTRY_PER_PAGE = 25;

export const Sessions: React.FC<SessionsProps> = ({ imsi }) => {
  const { validRange, selectedRange, selectRange, visibleRange, selectVisibleRange } =
    useGraphSessionRange();
  const [currentSessionPosition, setSessionCurrentPosition] = useState<ChartPosition>({
    id: '',
    index: null,
    x: 0
  });

  const [selectedSessionId, setSelectedSessionId] = useState<string>('');

  const debouncedFrom = useDebounce(selectedRange.from, 500);
  const debouncedTo = useDebounce(selectedRange.to, 500);

  const {
    data: sessionHistory,
    hasNextPage,
    isFetching,
    isError
  } = useHistorySessionGraphData({
    imsi,
    from: debouncedFrom,
    to: debouncedTo,
    entryPerPage: ENTRY_PER_PAGE
  });

  const loading = isFetching || hasNextPage;

  const [deferredSessionHistory, setDeferredSessionHistory] = useState(sessionHistory);

  useEffect(() => {
    if (!loading) {
      setDeferredSessionHistory(sessionHistory);
    }
  }, [loading]);

  useEffect(() => {
    setSessionCurrentPosition({
      id: '',
      index: null,
      x: 0
    });
  }, [deferredSessionHistory]);

  const [root, bounds] = useMeasure();

  const session = deferredSessionHistory.find(
    (entry) => entry.acctSessionId === currentSessionPosition.id
  );

  const selectedSession =
    deferredSessionHistory.find((entry) => entry.acctSessionId === selectedSessionId) || null;

  const clickAreaRef = useRef<HTMLElement>(null);

  useOnClickOutside(clickAreaRef, () => {
    setSessionCurrentPosition({ id: '', index: null, x: 0 });
  });

  const graphEntries = useMemo(
    () => convertSessionHistoryToGraphEntries(deferredSessionHistory) || [],
    [deferredSessionHistory]
  );

  const usageGraphEntries = useMemo(
    () => convertSessionHistoryToGraphUsageEntries(deferredSessionHistory, 'data') || [],
    [deferredSessionHistory]
  );

  const tooltipBody = useCallback(
    (positionX: number, index: number | null, setName: string | null) => {
      if (currentSessionPosition.index === index) {
        return;
      }

      if (index === null) {
        setSessionCurrentPosition({
          id: '',
          index: null,
          x: positionX
        });
        return;
      }

      setSessionCurrentPosition({
        id: deferredSessionHistory[index].acctSessionId || '',
        index: index,
        x: positionX
      });
    },
    [currentSessionPosition]
  );

  const onZoomCompleteHandle = useCallback((range: [Date, Date]) => {
    setSessionCurrentPosition({ id: '', x: 0, index: null });
    selectVisibleRange(range[0], range[1]);
  }, []);

  const { t } = useTranslation();

  const averageSessionTooltipData = useMemo(
    () => [
      {
        color: USAGE_BAR_BACKGROUND_COLORS.INPUT,
        description: t('simInventory.averageSessionChartInput')
      },
      {
        color: USAGE_BAR_BACKGROUND_COLORS.OUTPUT,
        description: t('simInventory.averageSessionChartOutput')
      }
    ],
    []
  );

  const sessionTooltipData = useMemo(
    () => [
      { color: STATUS_COLOR_MAP.NORMAL, description: t('simInventory.sessionChartFinalized') },
      {
        color: STATUS_COLOR_MAP.NOT_FINALIZED,
        description: t('simInventory.sessionChartNotFinalized')
      },
      {
        color: STATUS_COLOR_MAP.ZERO_BYTE_SESSION,
        description: t('simInventory.sessionChartZeroByte')
      }
    ],
    []
  );

  return (
    <Box sx={{ position: 'relative' }} ref={root}>
      {currentSessionPosition.index !== null ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 100,
            transform: `translateY(calc(-100%)) translateX(calc(min(max(${Math.max(
              currentSessionPosition.x
            )}px, 50%), calc(${bounds.width}px - 50%)) - 50%))`,
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08), 0px 0px 5px rgba(0, 0, 0, 0.08)',
            bgcolor: (theme) => theme.palette.tertiary.main,
            px: 2,
            py: 1,
            borderRadius: 3
          }}
        >
          <Typography variant="text3Secondary" component="div">
            {`start: ${toLocaleDateTime(session?.sessionStart)}`}
          </Typography>
          <Typography variant="text3Secondary" component="div">
            {`end: ${toLocaleDateTime(session?.sessionEnd)}`}
          </Typography>
          {!session?.sessionEnd && (
            <Typography variant="text3Secondary" component="div">
              {`Last timestamp: ${toLocaleDateTime(session?.statusLastUpdated)}`}
            </Typography>
          )}

          <Typography
            variant="text3Secondary"
            component="div"
          >{`location: ${session?.localization}`}</Typography>
          <Typography
            variant="text3Secondary"
            component="div"
          >{`Data input: ${session?.cumulativeInputUsage?.toFixed(2)} KB`}</Typography>
          <Typography
            variant="text3Secondary"
            component="div"
          >{`Data output: ${session?.cumulativeOutputUsage?.toFixed(2)} KB`}</Typography>
          <Typography
            variant="text3Secondary"
            component="div"
          >{`Data output vel: ${session?.inputThroughtput.toFixed(2)} KB/s`}</Typography>
          <Typography
            variant="text3Secondary"
            component="div"
          >{`Data input vel: ${session?.outputThroughtput.toFixed(2)} KB/s`}</Typography>
        </Box>
      ) : null}

      <Box sx={{ px: 10 }}>
        <Box>
          <Box sx={{ display: 'flex', marginBottom: '2px' }}>
            <Typography variant="text3Secondary" mr="5px">
              {t('simInventory.sessionChartTitle')}
            </Typography>
            <Tooltip
              title={t('simInventory.sessionChartTitle')}
              component={<TooltipAdditionalRowsRender rows={sessionTooltipData} />}
            >
              <Box
                sx={{
                  fill: (theme) => theme.palette.text.tertiary,
                  '&:hover': {
                    fill: (theme) => theme.palette.text.secondary
                  }
                }}
              >
                <HelpIcon width="15px" height="15px" role="img" />
              </Box>
            </Tooltip>
          </Box>
          <Box sx={{ position: 'relative' }}>
            <SignleSessionGraphRange
              highlightFrom={selectedSession?.sessionStart}
              highlightTo={selectedSession?.finalizationDate}
              entries={graphEntries}
              min={validRange.from.getTime()}
              max={validRange.to.getTime()}
              from={visibleRange.from.getTime()}
              to={visibleRange.to.getTime()}
              onZoomComplete={onZoomCompleteHandle}
              tooltip={tooltipBody}
              onClick={(index) => {
                const selectedSession = deferredSessionHistory[index];
                setSelectedSessionId(selectedSession.acctSessionId || '');
              }}
              onPan={selectRange}
            />
            {isError && <ErrorOverflowBox />}
            {loading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 'calc(50% - 14px)',
                  left: '50%',
                  transform: 'translateX(-50%) translateY(-50%)'
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={{ marginTop: '5px' }}>
          <Box sx={{ display: 'flex', marginBottom: '2px' }}>
            <Typography variant="text3Secondary" mr="5px">
              {t('simInventory.averageSessionChartTitle')}
            </Typography>
            <Tooltip
              title={t('simInventory.averageSessionChartTitle')}
              component={<TooltipAdditionalRowsRender rows={averageSessionTooltipData} />}
            >
              <Box
                sx={{
                  fill: (theme) => theme.palette.text.tertiary,
                  '&:hover': {
                    fill: (theme) => theme.palette.text.secondary
                  }
                }}
              >
                <HelpIcon width="15px" height="15px" role="img" />
              </Box>
            </Tooltip>
          </Box>

          <Box sx={{ position: 'relative' }}>
            <SingleUsageGraph
              highlightFrom={selectedSession?.sessionStart}
              highlightTo={selectedSession?.finalizationDate}
              entries={usageGraphEntries}
              min={validRange.from.getTime()}
              max={validRange.to.getTime()}
              from={visibleRange.from.getTime()}
              to={visibleRange.to.getTime()}
              onZoomComplete={onZoomCompleteHandle}
              tooltip={tooltipBody}
              onClick={() => {
                setSelectedSessionId(currentSessionPosition.id);
              }}
              onPan={selectRange}
            />
            {loading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 'calc(50% - 14px)',
                  left: '50%',
                  transform: 'translateX(-50%) translateY(-50%)'
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {isError && <ErrorOverflowBoxWithRefreshAction />}
          </Box>
        </Box>
      </Box>

      <Box mt={2}>
        <SessionDetailsWithInterimsGraphs session={selectedSession} />
      </Box>
    </Box>
  );
};
