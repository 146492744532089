import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import { bottomBorder, noTopBorder, topBorder } from 'theme/utility/utility.styles';

import { SeparatorRow } from './SeparatorRow';
import { StickyVerticalBorder } from './StickyVerticalBorder';
import { collapseContent, expandButtonCell } from './CollapseRow.style';
import { AdditionalMenu, AdditionalMenuProps } from '../../AdditionalMenu';
import { StickyCell } from 'simInventory/Components/StickyCell';
import { palette } from '../../../../theme/palette';

const style = {
  row: {
    '& .stickyCell': {
      opacity: '0',
    },
    '&:hover': {
      '& .stickyCell': {
        opacity: '1',
      },
    },
    '&:first-of-type .MuiTableCell-root': {
      borderTop: 'none',
    },
  },
};

const collapseRowStyle = {
  row: {
    '&.MuiTableRow-hover.collapse-row': {
      '&:hover': {
        backgroundColor: palette.white,
      },
      '.polling-status-cell': {
        backgroundColor: palette.white,
      },
    },
  },
};

type CollapseRowProps = {
  children: React.ReactElement | React.ReactElement[];
  collapseChildren: React.ReactElement;
  open?: boolean;
  onCollapseButtonClick: (open: boolean) => void;
  onCollapseEnd?: (open: boolean) => void;
  renderMenu?: AdditionalMenuProps['children'];
  isPreviousOpen?: boolean;
  isFirst?: boolean;
};

export const CollapseRow: React.FC<CollapseRowProps> = ({
  children,
  collapseChildren,
  open,
  onCollapseButtonClick,
  onCollapseEnd,
  renderMenu,
  isPreviousOpen,
  isFirst,
}) => {
  const { t } = useTranslation();
  const [innerOpen, setInnerOpen] = useState(false);
  const [contentDisplayed, setContentDisplayed] = useState(false);

  useEffect(() => {
    if (contentDisplayed) {
      setInnerOpen(true);
    }
  }, [contentDisplayed]);

  const closeRow = () => {
    if (open !== undefined) {
      onCollapseButtonClick(false);
    } else {
      setInnerOpen(false);
    }
  };

  useEffect(() => {
    if (open === undefined) {
      return;
    }
    if (open) {
      setContentDisplayed(true);
    } else {
      setInnerOpen(false);
    }
  }, [open]);

  const topBorderStyle = {
    '& .MuiTableCell-root':
      isPreviousOpen || innerOpen
        ? { ...topBorder, ...bottomBorder }
        : { ...noTopBorder, ...bottomBorder },
  };

  useEffect(() => {
    if (onCollapseEnd) {
      onCollapseEnd(contentDisplayed);
    }
  }, [contentDisplayed]);
  return (
    <>
      {innerOpen && !isFirst && !isPreviousOpen ? <SeparatorRow /> : null}
      <TableRow sx={{ ...style.row, ...topBorderStyle }}>
        <StickyVerticalBorder side="left" />
        <TableCell padding="checkbox" sx={expandButtonCell} data-testid="dataRow">
          <IconButton
            aria-label={t('common.expandRow')}
            size="small"
            onClick={() => {
              if (!innerOpen) {
                if (open === undefined) {
                  setContentDisplayed(true);
                } else {
                  onCollapseButtonClick(true);
                }
              } else {
                closeRow();
              }
            }}
          >
            {innerOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
        {renderMenu && (
          <StickyCell sx={{ background: '#fff' }} className="stickyCell">
            <AdditionalMenu>{renderMenu}</AdditionalMenu>
          </StickyCell>
        )}
        <StickyVerticalBorder side="right" />
      </TableRow>
      <TableRow className={'collapse-row'} sx={collapseRowStyle.row}>
        <StickyVerticalBorder side="left" />
        <TableCell
          colSpan={React.Children.count(children) + 2}
          sx={innerOpen ? { p: 0, border: 'none', ...bottomBorder } : { p: 0, border: 'none' }}
        >
          <Collapse
            in={innerOpen}
            addEndListener={() => {
              if (!innerOpen) {
                setContentDisplayed(false);
              }
            }}
          >
            <Box sx={collapseContent} data-testid="Collapse box root">
              {!contentDisplayed ? null : collapseChildren}
            </Box>
          </Collapse>
        </TableCell>
        <StickyVerticalBorder side="right" />
      </TableRow>
      {innerOpen ? <SeparatorRow /> : null}
    </>
  );
};
