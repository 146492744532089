import { useState } from 'react';
import Dropzone from 'react-dropzone';
import {
  BulkProvisioningJobsTable,
  BulkProvisioningJobsTableProps,
} from './BulkProvisioningJobsTable';
import { useBulkProvisioningUpload } from './useBulkProvisioningUpload';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { bulkProvisioningDropAreaTestId } from 'bulkProvisioning/BulkProvisioningDataTestIds';
import { useAbility } from 'permissions/hooks/useAbility';
import { Actions, Subjects } from 'permissions/ability';
import { UploadDialog } from './UploadDialog';
import { BulkProvisioningUploadSnackbar } from './BulkProvisioningUploadSnackbar';

const dropAreaStyle = {
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  fontSize: '2rem',
};

export const BulkProvisioningJobsDropTable: React.FC<BulkProvisioningJobsTableProps> = ({
  searchProps,
}) => {
  const ability = useAbility();
  const { t } = useTranslation();

  const [dropAreaDisplayed, setDropAreaDisplayed] = useState(false);

  const { selectedFile, selectFile, uploadFile, importError, setImportError } =
    useBulkProvisioningUpload();
  return (
    <>
      <Dropzone
        noClick
        onDrop={(acceptedFiles) => {
          setDropAreaDisplayed(false);
          selectFile(acceptedFiles[0]);
        }}
        onDragEnter={() => setDropAreaDisplayed(true)}
        onDragLeave={() => setDropAreaDisplayed(false)}
        disabled={ability.cannot(Actions.upload, Subjects.bulkProvisioning)}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <Box
              sx={{ position: 'relative', height: '100%', width: '100%' }}
              {...getRootProps()}
              data-testid={bulkProvisioningDropAreaTestId}
            >
              <input {...getInputProps()} />
              <BulkProvisioningJobsTable {...{ searchProps }} />
              {dropAreaDisplayed && (
                <Box sx={dropAreaStyle}>
                  <Typography variant="h1Secondary">{t('common.dropFile')}</Typography>
                </Box>
              )}
            </Box>
          );
        }}
      </Dropzone>
      <UploadDialog
        open={!!selectedFile && !importError}
        fileName={selectedFile?.name || ''}
        onConfirm={() => {
          uploadFile();
        }}
        onCancel={() => {
          selectFile(null);
        }}
      />
      <BulkProvisioningUploadSnackbar
        importError={importError}
        setImportError={setImportError}
        selectFile={selectFile}
      />
    </>
  );
};
