import Big from 'big.js';
import { useModalFilters } from 'common/components/Filters/useModalFilters';
import { RSIM_INITIAL_FILTERS, RSimFilters } from '../data/filters.constants';

const parseFiltersEidRange = <T extends { eidFrom: string; eidTo: string }>(filters: T) => {
  const { eidFrom, eidTo, ...newFilters } = filters;
  if (eidFrom === '' || eidTo === '') {
    return filters;
  }

  const parsedEidFrom = new Big(eidFrom);
  const parsedEidTo = new Big(eidTo);
  if (parsedEidFrom.gt(parsedEidTo)) {
    return {
      ...newFilters,
      eidFrom: '',
      eidTo: '',
    };
  }

  return { ...filters };
};

export const useRSimInventoryFiltersModal = (
  filters: RSimFilters,
  onFiltersChange: (filters: RSimFilters) => void,
) => {
  const { filtersActive, open, setOpen, apply, cancel, clear, modalFilters, setModalFilters } =
    useModalFilters(RSIM_INITIAL_FILTERS, filters, (filters) => {
      const parsedFilters = parseFiltersEidRange(filters);
      onFiltersChange(parsedFilters);
    });

  return {
    filtersActive,
    open,
    setOpen,
    apply,
    cancel,
    clear,
    modalFilters,
    setModalFilters,
  };
};
