import { useContext, useEffect } from 'react';
import { TagContext } from '../../../tags/TagProvider';
import { useModalHints } from './useModalHints';
import { useQuery } from 'react-query';
import { Option } from '../Inputs/AutocompleteSelect/AutocompleteSelect';

export const useTagsWithHints = () => {
  const { type, fetchTags } = useContext(TagContext);

  const {
    setTempHints,
    internalHints: hints,
    removeHint,
    applyHints,
  } = useModalHints(type === 'sim' ? 'sim-hints' : 'rsim-hints');
  const { data: tagsData, refetch: refetchTags } = useQuery(
    type === 'sim' ? 'simTags' : 'rsimTags',
    async () => {
      const tags = await fetchTags();
      return tags.map((tag) => ({
        ...tag,
        toAdd: false,
      }));
    },
    {
      suspense: false,
    },
  );

  useEffect(() => {
    if (hints?.tags) {
      const exclude: Option[] = hints.tags.filter(
        (hint: Option) =>
          tagsData?.map((option) => option.id.toString()).indexOf(hint.id.toString()) === -1,
      );
      exclude.forEach((hint) => removeHint(hint, 'tags'));
    }
  }, [hints, tagsData, removeHint]);

  return {
    setTempHints,
    hints,
    removeHint,
    applyHints,
    tagsData,
    refetchTags,
  };
};
