import { Chip } from '../Chips/Chip';
import { useQuery } from 'react-query';
import { fetchMno } from '../../../simInventory/SimInventoryApi/mnoApi';

type MnoFilterChipProps = {
  ccii: string;
  onCancel: (ccii: string) => void;
};

export const MnoFilterChip = ({ ccii, onCancel }: MnoFilterChipProps) => {
  const { data } = useQuery(
    ['mno', ccii],
    async () => {
      return await fetchMno(ccii);
    },
    {
      suspense: false,
      enabled: !!ccii,
    },
  );

  return (
    <Chip
      label={`Mobile Network Operator: ${data?.name}`}
      size="medium"
      variant="outlined"
      onDelete={() => {
        onCancel(ccii);
      }}
    />
  );
};
