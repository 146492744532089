import { useEffect, useMemo } from 'react';
import {
  SessionHistoryEntry,
  fetchSessionHistory
} from 'common/components/SessionHistory/services/SessionHistoryApi';
import { ErrorCodes } from 'common/errorCodes';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery, useQuery } from 'react-query';
import { fetchSimInventoryEntry } from 'simInventory/SimInventoryApi/simInventoryApi';

export const useInfinitySessionHistory = (iccid?: string) => {
  const { data: simInventoryEntry, status: simInventoryEntryFetchingStatus } = useQuery(
    ['SimInventoryEntry', iccid],
    () => fetchSimInventoryEntry(iccid!),
    {
      enabled: !!iccid,
      retry: (failureCount, error) => {
        if (error instanceof Error && error.message === ErrorCodes.NotFound) {
          return false;
        }
        return failureCount < 3;
      }
    }
  );

  const {
    data: sessionHistoryPages,
    fetchNextPage,
    isFetchingNextPage,
    isFetched,
    hasNextPage
  } = useInfiniteQuery<SessionHistoryEntry[]>(
    ['SessionHistory', simInventoryEntry?.imsi || ''],
    async ({ signal, pageParam }) => {
      const sessionHistory = await fetchSessionHistory(simInventoryEntry!.imsi!, pageParam, false, signal);
      return sessionHistory;
    },
    {
      enabled: simInventoryEntryFetchingStatus === 'success' && !!simInventoryEntry?.imsi,
      retry: (failureCount, error) => {
        if (error instanceof Error && error.message === ErrorCodes.NotFound) {
          return false;
        }
        return failureCount < 3;
      },
      getNextPageParam: (lastPage: SessionHistoryEntry[]) => {
        if (lastPage.length < 20) {
          return;
        }
        const toDate = lastPage.at(-1)!.sessionStart;
        toDate.setMilliseconds(toDate.getMilliseconds() + -1);
        return toDate;
      }
    }
  );

  const sessionHistory: SessionHistoryEntry[] = useMemo(
    () =>
      sessionHistoryPages?.pages.reduce((history, page) => {
        return [...history, ...page];
      }, []) || [],
    [sessionHistoryPages]
  );

  const { ref, inView } = useInView({
    threshold: 0
  });

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage]);

  return {
    refToLoader: ref,
    showLoader: hasNextPage,
    sessionHistory,
    fetchingEnded: isFetched
  };
};
