import React from 'react';
import { GridActionsCellItem, GridActionsCellItemProps } from '@mui/x-data-grid-pro';
import { TooltipCell } from './TooltipCell';
import { typoColor } from '../../../theme/palette';

type ActionCellProps = {
  label: string;
  icon: React.ReactElement;
  disabled?: boolean;
  disabledLabel?: string;
  isCurrentRowActive: boolean;
} & GridActionsCellItemProps;

export const ActionCell: React.FC<ActionCellProps> = ({
  label,
  icon,
  disabled = false,
  disabledLabel,
  isCurrentRowActive,
  ...props
}) => {
  return (
    <GridActionsCellItem
      icon={
        <TooltipCell title={label} disabledLabel={disabledLabel} disabled={disabled}>
          {icon}
        </TooltipCell>
      }
      disabled={disabled}
      label={label}
      color="inherit"
      sx={{
        '&.Mui-disabled': { pointerEvents: 'all' },
        '&:not(.Mui-disabled)': { '& svg': { fill: isCurrentRowActive ? typoColor.primary : typoColor.secondary } },
      }}
      {...props}
    />
  );
};
