import { Plugin } from 'chart.js';
import { ChartProps } from 'react-chartjs-2';
import { convertDateToLabels } from './utils/utils';
import { SessionType } from 'simInventory/SessionHistory/HistoryAnalysis/HistoryAnalysis.types';

type BarChartProps = ChartProps<'bar'>;

export const chartBorderPlugin: Plugin<'bar'> = {
  id: 'chartBorder',
  afterDraw(chart, args, options) {
    const {
      ctx,
      chartArea: { left, top, width, height }
    } = chart;
    ctx.save();
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderWidth;

    ctx.strokeRect(left, top, width, height);
    ctx.restore();
  }
};

export const zoomConfig = {
  pan: {
    enabled: true,
    mode: 'x',
    modifierKey: 'ctrl'
  },
  zoom: {
    mode: 'x',
    speed: 0.01,
    drag: {
      enabled: true,
      borderColor: 'rgb(54, 162, 235)',
      borderWidth: 1,
      backgroundColor: 'rgba(54, 162, 235, 0.3)',
      threshold: 3,
      drawTime: 'afterDraw'
    }
  },
  limits: {
    x: { minRange: 5 * 60 * 1000 }
  }
} as const;

export const GRAPH_DEFAULT_OPTIONS: BarChartProps['options'] = {
  normalized: true,
  maintainAspectRatio: false,
  animation: false,
  layout: {
    padding: 0,
    autoPadding: false
  },
  scales: {
    x: {
      type: 'time',
      clip: true,

      title: {
        display: false
      },
      grid: {
        display: true,
        drawTicks: false
      },
      border: {
        display: false
      },
      ticks: {
        autoSkip: true,
        maxRotation: 0,
        minRotation: 0,
        sampleSize: 1,
        major: {
          enabled: true
        },
        callback: function (value, index, values) {
          if (typeof value === 'string') {
            return '';
          }

          return convertDateToLabels(value, index, values);
        }
      }
    },
    y: {
      display: false,
      stacked: true,
      beginAtZero: true
    }
  },
  resizeDelay: 200,
  plugins: {
    zoom: zoomConfig,
    legend: {
      display: false
    },
    chartBorder: {
      borderColor: '#E5E5E5',
      borderWidth: 1
    }
  }
} as const;

export const GRAPH_USAGE_XY_DATA_SETTINGS = {
  borderWidth: 1,
  fill: true,
  stepped: true
};

export const GRAPH_USAGE_OPTIONS = {
  ...GRAPH_DEFAULT_OPTIONS,
  scales: {
    x: {
      ...GRAPH_DEFAULT_OPTIONS?.scales?.x
    },
    y: {
      ...GRAPH_DEFAULT_OPTIONS?.scales?.y,
      display: false,
      type: 'linear',
      stacked: true,
      beginAtZero: true
    }
  }
};

export const STATUS_COLOR_MAP: Record<SessionType, string> = {
  ZERO_BYTE_SESSION: '#F67D85',
  NOT_FINALIZED: '#F6C86D',
  NORMAL: '#6FB7E1'
};

export const STATUS_BORDER_COLOR_MAP: Record<SessionType, string> = {
  ZERO_BYTE_SESSION: '#D2535C',
  NOT_FINALIZED: '#ECAD33',
  NORMAL: '#3791C5'
};

export const USAGE_BAR_BACKGROUND_COLORS = {
  INPUT: '#6FB7E1',
  OUTPUT: '#96DB9D'
};

export const USAGE_BAR_BORDER_COLORS = {
  INPUT: '#3791C5',
  OUTPUT: '#4B9256'
};

export const CHANGE_IN_TIME_COLORS = ['#3791C5', '#6FB7E1'];

export const CHANGE_IN_TIME_BORDER_COLOR = '#3791C5';

export const CHANGE_IN_TIME_UNKNOW_COLOR = '#DCE4E5';

export const CHANGE_IN_TIME_UNKNOWN_BORDER_COLOR = '#C0CACC';
