import Box from '@mui/material/Box';

import { SessionHistoryHeader } from './SessionHistoryHeader';
import { CollapseAllIconButton } from 'common/components/Buttons/CollapseAllIconButton';

export type SessionHistoryHeaderRowProps = {
  collapseAllButtonVisible: boolean;
  onCollapseAllClick: () => void;
  showProfile?: boolean;
};

export const SessionHistoryHeaderRow: React.FC<SessionHistoryHeaderRowProps> = ({
  collapseAllButtonVisible,
  onCollapseAllClick,
  showProfile = false,
}) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridColumn: showProfile ? 'span 17' : 'span 16',
        gridTemplateColumns: 'subgrid',
        position: 'sticky',
        top: '0',
        zIndex: 1100,
        background: ({ palette }) => palette.grey['100'],
        borderTopLeftRadius: '12px',
        border: ({ palette }) => `1px solid ${palette.tableBorder.main}`,
        '&>*:not(.stickyBorder)+*:not(.firstCellData)': {
          pl: 5,
        },
        '&>.stickyBorder': {
          pl: 0,
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {collapseAllButtonVisible && <CollapseAllIconButton onClick={onCollapseAllClick} />}
      </Box>
      {showProfile && (
        <Box className="firstCellData">
          <SessionHistoryHeader primaryText={'Profile'} />
        </Box>
      )}

      <Box className={showProfile ? '' : 'firstCellData'}>
        <SessionHistoryHeader
          primaryText={'Session start time'}
          secondaryText={'Session end time'}
        />
      </Box>

      <SessionHistoryHeader primaryText={'Duration'} />
      <SessionHistoryHeader primaryText={'Cumulative Usage (KB)'} />
      <SessionHistoryHeader primaryText={'IP Address'} secondaryText={'APN'} />
      <SessionHistoryHeader primaryText={'MNO Code'} />
      <SessionHistoryHeader primaryText={'Roaming Code'} />
      <SessionHistoryHeader primaryText={'Location'} />
      <SessionHistoryHeader primaryText={'Detected IMEI'} />
      <SessionHistoryHeader primaryText={'Session ID'} />
      <SessionHistoryHeader primaryText={'Last RAT'} />
      <SessionHistoryHeader primaryText={'RAS Client'} />
      <SessionHistoryHeader primaryText={'Framed Protocol'} />
      <SessionHistoryHeader primaryText={'NAS IP Address'} />
      <SessionHistoryHeader primaryText={'Service Type'} />
      <SessionHistoryHeader primaryText={'Username'} />
    </Box>
  );
};
