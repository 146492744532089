import React from 'react';
import { GridEditInputCellProps, useGridApiContext } from '@mui/x-data-grid-pro';
import { TextField } from 'common/components/Inputs/TextField/TextField';
import { useTranslation } from 'react-i18next';
import { CustomField } from '../../entities/customFields.entity';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { palette } from 'theme/palette';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InputAdornment from '@mui/material/InputAdornment';

type LabelTextFieldCellProps = GridEditInputCellProps & {
  tableRows: CustomField[];
};

export const LabelTextFieldCell = (props: LabelTextFieldCellProps) => {
  const apiRef = useGridApiContext();
  const { t } = useTranslation();
  const [labelError, setLabelError] = React.useState<'duplicated' | 'empty' | ''>('');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        value={props.row.label}
        className={`${!labelError ? '' : 'textfield-error'} ${
          props.row.label ? '' : 'missing-data'
        }`}
        placeholder={t('customFields.typeFieldName')}
        data-testid="customFieldLabel-input"
        sx={{ background: 'white', borderColor: labelError ? palette.failed.contrastText : '' }}
        onChange={(e) => {
          const value = e.target.value;
          if (value && value !== ' ') {
            const duplicated = props.tableRows.find(
              (r) => r.id !== props.row.id && r.label.toLowerCase() === value.toLowerCase(),
            );
            setLabelError(duplicated ? 'duplicated' : '');
            apiRef.current.setEditCellValue({ id: props.row.id, field: 'label', value: value });
            return;
          }

          setLabelError(value === ' ' ? 'empty' : '');
          apiRef.current.setEditCellValue({ id: props.row.id, field: 'label', value: value });
        }}
        InputProps={{
          endAdornment: (
            <>
              {labelError && (
                <InputAdornment position="start">
                  <ErrorOutlineOutlinedIcon />
                </InputAdornment>
              )}
            </>
          ),
        }}
        type="text"
      />
      {labelError && (
        <Typography data-testid="labelError" variant="inputError">
          {labelError === 'duplicated' && t('customFields.fieldNameTaken')}
          {labelError === 'empty' && t('customFields.fieldNameEmpty')}
        </Typography>
      )}
    </Box>
  );
};
