import { useQuery } from 'react-query';
import { fetchCustomField } from '../../../admin/customFields/api/customFieldsApi';
import { FIELD_TYPE } from '../../../admin/customFields/entities/customFields.entity';
import { Chip } from '../Chips/Chip';

type CustomFieldFilterChipProps = {
  id: string;
  value: string;
  onCancel: (fieldIdd: string, optionId: string) => void;
};

export const CustomFieldFilterChip = ({ id, value, onCancel }: CustomFieldFilterChipProps) => {
  const { data } = useQuery(
    ['customField', id],
    async () => {
      return await fetchCustomField(+id);
    },
    {
      suspense: false,
      enabled: !!id,
    },
  );

  let fieldValue: string[] = [value];
  if (data?.dataType === FIELD_TYPE.Dropdown) {
    fieldValue = value.split(',');
  }

  return (
    <>
      {fieldValue.map((v) => (
        <Chip
          key={id + '-' + v}
          label={`${data?.label}: ${
            data?.dataType === FIELD_TYPE.Text
              ? v
              : data?.selectionOptions.find((option) => option.id?.toString() === v.toString())
                  ?.label
          }`}
          size="medium"
          variant="outlined"
          onDelete={() => {
            onCancel(id.toString(), v);
          }}
        />
      ))}
    </>
  );
};
