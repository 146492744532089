import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { InterimZoomSelect } from './InterimZoomSelect';
import { InterimScrollbarRangeSlider } from './InterimScrollbarRangeSlider';
import { InterimsTimelineProvider } from './InterimsTimelineProvider';
import { RadioAccessGraph } from './RadioAccessGraph';
import { InterimsUsageGraph } from './InterimsUsageGraph';
import { InterimsDetails } from './InterimsDetails';
import { InterimLocationGraph } from './InterimLocationGraph';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { InterimsTimeRangeProvider } from './InterimsTimeRangeProvider';

type NoSessionSelectedBoxProps = {
  children: React.ReactNode;
  show: boolean;
};

const NoSessionSelectedBox: React.FC<NoSessionSelectedBoxProps> = ({ children, show }) => {
  return show ? (
    <Box sx={{ position: 'relative' }}>
      {children}
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          bottom: 35,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography variant="text1" color="text.tertiary">
          Select session to see details
        </Typography>
      </Box>
    </Box>
  ) : (
    <>{children}</>
  );
};

export type RadioAccessTypeGraphProps = {
  imsi?: number;
  sessionId?: string;
  from: Date;
  to: Date;
};

export const InterimsGraphs: React.FC<RadioAccessTypeGraphProps> = ({
  imsi,
  sessionId,
  from,
  to
}) => {
  const { t } = useTranslation();
  const displayNoSession = !sessionId;
  return (
    <InterimsTimeRangeProvider sessionFrom={from} sessionTo={to}>
      <InterimsTimelineProvider
        imsi={imsi}
        sessionId={sessionId}
        sessionStart={from}
        sessionEnd={to}
      >
        <Box sx={{ px: 10 }}>
          <Box sx={{ mt: 2 }}>
            <InterimsDetails />
          </Box>
          <Box sx={{ py: 3 }}>
            <InterimScrollbarRangeSlider />
          </Box>

          <Stack spacing={1.5}>
            <Box>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="text3Primary">Data Volume</Typography>
                <InterimZoomSelect />
              </Stack>
              <NoSessionSelectedBox show={displayNoSession}>
                <InterimsUsageGraph />
              </NoSessionSelectedBox>
            </Box>
            <Box>
              <Typography variant="text3Primary">Radio Access Type</Typography>
              <NoSessionSelectedBox show={displayNoSession}>
                <RadioAccessGraph />
              </NoSessionSelectedBox>
            </Box>
            <Box>
              <Typography variant="text3Primary">{t('simInventory.location')}</Typography>
              <NoSessionSelectedBox show={displayNoSession}>
                <InterimLocationGraph />
              </NoSessionSelectedBox>
            </Box>
          </Stack>
        </Box>
      </InterimsTimelineProvider>
    </InterimsTimeRangeProvider>
  );
};
