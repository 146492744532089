import { ErrorCodes } from 'common/errorCodes';
import { useQuery } from 'react-query';
import { fetchSimInventoryEntry } from 'simInventory/SimInventoryApi/simInventoryApi';
import { useSimDetailSubscription } from './useSimDetailSubscription';

export const useSimDetails = (iccid: string) => {
  const { data, refetch } = useQuery(
    ['SimInventoryEntry', iccid],
    () => fetchSimInventoryEntry(iccid!),
    {
      enabled: !!iccid,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (error instanceof Error && error.message === ErrorCodes.NotFound) {
          return false;
        }
        return failureCount < 3;
      },
    },
  );

  useSimDetailSubscription(iccid, ['SimInventoryEntry', iccid]);

  return { data, refetch };
};
